import Axios from "axios";
import isAuth from "uvagriotLayouts/utility/authorization";
import { AxiosRequestConfig } from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
Axios.defaults.baseURL = API_URL;


// Define the structure of a retry queue item/* 
/* interface RetryQueueItem {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
  config: AxiosRequestConfig;
} */

// Create a list to hold the request queue


// Flag to prevent multiple token refresh requests

export class HttpService {

  _axios = Axios.create();//your-api-endpoint',);

  refreshAndRetryQueue = [];
  isRefreshing = false;

  constructor() {
    this.header = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };

    this._axios.interceptors.request.use(
      (config) => {
        const accessToken = localStorage.getItem('access_token');
        if (accessToken) {
          config.headers.Authorization = `JWT ${accessToken}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    this._axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;

        if (error.response && error.response.status === 401) {
          if (!this.isRefreshing) {
            this.isRefreshing = true;
            try {
              // Refresh the access token
              const newAccessToken = await isAuth();
              if (newAccessToken) {

                localStorage.setItem('access_token', newAccessToken);
                error.config.headers['Authorization'] = `JWT ${localStorage.getItem('access_token')}`;
                // Retry all requests in the queue with the new token
                this.refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
                  this._axios
                    .request(config)
                    .then((response) => resolve(response))
                    .catch((err) => reject(err));
                });

              } else {
                return Promise.reject(error);
              }

              // Clear the queue
              this.refreshAndRetryQueue.length = 0;

              // Retry the original request
              return this._axios(originalRequest);
            } catch (refreshError) {
              // Handle token refresh error
              localStorage.removeItem('access_token');
              localStorage.removeItem('refresh_token');
              window.location.href = '/login'; // Reindirizza al login
              throw refreshError;
            } finally {
              this.isRefreshing = false;
            }
          }
          // Add the original request to the queue
          return new Promise((resolve, reject) => {
            this.refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
          });
        }

        // Return a Promise rejection if the status code is not 401
        return Promise.reject(error);
      }
    )

  }


  get = async (url, header) => await this.request(this.getOptionsConfig("get", url, header));

  post = async (url, data, header) => await this.request(this.getOptionsConfig("post", url, data, header));

  put = async (url, data, header) => await this.request(this.getOptionsConfig("put", url, data, header));

  patch = async (url, data, header) => await this.request(this.getOptionsConfig("patch", url, data, header));

  delete = async (url, data, header) => await this.request(this.getOptionsConfig("delete", url, data, header));

  getOptionsConfig = (method, url, data = {}, header = this.header) => {

    console.log("---------------------------------------------------")
    console.log(method + " " + url);
    console.log(data)
    console.log(header)
    console.log("---------------------------------------------------")

    return {
      method,
      url,
      data,
      headers: header,
    };
  };

  request(options) {
    return new Promise((resolve, reject) => {
      this._axios
        .request(options)
        .then((res) => resolve(res.data))
        .catch((ex) => resolve(ex.response.data));
    });
  }
}

export default new HttpService();

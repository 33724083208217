import { Component } from "react";
import { useParams } from "react-router-dom";
import DataTableFilterDemo from "./components/DataFilter";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import TipologiaNodiTemplate from "uvagriotLayouts/TipologiaNodo/components/tipologiaTemplate";

export default function Machines() {

  const params = useParams();
  return (
    <TipologiaNodiTemplate
      title="Nodi"
      component={<DataTableFilterDemo appezzamentoID={params?.appezzamentoID} />}
    />

  );

}

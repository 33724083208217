import { useContext, useState, useRef } from "react";

// @mui material components
import Card from "@mui/material/Card";

import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";


// Authentication layout components
import BasicLayoutLanding from "layouts/authentication/components/BasicLayoutLanding";


// Images
import bgImage from "assets/images/LOGIN-bg.png";

import "uvagriotLayouts/utility/styles/uvagriotStyle.css"
// import "./style/style.css"

import AuthService from "services/auth-service";
import { AuthContext } from "context";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import {
    emailValidator,
    loginValidator,
    passwordValidator

} from "uvagriotLayouts/utility/validators";

import { FormInputMod, PasswordInput } from "./customForm";



function Login() {

    const authContext = useContext(AuthContext);

    const formRef = useRef(null);

    const [submitError, setSubmitError] = useState({
        error: false,
        message: ''
    })

    const [initialState, setInitialState] = useState({
        password: "",
        email: "",

    });
    const handlerInputs = () => setSubmitError({ error: false, message: '' });

    /* useEffect(()=>{
          console.log(localStorage.getItem('user'))
      }) */

    const closeModal = () => {
        closeForm(formRef.current.resetForm);
    };

    const closeForm = (reset) => {
        reset();
    };



    const submit = async (dataItem) => {

        console.log(dataItem)

        //accesso
        const response = await AuthService.login(dataItem);

        console.log("response login");
        console.log(response);

        if (response.status === '400') {

            setSubmitError({
                error: true,
                message: response.message
            })
        } else {
            console.log("error---------------------------------------------------")
            setSubmitError({
                error: false,
                message: ""
            })
            authContext.login(response.access, response.refresh)
        }



    };

    return (
        <BasicLayoutLanding image={bgImage}>
            <Card>
                <MDBox
                    color="white"
                    bgColor="info"
                    variant="gradient"
                    borderRadius="lg"
                    shadow="lg"
                    opacity={1}
                    mx={2}
                    mt={-3}
                    p={2}
                    mb={1}
                    textAlign="center"
                >
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        Login
                    </MDTypography>

                </MDBox>
                <MDBox pt={1} pb={3} px={3} >
                    <Form
                        ref={formRef}
                        onSubmit={submit}
                        initialValues={initialState}
                        validator={() => loginValidator(submitError)}
                        key={"form_recipe"}
                        render={(formRenderProps) => (
                            <FormElement>

                                <fieldset className={"k-form-fieldset"}>
                                    {formRenderProps.visited &&
                                        formRenderProps.errors &&
                                        formRenderProps.errors.VALIDATION_SUMMARY && (
                                            <div className={"k-messagebox k-messagebox-error mt-3 mb-0"}>
                                                {formRenderProps.errors.VALIDATION_SUMMARY}
                                            </div>
                                        )}

                                    <Grid container columnSpacing={2}>
                                        <Grid item xs={12} >
                                            <Field
                                                id={"emailID"}
                                                name={"email"}
                                                label={"Email"}
                                                component={FormInputMod}
                                                onChange={handlerInputs}
                                                validator={emailValidator}
                                            />

                                        </Grid>
                                        <Grid item xs={12} >
                                            <Field

                                                id={"pwdID"}
                                                name={"password"}
                                                label={"Password"}
                                                onChange={handlerInputs}
                                                component={PasswordInput}
                                                validator={passwordValidator}
                                            />
                                        </Grid>

                                    </Grid>
                                </fieldset>
                                <div className="k-form-buttons">

                                    <Button
                                        style={{ width: '100%' }}
                                        themeColor={"primary"}
                                        type={"submit"}
                                        size={'large'}
                                        disabled={!formRenderProps.allowSubmit}

                                    >
                                        {'Accedi'}
                                    </Button>


                                </div>
                            </FormElement>
                        )}
                    />
                </MDBox>
            </Card>
        </BasicLayoutLanding>
    );
}

export default Login;

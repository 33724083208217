import { useState, useEffect } from "react";
//import '@progress/kendo-theme-default/dist/default-ocean-blue.scss';
import "@progress/kendo-theme-default/dist/all.css";

import React from "react";
//import Button from 'react-bootstrap/Button';

import MDBox from "components/MDBox";
import Select from 'react-select';

//import "../style/style.css";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { enumTypeParam } from "uvagriotLayouts/utility/enumerators";

import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBCol,
} from "mdb-react-ui-kit";

import Grid from "@mui/material/Grid";

import { StatusCodes } from "http-status-codes";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import {
  FormInput,
  FormDropDownList,
  FormTextArea,
  FormSwitch,
} from "uvagriotLayouts/utility/form-components";
import {
  existParamValidator,
  existLabelValidator,
} from "uvagriotLayouts/utility/validators";
import uvagriotService from "../../../services/uvagriot-service";
import { Typography } from "@mui/material";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import { MultipleFileUploadBasic } from "./uploads";


import UvagriotService from "services/uvagriot-service";

export default function ImageModal(props){
    const {
            modalHook,
            toggleShow,
            pianificazioneID
        } = props

    const modality = "edit"

    const [images, setImages] = useState([])
    const [currImg, setCurrImg] = useState([])

    const formRef = React.useRef(null);


    const getImages = async() =>{
      const response = await UvagriotService.immaginiVoloGET(pianificazioneID);
      setImages(response.data)
      console.log(response.data)
      if (response.data){
        setCurrImg(response?.data[0])
      }
      


    }
    
  //const structureKey = Object.values(enumStructureKey);
  useEffect(()=>{
    if(modalHook.staticModal){
        console.log("RICHIESTA IMMAGINI\n")
        
        getImages();   
  }

  },[modalHook.staticModal])

  const initial = {
    nome: "",
    //label_frontend : "",
    descrizione: "",
    
    unita_di_misura: "-",
  };

  const [initialState, setInitialState] = useState(initial);
  const getImage = (choice) =>{
    console.log(choice)
      setCurrImg(choice)
  }
  const closeModal = () => {
    //closeForm(formRef.current.resetForm);
  };


  const source = "C:/Users/giuseppe forziati/Desktop/346-uvagriot/uvagriotapp/pyODM/image/08052024_125754/orthophoto/ndvi.jpg"
  //const source = "C:/Users/giuseppe forziati/Desktop/346-uvagriot/uvagriotapp/static/image/uvagriot_logo_no_writing.png"

    return (
            <MDBModal
                    staticBackdrop
                    tabIndex="-1"
                    show={modalHook.staticModal}
                    setShow={modalHook.setStaticModal}
                    //className="pt-1"
                   
                >
                    <MDBModalDialog size='xl fullscreen-sm-down'>
                    <MDBModalContent>
                        <MDBox
                        mx={1}
                        mt={-3}
                        //py={1}
                        //px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                        >
                        <MDBModalHeader style={{ border: 0 }}>
                           {/*  <MDBModalTitle style={{ color: "whitesmoke" }}>
                            Indice di vegetazione
                            </MDBModalTitle> */}
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              selected={currImg}
                              isSearchable={true}
                              name="color"
                              
                              options={images}
                              onChange={(choice) => getImage(choice)}
                          />
                            <MDBBtn
                            className="btn-close float right"
                            mx={2}
                            color="none"
                            aria-label="Close"
                            onClick={toggleShow}
                            />
                        </MDBModalHeader>
                        </MDBox>

                        <MDBModalBody className="px-4 py-0">

                            <MDBCol xl={12} lg={12} className="mb-4 pr-3 pt-3">
                              
                            <img 
                                src={`data:image/jpeg;base64,${currImg?.value}`} alt={"img"+currImg?.label} class="rounded mx-auto d-block" /* height={"100%"} width={"100%"} */
                            />
                            </MDBCol>
                        
                        
                        
                        </MDBModalBody>
                    </MDBModalContent>
                    </MDBModalDialog>
            </MDBModal>
    )
}








import { getter } from "@progress/kendo-react-common";
const emailRegex = new RegExp(/\S+@\S+\.\S+/);
//const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

const phoneRegex = new RegExp(/^[0-9 ()+-]+$/);
const ccardRegex = new RegExp(/^[0-9-]+$/);
const cvcRegex = new RegExp(/^[0-9]+$/);
export const termsValidator = (value) =>
  value ? "" : "It's required to agree with Terms and Conditions.";
export const emailValidator = (value) =>
  !value
    ? "Inserisci una email"
    : emailRegex.test(value)
    ? ""
    : "L'email inserita non è in un formato valido";
export const nameValidator = (value) =>
  !value
    ? "Inserisci un nome"
    : value.length < 3
    ? "Inserisci un nome lungo almeno 3 caratteri"
    : "";

export const surnameValidator = (value) =>
  !value
    ? "Inserisci un cognome"
    : value.length < 3
    ? "Inserisci un cognome lungo almeno 3 caratteri"
    : "";

export const recipeValidator = (value) =>
  !value ? "inserisci un nome per la ricetta" : "";
export const userNameValidator = (value) =>
  !value
    ? "User Name is required"
    : value.length < 5
    ? "User name should be at least 5 characters long."
    : "";
export const phoneValidator = (value) =>
  !value
    ? "Phone number is required."
    : phoneRegex.test(value)
    ? ""
    : "Not a valid phone number.";
export const cardValidator = (value) =>
  !value
    ? "Credit card number is required. "
    : ccardRegex.test(value)
    ? ""
    : "Not a valid credit card number format.";
export const cvcValidator = (value) =>
  !value
    ? "CVC code is required,"
    : cvcRegex.test(value) || value.length !== 3
    ? ""
    : "Not a valid CVC code format.";
export const guestsValidator = (value) =>
  !value ? "Number of guests is required" : value < 5 ? "" : "Maximum 5 guests";
export const nightsValidator = (value) =>
  value ? "" : "Number of Nights is required.";
export const arrivalDateValidator = (value) =>
  value ? "" : "Arrival Date is required.";
export const colorValidator = (value) => (value ? "" : "Color is required.");
export const requiredValidator = (value) =>
  value ? "" : "Error: This field is required.";
export const passwordValidator = (value) =>
  value && value.length > 8 ? "" : "Password must be at least 8 symbols.";
export const addressValidator = (value) =>
  value ? "" : "Address is required.";
export const numberValidator = (value) =>
  !value || value < 0 ? "Inserisci un valore valido" : ""; //!value ||

const userNameGetter = getter("username");
const emailGetter = getter("email");
export const formValidator = (values) => {
  const userName = userNameGetter(values);
  const emailValue = emailGetter(values);
  if (userName && emailValue && emailRegex.test(emailValue)) {
    return {};
  }
  return {
    VALIDATION_SUMMARY: "Please fill in the following fields.",
    ["username"]: !userName ? "User Name is required." : "",
    ["email"]:
      emailValue && emailRegex.test(emailValue)
        ? ""
        : "Email is required and should be in a valid format.",
  };
};

export const existRecipeValidator = (recipe, list) => {
  var array = [];
  for (let machine of list) {
    for (let rec of machine.recipes) {
      array.push(rec.Name);
    }
  }
  if (!recipe) {
    return "inserisci una nome per la ricetta";
  } else {
    if (array.includes(recipe.trim())) {
      return "Questa ricetta esiste già tra le nodi selezionate. Inserisci un altro nome";
    }
  }
};
export const existStdRecipeValidator = (recipe, list) => {
  if (!recipe) {
    return "inserisci una nome per la ricetta";
  } else {
    if (list.includes(recipe.trim())) {
      return "Questa ricetta esiste già per questa tipologia. Inserisci un altro nome";
    }
  }
};
export const existParamValidator = (recipe, list, structureKey = "") => {
  // console.log(recipe)
  // console.log(list)
  if (!recipe) {
    return "inserisci una nome per il parametro";
  } else {
    if (list.includes(recipe.trim())) {
      return "Questo parametro esiste già . Inserisci un altro nome";
    }
  }
};
export const existDroneValidator = (recipe, list) => {
  // console.log(recipe)
  // console.log(list)
  if (!recipe) {
    return "inserisci una nome per il Drone";
  } else {
    if (list.includes(recipe.trim())) {
      return "Questo nome è già in uso. Inserisci un altro nome";
    }
  }
};

export const existIDValidator = (recipe, list) => {
  // console.log(recipe)
  // console.log(list)
  if (!recipe) {
    return "inserisci un ID per il Drone";
  } else {
    if (list.includes(recipe.trim())) {
      return "Questo ID è già in uso. Inserisci un altro ID";
    }
  }
};
export const existModelValidator = (recipe, list) => {
  if (!recipe) {
    return "inserisci una nome per il modello";
  } else {
    if (list.includes(recipe.trim())) {
      return "Questo modello esiste già . Inserisci un altro nome";
    }
  }
};
export const existColturaValidator = (recipe, list) => {
  if (!recipe) {
    return "inserisci una nome per la coltura";
  } else {
    if (list.includes(recipe.trim())) {
      return "Questa coltura esiste già . Inserisci un altro nome";
    }
  }
};

export const existLabelValidator = (recipe, elem, list) => {
  if (!recipe) {
    return "inserisci una label per il parametro";
  } else {
    if (recipe.trim() !== elem && list.includes(recipe.trim())) {
      return "Questo label esiste già tra i parametri. Inserisci un altro label";
    }
  }
};
export const typeValidator = (type, list) => {
  if (!type) {
    return "inserisci una nome per la tipologia di nodo";
  } else {
    if (list.includes(type.trim())) {
      return "Questa tipologia esiste già. Inserisci un altro nome";
    }
  }
};

export const appezzamentoValidator = (type, list) => {
  if (!type) {
    return "inserisci una nome per l'appezzamento";
  } else {
    if (list.includes(type.trim().toLowerCase())) {
      return "Questo appezzamneto esiste già. Inserisci un altro nome";
    }
  }
};

export const paramValidator = (value, min, max) => {
  if (min === max) {
    if (value < 0 || value > 100) {
      return `inserisci un valore compreso tra 0 e 100`;
    } else {
      return "";
    }
  } else {
    if (value != 0 && !value) {
      return `inserisci un valore compreso tra ${min} e ${max}`;
    }
    if (value < min || value > max) {
      return `inserisci un valore compreso tra ${min} e ${max}`;
    } else {
      return "";
    }
  }
};

export const emptyValidator = (value) =>
  !value ? "Inserisci un nome per la tipologia di nodo" : "";
export const checkvalidator = (value) =>
  !value || value < 1
    ? {
        VALIDATION_SUMMARY: "Inserisci almeno un parametro dalla tabella",
      }
    : "";
export const sendRecipeValidator = (value, msg) =>
  value
    ? {
        VALIDATION_SUMMARY: msg,
      }
    : "";

export const loginValidator = (value) =>
  value.error
    ? {
        VALIDATION_SUMMARY: value.message,
      }
    : "";

export const errorPwdValidator = (value) =>
  value
    ? {
        ["old_password"]: "La password corrente inserita è sbagliata. Riprova",
      }
    : "";

export const handlerPassword = (value) => {
  const regularExpression =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#?!@$%^_&*-])[A-Za-z\d#?!@$%^_&*-]{8,}$/;

  return !regularExpression.test(value);
};

export const handlerRepetePassword = (pwd, pwd2) => {
  if (pwd !== pwd2) {
    return true;
  }
  return false;
};

const pwdGetter = getter("password");
const pwd2Getter = getter("password2");

export const passwordFormValidator = (values, check) => {
  const pwd = pwdGetter(values);
  const pwd2 = pwd2Getter(values);
  let pwdbool = handlerPassword(pwd);
  let pwd2bool = handlerRepetePassword(pwd, pwd2);

  return {
    ["old_password"]: check.oldpwd
      ? "La password corrente inserita è sbagliata. Riprova"
      : "",
    ["password"]: pwdbool
      ? "La password deve essere lunga almeno 8 caratteri, contenere almeno una lettera maiuscola, una lettera minuscola un numero e un carattere speciale (#,?,!,@,$,%,^,_,&,*,-)"
      : check.similarpwd
      ? "La password è troppo simile a quella precedente"
      : "",
    ["password2"]: pwd2bool
      ? "La password deve essere uguale alla nuova password"
      : "",
  };

  /* if (userName && emailValue && emailRegex.test(emailValue)) {
    return {};
  }
  return {
    VALIDATION_SUMMARY: 'Please fill in the following fields.',
    ['username']: !userName ? 'User Name is required.' : '',
    ['email']: emailValue && emailRegex.test(emailValue) ? '' : 'Email is required and should be in a valid format.'
  }; */
};

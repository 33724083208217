import HttpService from "./htttp.service";

const root = process.env.REACT_APP_API_ENDPOINT

class AuthService {
  // authEndpoint = process.env.API_URL;
  constructor() {
    this.header = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'JWT ' + localStorage.getItem('access_token')
      }
    }
    this.header_img = {
      headers: {
        Authorization: 'JWT ' + localStorage.getItem('access_token')
      }
    }
  }

  refreshTokenAuthorization = () => {
    this.header = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'JWT ' + localStorage.getItem('access_token')
      }
    }
  }

  login = async (payload) => {
    const endpoint = root + 'api/v1.0/auth/token/';
    return await HttpService.post(endpoint, payload);
  };

  refreshToken = async (payload) => {

    const endpoint = root + 'api/token/refresh/';
    return await HttpService.post(endpoint, payload);
  }

  register = async (credentials) => {
    const registerEndpoint = 'register';
    return await HttpService.post(registerEndpoint, credentials);
  };

  logout = async () => {
    const logoutEndpoint = root + 'users/logout/blacklist/';
    const data = {
      refresh_token: localStorage.getItem('refresh_token')
    }

    return await HttpService.post(logoutEndpoint, data, this.header);
  };

  getPermission = async (id) => {
    const permEndpoint = root + `users/${id}/permission/`;
    return await HttpService.get(permEndpoint);
  }

  changePasswordPUT = async (id, payload) => {
    const pwdEndpoint = root + `users/change_password/${id}/`;
    return await HttpService.put(pwdEndpoint, payload);
  }

  getUser = async (id) => {
    const endpoint = root + `users/user/${id}/`;
    console.log("HEADER")
    console.log(this.header)
    return await HttpService.get(endpoint);
  }

  forgotPassword = async (payload) => {
    const forgotPassword = 'password-forgot';
    return await HttpService.post(forgotPassword, payload);
  }

  resetPassword = async (credentials) => {
    const resetPassword = 'password-reset';
    return await HttpService.post(resetPassword, credentials);
  }

  getProfile = async () => {
    const getProfile = 'me';
    return await HttpService.get(getProfile);
  }

  updateProfile = async (newInfo) => {
    const updateProfile = "me";
    return await HttpService.patch(updateProfile, newInfo);
  }
}

export default new AuthService();

import React, {useEffect, useState} from 'react';
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "../../components/MDTypography";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import uvagriotService from "../../services/uvagriot-service";
import DataTable from "react-data-table-component";
import MDButton from "../../components/MDButton";
import {MDBSwitch} from "mdb-react-ui-kit";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import UvagriotService from "../../services/uvagriot-service";
import {NotificationManager} from "react-notifications";

export default function DSS_Pianificazione() {

    let location = useLocation();
    const [listPianificazioni, setListPianificazioni] = useState([])
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    console.log(location.state)

    const getPianificazioni = async () => {
        const response = await uvagriotService.pianificazioneLIST(location.state.appezzamento)
        console.log("pianificazioni")
        console.log(response)
        setListPianificazioni(response);
    }

    useEffect(() => {
        getPianificazioni().then(r => setLoading(false))
    }, []);

    const handleButtonClick = async (id) => {
        console.log(id)
        let response = await UvagriotService.pianificazioneDELETE(id)
        console.log(response)
        getPianificazioni().then(r => setLoading(false))
    }

    const handleDisableButtonClick = async (row) => {
        row.dss_status = !row?.dss_status
        row.tipologia_coltura_pk = row?.tipologia_coltura?.id
        row.parametro_gdd_pk = row?.parametro_gdd?.id
        console.log(row)
        let response = await UvagriotService.pianificazionePUT(row.id, row);
        console.log(response)
        getPianificazioni().then(r => setLoading(false))
    }

    const createNotification = async (response) => {
        if (response !== {}) {
            let error = ''
            let i = 0
            for (let k in response) {
                if (i > 0) error += "\n"
                error += response[k]
                i++
            }
            NotificationManager.error(
                error,
                "Errore",
                5000
            );
        }
    };

    let columns = [
        {
            name: 'Tipologia Coltura',
            selector: row => row.tipologia_coltura.nome,
        },
        {
            name: 'Data Inizio',
            selector: row => row.data_inizio,
        },
        {
            name: 'Data Fine',
            selector: row => row.data_fine,
        },
        {
            name: 'Parametro GDD',
            selector: row => row.parametro_gdd.descrizione,
        },
        {
            name: 'Actual GDD',
            selector: row => row.actual_gdd,
        },
        {
            name: 'Continuous GDD',
            selector: row => row.continuous_gdd,
        },
        {
            name: 'DSS Stato',
            selector: row => row.dss_status,
            button: true,
            cell: (row) => (
                <MDBSwitch
                    defaultChecked={row.dss_status}
                    onClick={() => handleDisableButtonClick(row)}
                />
            ),
        },
        {
            name: '',
            button: true,
            cell: (row) =>
                (
                    <NavLink to={`/dss/pianificazione/single`} state={{id: row.id, appezzamento: location.state.appezzamento}}>
                        <MDButton variant="gradient" color="info" m={5}>
                            Edit
                        </MDButton>
                    </NavLink>
                ),
        },
        {
            name: '',
            button: true,
            cell: (row) => (
                <MDButton
                    onClick={() => {handleButtonClick(row.id)}}
                    variant="gradient" color="error" m={5}>
                    Delete
                </MDButton>
            ),
        },
    ];


    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox pt={6} pb={3} style={{minWidth: 280}}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Pianificazione
                                </MDTypography>
                            </MDBox>
                            <MDBox pb={2} pt={2} px={2}>
                                <NavLink to={`/dss/pianificazione/single`} state={{id: 0, appezzamento: location.state.appezzamento}}>
                                    <MDButton variant="gradient" color="info">
                                        Nuovo
                                    </MDButton>
                                </NavLink>
                                <DataTable
                                    columns={columns}
                                    data={listPianificazioni}
                                    pagination
                                    subHeader
                                    // subHeaderComponent={subHeaderComponent}
                                    noDataComponent={<p className={'py-5'}><h4>Nessuna Pianificazione</h4></p>}
                                />
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    );
}
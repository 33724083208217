// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Dashboard components
import { MapMachine } from "./components/mapMachine";
import { useEffect, useState } from "react";
import Machines from "./components/listMachines";
import { MDBCard, MDBCardBody, MDBCardImage, MDBCardText } from "mdb-react-ui-kit";
import { paddingIcon } from "@progress/kendo-svg-icons";
import uvagriotLogo from "assets/images/uvagriot_logo_no_writing.png";


function Dashboard() {
    const [role, setRole] = useState(false);

    useEffect(() => {
        setRole(JSON.parse(localStorage.getItem('user'))?.is_superuser);
    }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox py={1}>
                <MDBox style={{ textAlignLast: "center" }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12} lg={12}>
                            <MDBCardImage style={{ padding: "10px", width: "25%" }}
                                src={uvagriotLogo}
                                alt='...' position='top' height='auto' />
                            <MDBCardBody>
                                <MDBCardText className="text-center fs-1"><p>Benvenuto</p></MDBCardText>
                            </MDBCardBody>
                        </Grid>
                    </Grid>
                </MDBox>
            </MDBox>
        </DashboardLayout>
    );
}

export default Dashboard;

import { React, Component } from "react";
import UvagriotTemplate from "uvagriotLayouts/utility/UvagriotTemplate";
import Dashboard from "uvagriotLayouts/Droni/components/DashboardDroni.jsx";


class Droni extends Component {

    render() {
        return (
            <UvagriotTemplate
                title="Droni"
                component={<Dashboard />}
            />
        )
    }
}

export default Droni;
import React from "react";
import uvagriotService from "services/uvagriot-service";
import {
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardFooter,
  MDBRow,
  MDBCol
} from 'mdb-react-ui-kit';

import DroniTable from "./DroniTable";
import MDBox from "components/MDBox";
//import "../style/formStyle.css";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { BsSearch } from "react-icons/bs";


function Dashboard() {
  

  const [search, setSearch] = React.useState("");
  const [filteredRow, setFilteredRow] = React.useState([]);
  const [droni, setDroni] = React.useState([]);

    const getDroni = async() =>{
        const response = await uvagriotService.droniGET();
        setDroni(response.data);
        setFilteredRow(response.data)
        // console.log("droni")
         console.log(response.data)

    }

    React.useEffect(()=>{
        getDroni();   
    },[])

    React.useEffect(() => {
      const filteredRows_ = droni.filter((elem) =>
        elem.nome.toLowerCase().includes(search)
      );
      setFilteredRow(filteredRows_);
    }, [droni]);
  
    const filterRows = (e) => {
      let search_ = e.target.value.toLowerCase();
      const filteredRows_ = droni.filter((elem) =>
        elem.nome.toLowerCase().includes(search_)
      );
      setFilteredRow(filteredRows_);
      setSearch(e.target.value);
    };


  return (
    <MDBox pb={2} pt={1} px={2}>
    <Grid container spacing={0}>
      <Grid item xs={12} md={6} lg={4} my={3}>
        <TextField
          id="input-with-icon-textfield"
          label="Droni"
          fullWidth
          value={search}
          onChange={filterRows}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <BsSearch />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12} md={12} xl={12}>
        <div id="droniTableID" >
          <DroniTable
            id={"tableParamID"}
            rows={filteredRow}
            paramsHook = {{ params:droni, setParams:setDroni }}
          />
        </div>
      </Grid>
    </Grid>
  </MDBox>
  );
}

/* function Dashboard(props){

    const [droni, setDroni] = React.useState([]);

    const getDroni = async() =>{
        const response = await uvagriotService.droniGET();
        setDroni(response.data);
        // console.log("droni")
        // console.log(response.data)

    }

    React.useEffect(()=>{
        getDroni();   
    },[])


    return <>

    <h1>Dahsboard Droni</h1>

    {droni.map(drone => drone.nome)}

    </>
} */


export default Dashboard;
import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useEffect } from "react";
import { Scrollbar } from "smooth-scrollbar-react";
import TablePagination from "@mui/material/TablePagination";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

import { styled } from "@mui/material/styles";

// Images
import MDButton from "components/MDButton";

import { BsPlusCircleFill } from "react-icons/bs";
import { BsCheck2Circle } from "react-icons/bs";
import { BsXCircle, BsPen } from "react-icons/bs";
import { BsX, BsPlusCircleDotted, BsPlus } from "react-icons/bs";
import { BsTrash3, BsFileText } from "react-icons/bs";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import Popover from "@mui/material/Popover";
import uvagriotService from "../../../services/uvagriot-service";
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function DeleteButton(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const disabled_label = "Ci sono nodi associate";
  const label = "elimina";

  return (
    <>
      <Tooltip
        title={props.disable ? disabled_label : label}
        TransitionComponent={Zoom}
        placement="top"
      >
        <span>
          <IconButton
            color="error"
            aria-label="add an alarm"
            onClick={handleClick}
            disabled={
              props.disable
            } /*  onClick={ () => updateTipologia(rowsPerPage)} */
          >
            <BsTrash3 size={20} className="p-0" />
          </IconButton>
        </span>
      </Tooltip>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Typography sx={{ p: 2 }} variant="h6">
          Sei Sicuro di voler eliminare questa tipologia?
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "5px 16px 5px 16px",
          }}
        >
          <MDButton
            variant="text"
            color="info"
            onClick={handleClose}
            size="small"
          >
            indietro
          </MDButton>
          <MDButton
            color={"error"}
            size="small"
            onClick={() => props.deleteAction(props.value)}
          >
            Elimina
          </MDButton>
        </div>
      </Popover>
    </>
  );
}

function Row(props) {
  const { row, updateAction, deleteAction, recipesAction } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <Typography variant="h6"> {row.nome}</Typography>
          <Typography variant="button">{row.timestamp}</Typography>
        </TableCell>
        <TableCell align="center">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
          sx={{ backgroundColor: "#f8f9fa" }}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {row?.parametriRicetta.map((pr) => (
                <>
                  <li>
                    {pr.parametro} : {pr.valore}
                  </li>
                </>
              ))}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function RecipesTable(props) {
  const { data, create, update, del, recipes } = props;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  useEffect(() => {
    console.log("DATAS TYPE");
    console.log(data);
  }, [data]);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead sx={{ display: "table-header-group" }}>
          <TableRow>
            <TableCell colSpan={3} align="center">
              Storico Attuazioni
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row) => (
              <Row
                key={row.id}
                row={row}
                updateAction={update}
                deleteAction={del}
                recipesAction={recipes}
              />
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        colSpan={3}
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage=""
        SelectProps={{
          select: {
            marginbottom: "10px",
          },
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </TableContainer>
  );
}

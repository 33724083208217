import React, { useState } from "react";
import QrReader from "modern-react-qr-reader";

const QrTestScan = ({
  codiceLte,
  setCodiceLte,
  nomeUnivoco,
  setNomeUnivoco,
  formInitialValue,
  setFormInitialValue,
  keyCount,
  setKeyCount,
  setOpenCodUniv
}) => {
  const [result, setResult] = useState("No Result");

  const handleScan = (data) => {
    if (data) {
      setResult(data);
      /* console.log(result);
      this.setState({ result: data }); */
      try {
        JSON.parse(data);
        var isString = false;
      } catch (e) {
        var isString = true;
      }
      if (isString) {
        if (data.includes("IMEI") && data.includes("MAC")) {
          let index = data.indexOf("MAC:");
          var strOut = data.substr(index).replace("MAC:", "");
          setCodiceLte(strOut);
          setKeyCount(keyCount + 1);
          codiceLte = strOut;
          const changeInitial = {
            nomeUnivocoNodi: nomeUnivoco,
            codiceLteNodo: strOut,
            keyCount: keyCount,
          };
          setFormInitialValue(changeInitial);
        } else {
          setNomeUnivoco(data);
          setKeyCount(keyCount + 1);
          nomeUnivoco = data;
          const changeInitial = {
            nomeUnivocoNodi: nomeUnivoco,
            codiceLteNodo: codiceLte,
            keyCount: keyCount,
          };
          setFormInitialValue(changeInitial);
        }
      } else {
        const dataJ = JSON.parse(data);
        if (typeof dataJ === "object") {
          if (dataJ["POST"]["Dsp_Board_Info"].hasOwnProperty("LTE_MAC")) {
            setCodiceLte(dataJ["POST"]["Dsp_Board_Info"]["LTE_MAC"]);
            setFormInitialValue({
              nomeUnivocoNodi: nomeUnivoco,
              codiceLteNodo: dataJ["POST"]["Dsp_Board_Info"]["LTE_MAC"],
            });
            console.log(formInitialValue);
          }
        }
      }
      setOpenCodUniv(false);
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  return (
    <div>
      <QrReader
        delay={300}
        facingMode={"environment"}
        onError={handleError}
        onScan={handleScan}
        style={{ width: "100%" }}
      />
      <p>{result}</p>
    </div>
  );
};

export default QrTestScan;

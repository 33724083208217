import * as React from "react";
import { FieldWrapper } from "@progress/kendo-react-form";

import { Error, Hint } from "@progress/kendo-react-labels";
import "uvagriotLayouts/utility/styles/uvagriotStyle.css";
import TextField from "@mui/material/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@mui/material/InputLabel";

export const FormInputMod = (fieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    type,
    optional,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  return (
    <FieldWrapper>
      <div className={"k-form-field-wrap"}>
        <TextField
          label={label}
          fullWidth
          valid={valid}
          type={type}
          id={id}
          disabled={disabled}
          error={showValidationMessage}
          ariaDescribedBy={`${hintId} ${errorId}`}
          {...others}
          //error={errors.emailError}
          //helperText={errors.emailErrorMsg}
        />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </div>
    </FieldWrapper>
  );
};

export const PasswordInput = (fieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    type,
    visiblity,
    optional,
    ...others
  } = fieldRenderProps;

  const [visible, setVisible] = React.useState(false);

  const handlerVisibile = () => setVisible(!visible);

  const handleClickShowPassword = (e) => {
    e.preventDefault();

    setVisible(!visible);
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  return (
    <FieldWrapper>
      <div className={"k-form-field-wrap "}>
        <FormControl variant="outlined" fullWidth className={others.className}>
          <InputLabel
            htmlFor="outlined-adornment-password"
            style={{ color: showValidationMessage ? "red" : "" }}
          >
            {label}
          </InputLabel>
          <OutlinedInput
            valid={valid}
            type={visible ? "text" : "password"}
            id={id}
            disabled={disabled}
            error={showValidationMessage}
            aria-describedby={`${hintId} ${errorId}`}
            name="password"
            {...others}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseUp={handleMouseDownPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {visible ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>

        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </div>
    </FieldWrapper>
  );
};

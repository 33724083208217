import { useState, useEffect } from "react";
//import '@progress/kendo-theme-default/dist/default-ocean-blue.scss';
import "@progress/kendo-theme-default/dist/all.css";

import React from "react";
//import Button from 'react-bootstrap/Button';

import MDBox from "components/MDBox";
//import "../style/style.css";
import { StatusCodes } from "http-status-codes";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBCol,
} from "mdb-react-ui-kit";

import Grid from "@mui/material/Grid";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import {
  FormInput,
  PasswordInput,
  FormDropDownList,
  FormTextArea,
  FormSwitch,
} from "uvagriotLayouts/utility/form-components";
import {
  existParamValidator,
  existLabelValidator,
  errorPwdValidator,
  passwordFormValidator,
  handlerPassword,
} from "uvagriotLayouts/utility/validators";
import uvagriotService from "../../../services/uvagriot-service";
import { Typography } from "@mui/material";

import authService from "services/auth-service";

export default function PasswordForm(props) {
  const { toggleShow, modalHook, userID } = props;

  const [errorPwd, setErrorPwd] = useState({
    oldpwd: false,
    similarpwd: false,
  });

  const formRef = React.useRef(null);

  const handlerError = () =>
    errorPwd &&
    setErrorPwd({
      oldpwd: false,
      similarpwd: false,
    });

  const [initialState, setInitialState] = useState({
    old_password: "",
    password: "",
    password2: "",
  });

  /* useEffect(()=>{
        console.log(localStorage.getItem('user'))
    }) */

  const closeModal = () => {
    closeForm(formRef.current.resetForm);
  };

  const closeForm = (reset) => {
    reset();
    toggleShow();
  };

  /* const notificationAction = (type) => {

        console.log(type)
        if (type === "create") {
            NotificationManager.success(
                "Ricetta creata con successo",
                "",
                4000
            );
        } else {
            NotificationManager.success(
                "Ricetta modificata con successo",
                "",
                4000
            );
        }
    } */

  const submit = async (dataItem) => {
    /* 
            descrizione:""
            is_structure:true
            label_frontend:""
            nome:""
            tipologia:""
            unita_di_misura:""       
        */

    const response = await authService.changePasswordPUT(
      userID,
      JSON.stringify(dataItem)
    );

    console.log(response);

    if (response.status === StatusCodes.BAD_REQUEST) {
      switch (Object.keys(response.error)[0]) {
        case "old_password":
          setErrorPwd({ ...errorPwd, oldpwd: true });
          break;
        case "password":
          setErrorPwd({ ...errorPwd, similarpwd: true });
          break;
        default:
          break;
      }
    } else {
      closeForm(formRef.current.resetForm);
    }
  };

  useEffect(() => {
    formRef.current.resetForm();
  }, [initialState]);

  return (
    <>
      <MDBModal
        staticBackdrop
        tabIndex="-1"
        show={modalHook.staticModal}
        setShow={modalHook.setStaticModal}
        className="pt-5"
      >
        <MDBModalDialog>
          <MDBModalContent>
            <MDBox
              mx={1}
              mt={-3}
              py={1}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDBModalHeader style={{ border: 0 }}>
                <MDBModalTitle style={{ color: "whitesmoke" }}>
                  {"Modifica Password"}
                </MDBModalTitle>
                <MDBBtn
                  className="btn-close float right"
                  color="none"
                  aria-label="Close"
                  onClick={closeModal}
                />
              </MDBModalHeader>
            </MDBox>

            <MDBModalBody className="px-4 py-0">
              <MDBCol xl={12} lg={12} className="mb-4 pr-3">
                <Form
                  ref={formRef}
                  onSubmit={submit}
                  initialValues={initialState}
                  validator={(values) =>
                    passwordFormValidator(values, errorPwd)
                  }
                  key={"form_recipe"}
                  render={(formRenderProps) => (
                    <FormElement>
                      <fieldset className={"k-form-fieldset"}>
                        {formRenderProps.visited &&
                          formRenderProps.errors &&
                          formRenderProps.errors.VALIDATION_SUMMARY && (
                            <div
                              className={
                                "k-messagebox k-messagebox-error mt-3 mb-0"
                              }
                            >
                              {formRenderProps.errors.VALIDATION_SUMMARY}
                            </div>
                          )}

                        <Grid container columnSpacing={2}>
                          <Grid item xs={12}>
                            <Field
                              id={"pwdID"}
                              name={"old_password"}
                              label={"Password corrente"}
                              component={PasswordInput}
                              onChange={handlerError}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Field
                              id={"newpwd"}
                              name={"password"}
                              label={"Nuova password"}
                              component={PasswordInput}
                              onChange={handlerError}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Field
                              id={"reppwd"}
                              name={"password2"}
                              label={"Ripeti password"}
                              component={PasswordInput}
                            />
                          </Grid>
                        </Grid>
                      </fieldset>
                      <div className="k-form-buttons">
                        <Button
                          themeColor={"primary"}
                          type={"submit"}
                          disabled={!formRenderProps.allowSubmit}
                        >
                          {"Aggiorna"}
                        </Button>
                        <Button
                          onClick={() => closeForm(formRenderProps.onFormReset)}
                        >
                          Chiudi
                        </Button>
                      </div>
                    </FormElement>
                  )}
                />
              </MDBCol>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <NotificationContainer />
    </>
  );
}

import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useEffect } from "react";
import { Scrollbar } from "smooth-scrollbar-react";
import TablePagination from "@mui/material/TablePagination";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

import { styled } from "@mui/material/styles";

// Images
import MDButton from "components/MDButton";

import { BsPlusCircleFill } from "react-icons/bs";
import { BsCheck2Circle } from "react-icons/bs";
import { BsXCircle, BsPen } from "react-icons/bs";
import { BsX, BsPlusCircleDotted, BsPlus } from "react-icons/bs";
import { BsTrash3, BsFileText } from "react-icons/bs";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import Popover from "@mui/material/Popover";
import uvagriotService from "../../../services/uvagriot-service";
import { useState } from "react";

import { MDBCheckbox, MDBSelect } from "mdb-react-ui-kit";

import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "react-select";
import Chip from "@mui/material/Chip";
import { dataCsvIcon } from "@progress/kendo-svg-icons";
import ParamForm from "./ParamForm";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";

import {
  enumStructureKey,
  regexStructureKey,
  enumTypeParam,
} from "uvagriotLayouts/utility/enumerators";

//const structureKey = Object.values(enumStructureKey);

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function DeleteButton(props) {
  const { deleteAction, parametro } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const label = "elimina";

  return (
    <>
      <Tooltip title={label} TransitionComponent={Zoom} placement="top">
        <span>
          <IconButton color="error" onClick={handleClick}>
            <BsTrash3 size={20} className="p-0" />
          </IconButton>
        </span>
      </Tooltip>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Typography sx={{ p: 2 }} variant="h6">
          Sei Sicuro di voler eliminare questa tipologia?
        </Typography>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "5px 16px 5px 16px",
          }}
        >
          <MDButton
            variant="text"
            color="info"
            onClick={handleClose}
            size="small"
          >
            indietro
          </MDButton>
          <MDButton
            color={"error"}
            size="small"
            onClick={() => deleteAction(parametro)}
          >
            Elimina
          </MDButton>
        </div>
      </Popover>
    </>
  );
}

function Row(props) {
  const { row, updateAction, deleteAction } = props;

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell component="th" scope="row">
          {row.nome}
        </TableCell>
        <TableCell align="center">{enumTypeParam[row.tipologia]}</TableCell>
        <TableCell align="center">{row.descrizione}</TableCell>
        <TableCell align="center">{row.unita_di_misura}</TableCell>
        <TableCell align="right">
          <ParamEditButton color="info" onClick={() => updateAction(row)}>
            <BsPen size={20} />
          </ParamEditButton>
          <DeleteButton
            key={row.pk}
            deleteAction={deleteAction}
            parametro={row}
          />
        </TableCell>
      </TableRow>

      <TableRow></TableRow>
    </React.Fragment>
  );
}

export default function ParamTable(props) {
  const { rows, paramsHook } = props;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [staticModal, setStaticModal] = useState(false);
  const toggleShow = () => setStaticModal(!staticModal);

  const [staticModalEdit, setStaticModalEdit] = useState(false);
  const toggleShowEdit = () => setStaticModalEdit(!staticModalEdit);

  const [editValue, setEditValue] = useState({});

  const updateValue = (data) => {
    setEditValue(data);
    toggleShowEdit();
  };

  const remove = async (parametro) => {
    const response = await uvagriotService.parametriDELETE(
      JSON.stringify({
        paramID: parametro.id,
      })
    );

    console.log(response.data);

    if (response.status === 200) {
      let copy = [...paramsHook.params];
      paramsHook.setParams(copy.filter((param) => param.id !== parametro.id));
    } else {
      //do something
    }
  };

  return (
    <>
      <TableContainer component={Paper} style={{ minHeight: "500px" }}>
        <Table aria-label="collapsible table">
          <TableHead sx={{ display: "table-header-group" }}>
            <TableRow>
              <TableCell>Parametro</TableCell>
              {/* <TableCell style={{maxWidth: '40px'}}>Descrizione</TableCell> */}
              <TableCell align="center">tipologia</TableCell>
              <TableCell align="center">Descrizione</TableCell>
              <TableCell align="center">Unità di misura</TableCell>
              <TableCell component="td" align="right">
                <Button
                  variant="text"
                  color="primary"
                  style={{ paddingRight: 17 }}
                  onClick={toggleShow}
                  id={"addParamID"}
                >
                  <BsPlus size={30} align={"right"} />
                  AGGIUNGI
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              {/* <TableCell colSpan={5} style={{hidden:true}}></TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <Row
                  key={row.pk}
                  row={row}
                  updateAction={updateValue}
                  deleteAction={remove}
                />
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          colSpan={3}
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage="righe per pagina"
          SelectProps={{
            select: {
              marginbottom: "10px",
            },
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </TableContainer>
      <ParamForm
        id={"createFormID"}
        paramsHook={paramsHook}
        toggleShow={toggleShow}
        modalHook={{ staticModal: staticModal, setStaticModal: setStaticModal }}
        modality={"create"}
      />
      <ParamForm
        id={"editFormID"}
        paramsHook={paramsHook}
        toggleShow={toggleShowEdit}
        modalHook={{
          staticModal: staticModalEdit,
          setStaticModal: setStaticModalEdit,
        }}
        editValue={editValue}
        modality={"edit"}
      />
    </>
  );
}

const ParamEditButton = styled(IconButton)({
  "&:hover": {
    color: "blue",
    boxShadow: "none",
  },
});

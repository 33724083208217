import React, { useState, useEffect } from "react";

/* import { v } from "../style/Variables"; */
/* import { Button, ButtonGroup, Dropdown } from "react-bootstrap"; */

import MDBox from "components/MDBox";
//import "../style/formStyle.css";
import Grid from "@mui/material/Grid";
import uvagriotService from "../../../services/uvagriot-service";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { BsSearch } from "react-icons/bs";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import ParamTable from "./ParamsTable";

export default function DashboardParametri() {
  const [rows, setRows] = useState([]);

  const [search, setSearch] = useState("");
  const [filteredRow, setFilteredRow] = useState([]);

  //const [tabsOrientation, setTabsOrientation] = useState("horizontal");

  useEffect(() => {
    const getParams = async () => {
      const data = await uvagriotService.parametriGET();
      console.log("data");
      console.log(data);
      setRows(data.data);
      setFilteredRow(data.data);
    };

    getParams();
  }, []);

  useEffect(() => {
    const filteredRows_ = rows.filter((elem) =>
      elem.nome.toLowerCase().includes(search)
    );
    setFilteredRow(filteredRows_);
  }, [rows]);

  const filterRows = (e) => {
    let search_ = e.target.value.toLowerCase();
    const filteredRows_ = rows.filter((elem) =>
      elem.nome.toLowerCase().includes(search_)
    );
    setFilteredRow(filteredRows_);
    setSearch(e.target.value);
  };
  return (
    <>
      <MDBox pb={2} pt={1} px={2}>
        <Grid container spacing={0}>
          <Grid item xs={12} md={6} lg={4} my={3}>
            <TextField
              id="input-with-icon-textfield"
              label="Parametri"
              fullWidth
              value={search}
              onChange={filterRows}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BsSearch />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} md={12} xl={12}>
            <div id="id_standard_param">
              <ParamTable
                id={"tableParamID"}
                rows={filteredRow}
                paramsHook={{ params: rows, setParams: setRows }}
              />
            </div>
          </Grid>
        </Grid>
      </MDBox>

      {/* <NotificationContainer/> */}
    </>
  );
}

// #End region

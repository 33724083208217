import { Component } from "react";
import DashBoardClienti from "./components/dashboard";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import UvagriotTemplate from "uvagriotLayouts/utility/UvagriotTemplate";


export default class Clienti extends Component {
    render() {
        return (
            <UvagriotTemplate
                title="Clienti"
                component={<DashBoardClienti />}
            />

        );
    }
}

import React, { Component } from "react";
import DashboardParametri from "./components/DashboardParametri";
import TipologiaNodiTemplate from "uvagriotLayouts/TipologiaNodo/components/tipologiaTemplate";

export default class DashboardParametriComponent extends Component {
    render() {
        return (
            <TipologiaNodiTemplate
                title="Parametri"
                component={<DashboardParametri />}
            />

        );
    }
}

// #End region

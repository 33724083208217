import { useState, useEffect } from "react";
//import '@progress/kendo-theme-default/dist/default-ocean-blue.scss';
import "@progress/kendo-theme-default/dist/all.css";
import React from "react";
//import Button from 'react-bootstrap/Button';
import MDBox from "components/MDBox";
import "uvagriotLayouts/utility/styles/uvagriotStyle.css";
import MDTypography from "components/MDTypography";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";

import { StatusCodes } from "http-status-codes";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import {FormDropDownList} from "uvagriotLayouts/utility/form-components";
import uvagriotService from "../../../services/uvagriot-service";

import InfoPianificazione from "./infoPianificazione";

export default function PianificazioneForm(props) {
  const { info, droni, paramsHook , activeDrone} =
    props;

  const formRef = React.useRef(null);

  const [initialState, setInitialState] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  /* const closeModal = () => {
    closeForm(formRef.current.resetForm);
  };

  const closeForm = (reset) => {
    reset();
    toggleShow();
  };
 */
  useEffect(()=>{
    setErrorMessage(null);
  },[droni.droni])

  const submit = async (dataItem) => {
    console.log("submit --------------------------");
    console.log(dataItem);

    //? POST request al server per creare la pianificazione del volo con la coppia drone - nodo

    const response = await uvagriotService.pianificazioniPOST(info.id,
      JSON.stringify(dataItem)
    );

    //console.log("response data");
    //console.log(response.data);
    switch(response.status){

      case StatusCodes.OK:
        let copy = [...paramsHook.rows];
        copy.unshift(response.data)
        droni.setDroni(droni.droni.filter(drone=> drone.nome !== response.data.drone))
        paramsHook.setRows(copy);
        console.log("data")
        console.log(response.data)
        console.log(response.data.stato)
        activeDrone.setActiveDrone(response.data)
        setErrorMessage(null);
        break;

      case StatusCodes.BAD_REQUEST:
        setErrorMessage(response.msg);
        break;

      default:
        alert("qualcosa è andato storto");

    }

    formRef?.current?.resetForm();

  };

  const defineAction = () =>{


    if(activeDrone.activeDrone){
    
      return(<>
        <MDBox mb={1} lineHeight={0}>
        <MDTypography variant="caption" color="text">
            Drone :&nbsp;&nbsp;&nbsp;
            <MDTypography variant="caption" fontWeight="medium">
              {activeDrone.activeDrone.drone}
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
        <MDTypography variant="caption" color="text">
            Data :&nbsp;&nbsp;&nbsp;
            <MDTypography variant="caption" fontWeight="medium">
              {activeDrone.activeDrone.timestamp}
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
        <MDTypography variant="caption" color="text">
            Stato :&nbsp;&nbsp;&nbsp;
           
            <MDTypography variant="caption" fontWeight="medium">

                  <Chip
              variant="outlined"
              color="warning"
              label="in volo/trasferimento dati"
              size="small"
              />
            
              </MDTypography>
          </MDTypography>
        </MDBox>
      </>)
    }
    
    if (droni?.droni?.length){
      return (
        <MDBox mb={1} lineHeight={0}>
        <MDTypography variant="caption" color="text">
            Drone :&nbsp;&nbsp;&nbsp;
            <MDTypography variant="caption" fontWeight="medium">
            <Form
        ref={formRef}
        onSubmit={submit}
        initialValues={initialState}
        key={"form_recipe"}
        render={(formRenderProps) => (
          <FormElement>
            <fieldset className={"k-form-fieldset"}>

              <Grid container>
                
                <Grid item xs={12} md={12}>
                  {/* select dei droni disponibili per la pianificazione di volo */}
                <Field
                    id={"droniID"}
                    name={"Droni"}
                    // label={"Drone"}
                    textField={"nome"} 
                    component={FormDropDownList}
                    data={droni.droni}
                  />
                </Grid>
              </Grid>
            </fieldset>
            <div className="k-form-buttons">
              <Button
                themeColor={"primary"}
                type={"submit"}
                disabled={!formRenderProps.allowSubmit}
              >
                Pianifica Volo
              </Button>
            </div>
          </FormElement>
        )
      
    }
    />
            </MDTypography>
          </MDTypography>
        </MDBox>
     )
    }
    return (
      <MDBox mb={1} lineHeight={0}>
        <MDTypography variant="caption" color="text">
            Drone :&nbsp;&nbsp;&nbsp;
            <MDTypography variant="caption" fontWeight="medium">
            <div className={"k-messagebox k-messagebox-warning"}>
            {
              "Nessun drone è disponibile al momento"
            }
          </div>
            </MDTypography>
          </MDTypography>
        </MDBox>
          
        )
  }
  useEffect(() => {
    formRef?.current?.resetForm();
  }, [initialState]);

  return (
    <>
    <InfoPianificazione
        nodo = {info}
        form={defineAction()}
        errors={errorMessage}
      />
    </>
  );
}

import React, { useCallback, useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

/* import { v } from "../style/Variables"; */
/* import { Button, ButtonGroup, Dropdown } from "react-bootstrap"; */

import MDBox from "components/MDBox";
import "../style/formStyle.css";
import Grid from "@mui/material/Grid";
import { useMaterialUIController } from "context";
import TipologiaNodiTemplate from "./tipologiaTemplate";
import ParamTable from "./DataTable";


import MDTypography from "components/MDTypography";

// Data

import MDButton from "components/MDButton";
import uvagriotService from "../../../services/uvagriot-service";

import { Form, Field, FormElement } from "@progress/kendo-react-form";

import {
    FormInput,
    FormTextArea,
    FormComboBox,
    FormDropDownList

} from "../../utility/form-components";
import {
    checkvalidator,
    typeValidator

} from "../../utility/validators";




function DashBoardInsertVar() {

    const [data, setData] = useState([]);
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const location = useLocation();
    const [initial, setInitial] = useState({
        "tipologia": "",
        "descrizione": "",
        "modello": location.state.modelli[0],
        "coltura": location.state.colture[0]
    });

    const navigate = useNavigate();

    const goBack = () => {
        navigate("/tipologia-nodo");
    };

    const submit = async (dataitem) => {


        const response = await uvagriotService.tipologiaNodoPOST(JSON.stringify({
            'nodo': dataitem.tipologia,
            'modello': dataitem.modello.pk,
            'coltura': dataitem.coltura.pk,
            'descrizione': dataitem.descrizione,
            'parametri': data
        }))

        switch (response.status) {
            case 200:
                break;
            case 409:
                alert(response.msg);
                break;
        }
        goBack();



    }

    return (
        <><MDBox pt={3}>
            <MDBox pt={1} pb={2} >
                <Grid container spacing={0} >
                    <Grid item xs={12} sm={12} md={5} xl={4} mb={3} px={2}>
                        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}
                            bgColor={darkMode ? "transparent" : "grey-100"}
                            style={{ borderRadius: '0.5rem' }}>
                            <MDBox
                                id={'box_tipologia'}
                                component="li"
                                display="flex"
                                justifyContent="space-between"
                                borderRadius="lg"
                                my={2}
                            >
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12} xl={12}>
                                        <Form

                                            onSubmit={submit}
                                            initialValues={initial}
                                            validator={() => checkvalidator(data.filter(e => e.modality === 'view').length)}

                                            render={(formRenderProps) => (
                                                <FormElement px={2}>
                                                    <fieldset className={"k-form-fieldset px-3"}>

                                                        {formRenderProps.visited &&
                                                            formRenderProps.errors &&
                                                            formRenderProps.errors.VALIDATION_SUMMARY && (
                                                                <div className={"k-messagebox k-messagebox-error"}>
                                                                    {formRenderProps.errors.VALIDATION_SUMMARY}
                                                                </div>
                                                            )}
                                                        <Field
                                                            id={"id_tip"}
                                                            name={"tipologia"}
                                                            label={"tipologia"}
                                                            component={FormInput}
                                                            validator={(choice) => typeValidator(choice, location.state.tipologie)}

                                                        />
                                                        <Field
                                                            id={"id_descrizione"}
                                                            name={"descrizione"}
                                                            label={"Descrizione"}
                                                            component={FormTextArea}
                                                            rows={12}
                                                        />
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} md={6}>
                                                                <Field
                                                                    id={"modelloID"}
                                                                    name={"modello"}
                                                                    label={"Modello"}
                                                                    textField={"modello"}
                                                                    component={FormComboBox}
                                                                    data={location.state.modelli}
                                                                />

                                                            </Grid>
                                                            <Grid item xs={12} md={6}>
                                                                <Field
                                                                    id={"colturaID"}
                                                                    name={"coltura"}
                                                                    label={"Coltura"}
                                                                    textField={"nome"}
                                                                    component={FormComboBox}
                                                                    data={location.state.colture}
                                                                />


                                                            </Grid>
                                                        </Grid>


                                                    </fieldset>

                                                    <div className="k-form-buttons px-3">

                                                        <MDButton
                                                            variant="gradient"
                                                            color="info"
                                                            className="float-right"
                                                            type={"submit"}

                                                            disabled={!formRenderProps.allowSubmit}
                                                            fullWidth
                                                        >
                                                            + tipologia
                                                        </MDButton>

                                                    </div>
                                                </FormElement>
                                            )}
                                        />

                                    </Grid>

                                </Grid>
                            </MDBox>
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} sm={12} md={7} xl={8} px={2}>

                        <MDBox className={"mx-0 mb-2 mt-0"}>
                            <ParamTable
                                rows={data}
                                setRows={setData}
                            />
                        </MDBox>
                    </Grid>
                </Grid>
            </MDBox>

        </MDBox>
        </>
    );
}

// #End region


export class CreateTipologia extends React.Component {
    render() {
        return (
            <TipologiaNodiTemplate
                title="Crea tipologia nodo"
                component={<DashBoardInsertVar />}
            />

        );
    }
}
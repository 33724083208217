import { Component } from "react";
import DashBoardAppezzamenti from "./components/dashboard";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import UvagriotTemplate from "uvagriotLayouts/utility/UvagriotTemplate";


export default class Appezzamenti extends Component {
  render() {
    return (
      <UvagriotTemplate
        title="Appezzamenti"
        component={<DashBoardAppezzamenti />}
      />

    );
  }
}

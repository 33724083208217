import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import React, {useEffect, useState} from "react";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {RJSFSchema} from "@rjsf/utils";
import {NotificationContainer, NotificationManager} from "react-notifications";
import Form from "@rjsf/core";
import {customizeValidator} from "@rjsf/validator-ajv8";
import localizer from "ajv-i18n";
import MDButton from "../../components/MDButton";
import UvagriotService from "../../services/uvagriot-service";
import {UiSchema} from "@rjsf/utils";
import uvagriotService from "../../services/uvagriot-service";


export default function DSS_Pianificazione_Single() {

    let location = useLocation();
    const [formData, setFormData] = useState(null);
    const [listTipologie, setListTipologie] = useState([])
    const [listParametri, setListParametri] = useState([])
    const [listSensori, setListSensori] = useState([])
    const [listAttuatori, setListAttuatori] = useState([])
    const navigate = useNavigate()
    console.log(location.state);

    useEffect(() => {
        if (location.state.id > 0) {
            getPianificazione();
        }
        getTipologie()
        getParametri()
        getlistNodi()
    }, []);

    useEffect(() => {

    }, [formData])

    const getTipologie = async () => {
        let response = await UvagriotService.tipologiaColturaLIST()
        let tmp_list = []
        for (let k in response) {
            if (response[k]?.is_active) {
                tmp_list.push({const: response[k]?.id, title: response[k]?.nome})
            }
        }
        setListTipologie(tmp_list)
    }

    const getParametri = async () => {
        let response = await uvagriotService.parametriGET()
        let tmp_list = []
        for (let k in response?.data) {
            if (response?.data[k]?.tipologia === 0) {
                tmp_list.push({const: response?.data[k]?.id, title: response?.data[k]?.descrizione})
            }
        }
        console.log(tmp_list)
        setListParametri(tmp_list)
    }

    const getlistNodi = async () => {
        let response = await UvagriotService.nodiGET(location.state.appezzamento);
        let tmp_list_sensori = []
        let tmp_list_attuatori = []
        for (let k in response?.data) {
            if (response?.data[k]?.is_sensor) {
                tmp_list_sensori.push(response?.data[k]?.nomeUnivoco)
            } else {
                tmp_list_attuatori.push(response?.data[k]?.nomeUnivoco)
            }
        }
        setListSensori(tmp_list_sensori)
        setListAttuatori(tmp_list_attuatori)
      }

    let getPianificazione = async () => {
        let response = await UvagriotService.pianificazioneGET(location.state.id);
        let tmp_form_data = {
            "tipologia_coltura": response?.tipologia_coltura?.id,
            "data_inizio": response?.data_inizio,
            "parametro_gdd": response?.parametro_gdd?.id,
        }
        if(response?.data_fine){
            tmp_form_data.data_fine = response?.data_fine
        }
        let tmp_sensori = []
        let tmp_attuatori = []
        response = await UvagriotService.pianificazioneNodoLIST(response?.id);
        for(let k in response){
            if(response[k]?.is_sensor){
                tmp_sensori.push(response[k]?.nodo?.nomeUnivoco)
            } else {
                tmp_attuatori.push(response[k]?.nodo?.nomeUnivoco)
            }
        }
        tmp_form_data.sensori = tmp_sensori
        tmp_form_data.attuatori = tmp_attuatori
        console.log(tmp_form_data)
        setFormData(tmp_form_data)
    }

    const schema: RJSFSchema = {
        title: location.state.id > 0 ? "Modifica Pianificazione" : "Nuova Pianificazione",
        type: 'object',
        required: ['tipologia_coltura', 'data_inizio', 'parametro_gdd', 'sensori', 'attuatori'],
        properties: {
            tipologia_coltura: {
                type: 'number',
                title: 'Tipologia Coltura',
                oneOf: listTipologie
            },
            data_inizio: {type: 'string', format: 'date', title: 'Data Inizio'},
            parametro_gdd: {
                type: 'number',
                title: 'Parametro GDD',
                oneOf: listParametri,

            },
            sensori: {
                type: "array",
                title: "Sensori",
                uniqueItems: true,
                items: {
                    type: "string",
                    enum: listSensori
                }
            },
            attuatori: {
                type: "array",
                title: "Attuatori",
                uniqueItems: true,
                items: {
                    type: "string",
                    enum: listAttuatori
                }
            },
            data_fine: {type: 'string', format: 'date', title: 'Data FIne'},
        },
    };

    const uiSchema: UiSchema = {
        "ui:submitButtonOptions": {
            "props": {
                "disabled": false,
                "className": "btn btn-info"
            },
            "submitText": "Applica"
        }
    }

    const onSubmit = async () => {
        console.log(formData);
        let response = null
        formData.id = location.state.id
        formData.appezzamento = location.state.appezzamento
        response = await UvagriotService.pianificazionePOST(formData);
        console.log(response)
        await createNotification(response)
    }

    const createNotification = async (response) => {
        if (response?.id > 0) {
            navigate("/dss/pianificazione", {state: {appezzamento: location.state.appezzamento}});
        } else {
            let error = response?.error
            NotificationManager.error(
                error,
                "Errore",
                5000
            );
        }
    };

    const validator = customizeValidator({}, localizer.it);

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox py={1}>
                <MDBox>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Form
                                schema={schema}
                                uiSchema={uiSchema}
                                formData={formData}
                                onChange={(e) => setFormData(e.formData)}
                                validator={validator}
                                onSubmit={onSubmit}
                            >
                                <div style={{"padding-top": 15}}>
                                    <MDButton
                                        variant="gradient"
                                        color="info"
                                        m={5}
                                        type={"submit"}
                                    >
                                        Invia
                                    </MDButton>
                                    &nbsp;
                                    <NavLink to={`/dss/pianificazione`}
                                             state={{appezzamento: location.state.appezzamento}}>
                                        <MDButton variant="gradient" color="warning">
                                            Annulla
                                        </MDButton>
                                    </NavLink>
                                </div>
                            </Form>
                        </Grid>
                    </Grid>
                </MDBox>
            </MDBox>
            <NotificationContainer/>
        </DashboardLayout>
    );
}
import styled from "styled-components";
import React from "react";

import DashBoardGraf from "./dashBoardgraf";
import RealTime from "./dashBoardRealTime";

import Example from "./dashBoardMotor";

import {useNavigate, useLocation} from "react-router-dom";
import {useEffect} from "react";

import {NavLink} from "react-router-dom";
import {isAuthenticated} from "../../../utils";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import "primereact/resources/themes/lara-light-indigo/theme.css";

import "primereact/resources/primereact.min.css";
import "bootstrap/dist/css/bootstrap.min.css";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import "leaflet/dist/leaflet.css";
import "bootstrap/dist/css/bootstrap.css";
import uvagriotService from "services/uvagriot-service";
import {useState} from "react";
import {
    MDBIcon,
    MDBTabs,
    MDBTabsContent,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsPane,
} from "mdb-react-ui-kit";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import breakpoints from "assets/theme/base/breakpoints";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";

import Box from "@mui/material/Box";

import TabList from "@mui/lab/TabList";
import AttuazioniTemp from "./ricette";
import Attuazione from "./attuazione";
import UvagriotService from "../../../services/uvagriot-service";

export default function DashboardNodi(props) {
    const {nodoID, tipologiaID} = props;

    const [tabsOrientation, setTabsOrientation] = useState("horizontal");
    const [tabValue, setTabValue] = useState(0);

    const [permission, setPermission] = useState(false);

    const [value, setValue] = React.useState("1");

    const [act, setAct] = useState([]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    let history = useNavigate();

    const [basicActive, setBasicActive] = useState("tab1");

    const handleBasicClick = (value) => {
        if (value === basicActive) {
            return;
        }

        setBasicActive(value);
    };

    const getRealtimeParams = async () => {

        const data = await UvagriotService.getRealtimeParams(nodoID);
        console.log("data")
        console.log(data)
        setAct(data.attuatori);
    }

    useEffect(() => {
        getRealtimeParams();
    }, []);

    useEffect(() => {
        // A function that sets the orientation state of the tabs.
        function handleTabsOrientation() {
            return window.innerWidth < breakpoints.values.sm
                ? setTabsOrientation("vertical")
                : setTabsOrientation("horizontal");
        }

        /**
         The event listener that's calling the handleTabsOrientation function when resizing the window.
         */
        window.addEventListener("resize", handleTabsOrientation);

        // Call the handleTabsOrientation function to set the state with the initial value.
        handleTabsOrientation();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleTabsOrientation);
    }, [tabsOrientation]);

    const handleSetTabValue = (event, newValue) => setTabValue(newValue);

    /* useEffect(() => {
      const getPermission = async () => {
        const response = await uvagriotService.getMachineDatasPermission(nodoID);

        console.log("PERMISSION");
        console.log(response);

        if (response.status === 200 && response.permesso) {
          console.log("accept");
          setPermission(response.permesso);
        } else {
          history("/nodi");
        }
      };
      //getPermission();

      //getMachineDatasPermission
    }, []); */

    return (
        <>
            {/* {permission && ( */}

            <MDBox pb={2} pt={1} px={2}>
                <TabContext value={value}>
                    <Box>
                        <TabList
                            onChange={handleChange}
                            aria-label="lab API tabs example"
                            TabIndicatorProps={{
                                style: {
                                    border: "solid 1px #33ba3b",
                                },
                            }}
                        >
                            <Tab label="Realtime" value="1"/>
                            <Tab label="Storico" value="2"/>
                            <Tab label="Attuazione Temporizzate" value="3"
                                 style={{display: act.length > 0 ? "" : "none"}}/>
                            <Tab label="Attuazione Manuale" value="4" style={{display: act.length > 0 ? "" : "none"}}/>
                        </TabList>
                    </Box>

                    <TabPanel value="1" sx={{padding: "0"}}>
                        <RealTime id={nodoID} style={{padding: "0 !important"}}/>
                    </TabPanel>
                    <TabPanel value="2" sx={{padding: "0"}}>
                        <DashBoardGraf id={nodoID}/>
                    </TabPanel>
                    <TabPanel value="3" sx={{padding: "0"}}>
                        <AttuazioniTemp nodoID={nodoID}/>
                    </TabPanel>
                    <TabPanel value="4" sx={{padding: "0"}}>
                        <Attuazione id={nodoID}/>
                    </TabPanel>
                </TabContext>
            </MDBox>

            {/* )} */}
        </>
    );
}

// #Region Styled Component
const ContainerDashBoard = styled.div``;

// #End region

import React, { Component } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import Divider from "@mui/material/Divider";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect } from "react";
import PasswordForm from "./passwordForm";
import UserForm from "./userdataForm";

export default function DashboardUser() {
  const [visible, setVisible] = useState(false);
  const [user, setUser] = useState({
    id: "",
    nome: "",
    cognome: "",
    dataNascita: "",
    email: "",
    sede: "",
    user_name: "",
    ruolo: "",
  });

  const [staticModal, setStaticModal] = useState(false);
  const toggleShow = () => setStaticModal(!staticModal);

  const [staticModalUser, setStaticModalUser] = useState(false);
  const toggleShowUser = () => setStaticModalUser(!staticModalUser);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("user"));
    console.log(JSON.parse(localStorage.getItem("user")));

    if (data) {
      console.log(data);
      let user_ = {
        id: data.id,
        nome: data.nome,
        cognome: data.cognome,
        dataNascita: data.dataNascita,
        email: data.email,
        //sede: data.idSedi.indirizzo,
        user_name: data.user_name ? data.user_name : data.cognome,
      };
      setUser(user_);
    }
  }, []);

  return (
    <>
      <Grid container style={{ minWidth: 250 }}>
        <Grid item xs={12} my={3} px={3}>
          <MDBox
            component="li"
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            bgColor={"grey-100"}
            borderRadius="lg"
            p={3}
            mb={1}
            mt={2}
          >
            <MDBox width="100%" display="flex" flexDirection="column">
              <MDBox mb={2}>
                <Grid container spacing={2}>
                  <Grid item xs={11}>
                    <MDTypography
                      variant="button"
                      fontWeight="medium"
                      textTransform="capitalize"
                    >
                      {"DATI PERSONALI"}
                    </MDTypography>
                  </Grid>
                  {/* <Grid item xs={1} display="flex" justifyContent="flex-end">
                  <IconButton
                    color="info"
                    label="add an alarm"
                    style={{ color: "#344767" }}
                    onClick={toggleShowUser}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Grid> */}
                </Grid>
              </MDBox>
              <MDBox mb={1}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={2}>
                    <MDTypography variant="caption" color="text">
                      NOME :&nbsp;&nbsp;&nbsp;
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <MDTypography variant="caption" fontWeight="medium" mr={3}>
                      {user.nome}
                    </MDTypography>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox mb={1}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={2}>
                    <MDTypography variant="caption" color="text">
                      COGNOME :&nbsp;&nbsp;&nbsp;
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <MDTypography variant="caption" fontWeight="medium" mr={3}>
                      {user.cognome}
                    </MDTypography>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox mb={1}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={2}>
                    <MDTypography variant="caption" color="text">
                      USERNAME :&nbsp;&nbsp;&nbsp;
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <MDTypography variant="caption" fontWeight="medium" mr={3}>
                      {user.user_name}
                    </MDTypography>
                    {/* <VisibilityOffIcon/>&nbsp;
                    <Icon>edit</Icon>&nbsp; */}
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox mb={1}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={2}>
                    <MDTypography variant="caption" color="text">
                      DATA DI NASCITA :&nbsp;&nbsp;&nbsp;
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <MDTypography variant="caption" fontWeight="medium" mr={3}>
                      {user.dataNascita}
                    </MDTypography>
                  </Grid>
                </Grid>
              </MDBox>
              {/* <MDBox mb={1}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={2}>
                    <MDTypography variant="caption" color="text">
                      SEDE :&nbsp;&nbsp;&nbsp;
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <MDTypography variant="caption" fontWeight="medium" mr={3}>
                      {user.sede}
                    </MDTypography>
                  </Grid>
                </Grid>
              </MDBox> */}
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item xs={12} mb={3} px={3}>
          <MDBox
            component="li"
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            bgColor={"grey-100"}
            borderRadius="lg"
            p={3}
            mb={1}
            mt={2}
          >
            <MDBox width="100%" display="flex" flexDirection="column">
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
                mb={2}
              >
                <MDTypography
                  variant="button"
                  fontWeight="medium"
                  textTransform="capitalize"
                >
                  {"DATI ACCOUNT"}
                </MDTypography>
              </MDBox>
              <MDBox mb={1}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={2}>
                    <MDTypography variant="caption" color="text">
                      EMAIL :&nbsp;&nbsp;&nbsp;
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <MDTypography variant="caption" fontWeight="medium" mr={3}>
                      {user.email}
                    </MDTypography>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox mb={1}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={2}>
                    <MDTypography variant="caption" color="text">
                      RUOLO :&nbsp;&nbsp;&nbsp;
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <MDTypography variant="caption" fontWeight="medium" mr={3}>
                      {user.ruolo}
                    </MDTypography>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox my={1}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <MDButton color="info" onClick={toggleShow}>
                      Modifica Password
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
      <PasswordForm
        id={"pwdFormID"}
        toggleShow={toggleShow}
        modalHook={{ staticModal: staticModal, setStaticModal: setStaticModal }}
        userID={user.id}
      />
      <UserForm
        id={"userFormID"}
        toggleShow={toggleShowUser}
        modalHook={{
          staticModal: staticModalUser,
          setStaticModal: setStaticModalUser,
        }}
        user={user}
      />
    </>
  );
}

import HttpService from "./htttp.service";

const root = process.env.REACT_APP_API_ENDPOINT;

class UvagriotService {
    //authEndpoint = process.env.API_URL;

    constructor() {
        this.header = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "JWT " + localStorage.getItem("access_token"),
            },
        };
        this.header_img = {
            headers: {
                Authorization: 'JWT ' + localStorage.getItem('access_token')
            }
        }

        this.rootSocketRecipes = process.env.REACT_APP_SOCKET_ENDPOINT;
        this.root = process.env.REACT_APP_API_ENDPOINT;
    }

    getMachineLocation = async () => {
        const endpoint = this.root + "uvagriotapp/locationAll/";
        return await HttpService.get(endpoint, this.header);
    };

    getDataAttributes = async () => {
        const endpoint = this.root + "uvagriotapp/datas/columns";
        return await HttpService.get(endpoint, this.header);
    };

    getMachineDatas = async () => {
        const endpoint = this.root + "uvagriotapp/datas/";
        return await HttpService.get(endpoint, this.header);
    };

    getMachineDatasById = async (id) => {
        const endpoint = this.root + "uvagriotapp/datas/" + id + "/";
        return await HttpService.get(endpoint, this.header);
    };

    getMachineDatasPermission = async (id) => {
        const endpoint = this.root + "uvagriotapp/dataMachinePermission/" + id + "/";
        return await HttpService.get(endpoint, this.header);
    };

    getParamsMachine = async () => {
        const endpoint = this.root + "uvagriotapp/paramsmachine/";
        return await HttpService.get(endpoint, this.header);
    };

    getDataMachine = async (payload) => {
        const endpoint = this.root + "uvagriotapp/machine_data/";
        return await HttpService.post(endpoint, payload);
    };

    getMachineMixMax = async (id) => {
        const endpoint = this.root + "uvagriotapp/cutterpress/" + id + "/";
        return await HttpService.get(endpoint, this.header);
    }

    datasselection = async (id, dataDate) => {
        console.log("dataselection check");
        const selectionEndpoint = this.root + "uvagriotapp/datasselection/" + id + "/";
        return await HttpService.post(selectionEndpoint, dataDate);
    };

    register = async (credentials) => {
        const registerEndpoint = "register";
        return await HttpService.post(registerEndpoint, credentials);
    };

    logout = async () => {
        const logoutEndpoint = "logout";
        return await HttpService.post(logoutEndpoint);
    };

    forgotPassword = async (payload) => {
        const forgotPassword = "password-forgot";
        return await HttpService.post(forgotPassword, payload);
    };

    resetPassword = async (credentials) => {
        const resetPassword = "password-reset";
        return await HttpService.post(resetPassword, credentials);
    };

    getProfile = async () => {
        const getProfile = "me";
        return await HttpService.get(getProfile);
    };

    updateProfile = async (newInfo) => {
        const updateProfile = "me";
        return await HttpService.patch(updateProfile, newInfo);
    };

    getTypeMachines = async () => {
        const endpoint = this.root + "uvagriotapp/typemachines/";
        return await HttpService.get(endpoint);
    };

    getAllTypeMachines = async () => {
        const endpoint = this.root + "uvagriotapp/allTypeMachine/";
        return await HttpService.get(endpoint);
    };

    getMachines = async (type) => {
        const params = "?type=" + type;
        const endpoint = this.root + "uvagriotapp/machines/" + params;
        return await HttpService.get(endpoint);
    };

    getParamsType = async (type) => {
        const params = "?type=" + type;
        const endpoint = this.root + "uvagriotapp/paramasType/" + params;
        return await HttpService.get(endpoint);
    };


    datasGetAllClients = async (idLog) => {
        const endpointAllClient =
            this.root + "uvagriotapp/datasGetAllClients/";
        return await HttpService.get(endpointAllClient);
    };

    getRecipes = async (payload) => {
        const endpoint = this.root + "uvagriotapp/getrecipes/";
        return await HttpService.post(endpoint, payload);
    };

    getRecipesSocket = (id) => {
        let url = `${this.rootSocketRecipes}recipes/${id}/`;
        return new WebSocket(url);
    };

    getDataMqttSocket = (id) => {
        let url = `${this.rootSocketRecipes}ws/mqtt/${id}/`;
        return new WebSocket(url);
    };

    sendRecipe = async (payload) => {
        const endpoint = this.root + "uvagriotapp/sendrecipes/";
        return await HttpService.post(endpoint, payload);
    };

    removeRecipe = async (payload) => {
        const endpoint = this.root + "uvagriotapp/removerecipes/";
        return await HttpService.post(endpoint, payload);
    };

    associanodiclienti = async (payload) => {
        const endpoint = this.root + "uvagriotapp/associa_nodi_cliente/";
        return await HttpService.post(endpoint, payload);
    };

    datasGetAll = async (payloadDataAll) => {
        const endpointDataAll = this.root + "uvagriotapp/datasGetAll/";
        return await HttpService.post(endpointDataAll, payloadDataAll);
    };

    insertnewmachineandcheck = async (payloadDataNewMachine) => {
        const endpointDataAll = this.root + "uvagriotapp/new_nodo/";
        return await HttpService.post(endpointDataAll, payloadDataNewMachine);
    };

    insertNewClientAndSede = async (payloadDataNewClientAS) => {
        const endpointDataClientAS = this.root + "uvagriotapp/insert_cliente/";
        return await HttpService.post(endpointDataClientAS, payloadDataNewClientAS);
    };

    deleteClientAndSede = async (payload) => {
        const endpoint = this.root + "uvagriotapp/delete_cliente/"
        return await HttpService.post(endpoint, payload);
    };

    getClienteAndSede = async (idClient) => {
        const endpointGetDataClient = this.root + "uvagriotapp/getclient/" + idClient + "/";
        return await HttpService.post(endpointGetDataClient);
    }

    editClientAndSede = async (payloadDataEditClientAS) => {
        const endpointDataClientAS = this.root + "uvagriotapp/editclient/";
        return await HttpService.post(endpointDataClientAS, payloadDataEditClientAS);
    };

    getRegion = async (payloadDataRegion) => {
        const endpointDataRegions = this.root + "uvagriotapp/getregions/";
        return await HttpService.post(endpointDataRegions, payloadDataRegion);
    };

    getProvince = async (payloadDataProvince) => {
        const endpointDataProvince = this.root + "uvagriotapp/getprovinces/";
        return await HttpService.post(endpointDataProvince, payloadDataProvince);
    };

    getCity = async (payloadDataCity) => {
        const endpointDataCity = this.root + "uvagriotapp/getcitys/";
        return await HttpService.post(endpointDataCity, payloadDataCity);
    };

    dissocianodo = async (payload) => {
        const endpoint = this.root + "uvagriotapp/unlink_nodo/";
        return await HttpService.post(endpoint, payload);
    };

    eliminanodo = async (payload) => {
        const endpoint = this.root + "uvagriotapp/delete_nodo/"
        return await HttpService.post(endpoint, payload);
    };

    getnodo = async (payload) => {
        const endpoint = this.root + "uvagriotapp/get_nodo/"
        return await HttpService.post(endpoint, payload);
    };

    editMachine = async (payloadDataEditMachine) => {
        const endpointDataClientAS = this.root + "uvagriotapp/edit_nodo/";
        return await HttpService.post(endpointDataClientAS, payloadDataEditMachine);
    };

    restorenodo = async (payload) => {
        const endpoint = this.root + "uvagriotapp/restore_nodo/"
        return await HttpService.post(endpoint, payload);
    };

    creaTipologia = async (payload) => {
        const endpoint = this.root + "uvagriotapp/crea-tipologia/";
        return await HttpService.post(endpoint, payload);
    };

    updateTipologia = async (payload) => {
        const endpoint = this.root + "uvagriotapp/update-tipologia/";
        return await HttpService.post(endpoint, payload);
    };

    deleteTipologia = async (pk) => {
        const endpoint = this.root + "uvagriotapp/tipologie-nodo/" + pk + "/delete/";
        return await HttpService.delete(endpoint);
    };

    getTipologieNodo = async () => {
        const endpoint = this.root + "uvagriotapp/tipoligie-nodo/";
        return await HttpService.get(endpoint);
    };

    setActiveRecipe = async (payload) => {
        const endpoint = this.root + "uvagriotapp/active-recipes/";
        return await HttpService.post(endpoint, payload);
    };

    // RICETTE  STANDARD

    // recipeGET = async (type) => {
    //     const params = "?type=" + type;
    //     const endpoint = this.root + "uvagriotapp/ricette/" + params;
    //     return await HttpService.get(endpoint);
    // };
    //
    // recipePOST = async (payload) => {
    //     const endpoint = this.root + "uvagriotapp/ricette/";
    //     return await HttpService.post(endpoint, payload);
    // };
    // recipePUT = async (payload) => {
    //     const endpoint = this.root + "uvagriotapp/ricette/";
    //     return await HttpService.put(endpoint, payload);
    // };
    // recipeDELETE = async (payload) => {
    //     const endpoint = this.root + "uvagriotapp/ricette/";
    //     return await HttpService.delete(endpoint, payload);
    // };

    // PARAMETRI

    /* parametriGET = async () => {
      const endpoint = this.root + "uvagriotapp/parametri/";
      return await HttpService.get(endpoint);
    }; */


    //================================== UVAGRIOT =========================================================================

    nodiGET = async (id) => {
        const endpoint = this.root + "uvagriotapp/appezzamenti/" + id + "/nodi/";
        return await HttpService.get(endpoint);
    };


    // PARAMETRI
    parametriGET = async () => {
        const endpoint = this.root + "uvagriotapp/parametri/";
        return await HttpService.get(endpoint);
    };
    parametriPOST = async (payload) => {
        const endpoint = this.root + "uvagriotapp/parametri/";
        return await HttpService.post(endpoint, payload);
    };
    parametriPUT = async (payload) => {
        const endpoint = this.root + "uvagriotapp/parametri/";
        return await HttpService.put(endpoint, payload);
    };
    parametriDELETE = async (payload) => {
        const endpoint = this.root + "uvagriotapp/parametri/";
        return await HttpService.delete(endpoint, payload);
    };

    // MODELLI
    modelliGET = async () => {
        const endpoint = this.root + "uvagriotapp/modelli/";
        return await HttpService.get(endpoint);
    };
    modelliPOST = async (payload) => {
        const endpoint = this.root + "uvagriotapp/modelli/";
        return await HttpService.post(endpoint, payload);
    };
    modelliPUT = async (payload) => {
        const endpoint = this.root + "uvagriotapp/modelli/";
        return await HttpService.put(endpoint, payload);
    };
    modelliDELETE = async (payload) => {
        const endpoint = this.root + "uvagriotapp/modelli/";
        return await HttpService.delete(endpoint, payload);
    };

    // COLTURE
    coltureGET = async () => {
        const endpoint = this.root + "uvagriotapp/colture/";
        return await HttpService.get(endpoint);
    };
    colturePOST = async (payload) => {
        const endpoint = this.root + "uvagriotapp/colture/";
        return await HttpService.post(endpoint, payload);
    };
    colturePUT = async (payload) => {
        const endpoint = this.root + "uvagriotapp/colture/";
        return await HttpService.put(endpoint, payload);
    };
    coltureDELETE = async (payload) => {
        const endpoint = this.root + "uvagriotapp/colture/";
        return await HttpService.delete(endpoint, payload);
    };


    // TIPOLOGIA NODO
    tipologiaNodoGET = async () => {
        const endpoint = this.root + "uvagriotapp/tipologie-nodi/";
        return await HttpService.get(endpoint);
    };

    tipologiaNodoPOST = async (payload) => {
        const endpoint = this.root + "uvagriotapp/tipologie-nodi/";
        return await HttpService.post(endpoint, payload);
    };

    tipologiaNodoPUT = async (payload) => {
        const endpoint = this.root + "uvagriotapp/tipologie-nodi/";
        return await HttpService.put(endpoint, payload);
    };

    tipologiaNodoDELETE = async (payload) => {
        const endpoint = this.root + "uvagriotapp/tipologie-nodi/";
        return await HttpService.delete(endpoint, payload);
    };

    //RICETTE
    recipeGET = async (tipologia = null) => {
        let endpoint = this.root + "uvagriotapp/ricette/"
        if (tipologia) {
            endpoint += "?tipologia=" + tipologia;
        }
        return await HttpService.get(endpoint);
    };

    recipePOST = async (payload) => {
        const endpoint = this.root + "uvagriotapp/ricette/";
        return await HttpService.post(endpoint, payload);
    };
    recipePUT = async (payload) => {
        const endpoint = this.root + "uvagriotapp/ricette/";
        return await HttpService.put(endpoint, payload);
    };
    recipeDELETE = async (payload) => {
        const endpoint = this.root + "uvagriotapp/ricette/";
        return await HttpService.delete(endpoint, payload);
    };


    parametriNodoPUT = async (payload) => {
        const endpoint = this.root + "uvagriotapp/parametri-nodi/";
        return await HttpService.put(endpoint, payload);
    };


    getRealtimeParams = async (id) => {
        const endpoint = this.root + "uvagriotapp/realtimeParams/" + id + "/";
        return await HttpService.get(endpoint);
    }

    getLastRealtimeParams = async (id) => {
        const endpoint = this.root + "uvagriotapp/lastRealtimeParams/" + id + "/";
        return await HttpService.get(endpoint);
    }

    getStatoParametriRicetta = async (ricettaID) => {
        const endpoint = this.root + "uvagriotapp/stato-parametri-ricetta/" + ricettaID + "/";
        return await HttpService.get(endpoint);
    }

    //CONFIGURAZIONE PER TIPOLOGIA
    ModelliAndColturaGET = async () => {
        const endpoint = this.root + "uvagriotapp/modelli-colture/";
        return await HttpService.get(endpoint);
    };

    getConfigurazione = async (id) => {
        const endpoint = this.root + "uvagriotapp/nodi/" + id + "/configurazione/";
        return await HttpService.get(endpoint);
    }

    appezzamentiGET = async () => {
        const endpoint = this.root + "uvagriotapp/appezzamenti/";
        return await HttpService.get(endpoint);
    };

    appezzamentiPOST = async (payload) => {
        const endpoint = this.root + "uvagriotapp/appezzamenti/";
        return await HttpService.post(endpoint, payload);
    };

    appezzamentiPUT = async (payload) => {
        const endpoint = this.root + "uvagriotapp/appezzamenti/";
        return await HttpService.put(endpoint, payload);
    };


    //UTENTI UVAGRIOT
    usersGET = async () => {
        const endpoint = this.root + "users/";
        return await HttpService.get(endpoint);
    };

    usersPOST = async (payload) => {
        const endpoint = this.root + "users/";
        return await HttpService.post(endpoint, payload);
    };

    usersDELETE = async (payload) => {
        const endpoint = this.root + "users/";
        return await HttpService.delete(endpoint, payload);
    };

    //* DRONI
    droniGET = async () => {
        const endpoint = this.root + "uvagriotapp/droni/";
        return await HttpService.get(endpoint);
    }

    statoDroniGET = async (id) => {
        const endpoint = this.root + "uvagriotapp/nodi/" + id + "/stato/";
        return await HttpService.get(endpoint);
    }

    droniPOST = async (payload) => {
        const endpoint = this.root + "uvagriotapp/droni/";
        return await HttpService.post(endpoint, payload);
    }

    droniPUT = async (payload) => {
        const endpoint = this.root + "uvagriotapp/droni/";
        return await HttpService.put(endpoint, payload);
    }

    droniDELETE = async (payload) => {
        const endpoint = this.root + "uvagriotapp/droni/";
        return await HttpService.delete(endpoint, payload);
    };

    //* PIANIFICAZIONI
    pianificazioniGET = async (id) => {
        const endpoint = this.root + "uvagriotapp/nodi/" + id + "/pianificazioni/";
        return await HttpService.get(endpoint);
    }

    pianificazioniPOST = async (id, payload) => {
        const endpoint = this.root + "uvagriotapp/nodi/" + id + "/pianificazioni/";
        return await HttpService.post(endpoint, payload);
    }

    // DSS

    tipologiaColturaLIST = async () => {
        const endpoint = this.root + "uvagriotapp/dss/tipologia_coltura/"
        return await HttpService.get(endpoint)
    }

    tipologiaColturaGET = async (id) => {
        const endpoint = this.root + "uvagriotapp/dss/tipologia_coltura/" + id + "/"
        return await HttpService.get(endpoint)
    }

    tipologiaColturaPUT = async (id, payload) => {
        const endpoint = this.root + "uvagriotapp/dss/tipologia_coltura/" + id + "/"
        return await HttpService.put(endpoint, payload);
    };

    tipologiaColturaPOST = async (payload) => {
        const endpoint = this.root + "uvagriotapp/dss/tipologia_coltura/"
        return await HttpService.post(endpoint, payload);
    };

    faseFenologicaLIST = async () => {
        const endpoint = this.root + "uvagriotapp/dss/fase_fenologica/"
        return await HttpService.get(endpoint)
    }

    faseFenologicaGET = async (id) => {
        const endpoint = this.root + "uvagriotapp/dss/fase_fenologica/" + id + "/"
        return await HttpService.get(endpoint)
    }

    faseFenologicaDELETE = async (id) => {
        const endpoint = this.root + "uvagriotapp/dss/fase_fenologica/" + id + "/"
        return await HttpService.delete(endpoint);
    };

    faseFenologicaPOST = async (payload) => {
        const endpoint = this.root + "uvagriotapp/dss/fase_fenologica/"
        return await HttpService.post(endpoint, payload);
    };

    faseFenologicaPUT = async (id, payload) => {
        const endpoint = this.root + "uvagriotapp/dss/fase_fenologica/" + id + "/"
        return await HttpService.put(endpoint, payload);
    };

    faseLIST = async () => {
        const endpoint = this.root + "uvagriotapp/dss/fase/"
        return await HttpService.get(endpoint)
    }

    faseGET = async (id) => {
        const endpoint = this.root + "uvagriotapp/dss/fase/" + id + "/"
        return await HttpService.get(endpoint)
    }

    fasePOST = async (payload) => {
        const endpoint = this.root + "uvagriotapp/dss/fase/"
        return await HttpService.post(endpoint, payload);
    };

    fasePUT = async (id, payload) => {
        const endpoint = this.root + "uvagriotapp/dss/fase/" + id + "/"
        return await HttpService.put(endpoint, payload);
    };

    soglieFaseLIST = async (fase) => {
        const endpoint = this.root + "uvagriotapp/dss/soglia/?fase=" + fase
        return await HttpService.get(endpoint)
    }

    faseSogliaGET = async (id) => {
        const endpoint = this.root + "uvagriotapp/dss/soglia/" + id + "/"
        return await HttpService.get(endpoint)
    }

    faseSogliaPOST = async (payload) => {
        const endpoint = this.root + "uvagriotapp/dss/soglia/"
        return await HttpService.post(endpoint, payload);
    };

    faseSogliaPUT = async (id, payload) => {
        const endpoint = this.root + "uvagriotapp/dss/soglia/" + id + "/"
        return await HttpService.put(endpoint, payload);
    };

    faseSogliaDELETE = async (id) => {
        const endpoint = this.root + "uvagriotapp/dss/soglia/" + id + "/"
        return await HttpService.delete(endpoint);
    };

    sogliaLIST = async () => {
        const endpoint = this.root + "uvagriotapp/dss/soglia/"
        return await HttpService.get(endpoint)
    }

    attuazioneLIST = async (soglia = 0) => {
        let search = ''
        if (soglia > 0) {
            search = "?soglia=" + soglia
        }
        const endpoint = this.root + "uvagriotapp/dss/attuazione/" + search
        return await HttpService.get(endpoint)
    }

    attuazioneGET = async (id) => {
        const endpoint = this.root + "uvagriotapp/dss/attuazione/" + id + "/"
        return await HttpService.get(endpoint)
    }

    attuazionePOST = async (payload) => {
        const endpoint = this.root + "uvagriotapp/dss/attuazione/"
        return await HttpService.post(endpoint, payload);
    };

    attuazionePUT = async (id, payload) => {
        const endpoint = this.root + "uvagriotapp/dss/attuazione/" + id + "/"
        return await HttpService.put(endpoint, payload);
    };

    attuazioneDELETE = async (id) => {
        const endpoint = this.root + "uvagriotapp/dss/attuazione/" + id + "/"
        return await HttpService.delete(endpoint);
    };

    rangeAttuazioneLIST = async (attuazione) => {
        const endpoint = this.root + "uvagriotapp/dss/attuazione_range/?attuazione=" + attuazione
        return await HttpService.get(endpoint)
    }

    rangeAttuazioneGET = async (id) => {
        const endpoint = this.root + "uvagriotapp/dss/attuazione_range/" + id + "/"
        return await HttpService.get(endpoint)
    }

    rangeAttuazionePOST = async (payload) => {
        const endpoint = this.root + "uvagriotapp/dss/attuazione_range/"
        return await HttpService.post(endpoint, payload);
    };

    rangeAttuazionePUT = async (id, payload) => {
        const endpoint = this.root + "uvagriotapp/dss/attuazione_range/" + id + "/"
        return await HttpService.put(endpoint, payload);
    };

    rangeAttuazioneDELETE = async (id) => {
        const endpoint = this.root + "uvagriotapp/dss/attuazione_range/" + id + "/"
        return await HttpService.delete(endpoint);
    };

    pianificazioneLIST = async (appezzamento) => {
        const endpoint = this.root + "uvagriotapp/dss/pianificazione/?appezzamento=" + appezzamento
        return await HttpService.get(endpoint)
    }

    pianificazioneGET = async (id) => {
        const endpoint = this.root + "uvagriotapp/dss/pianificazione/" + id + "/"
        return await HttpService.get(endpoint)
    }

    pianificazionePOST = async (payload) => {
        const endpoint = this.root + "uvagriotapp/dss/pianificazione/"
        return await HttpService.post(endpoint, payload);
    };

    pianificazionePUT = async (id, payload) => {
        const endpoint = this.root + "uvagriotapp/dss/pianificazione/" + id + "/"
        return await HttpService.put(endpoint, payload);
    };

    pianificazioneDELETE = async (id) => {
        const endpoint = this.root + "uvagriotapp/dss/pianificazione/" + id + "/"
        return await HttpService.delete(endpoint);
    };

    pianificazioneNodoLIST = async (pianificazione) => {
        const endpoint = this.root + "uvagriotapp/dss/pianificazione_nodo/?pianificazione=" + pianificazione
        return await HttpService.get(endpoint)
    }


    immaginiVoloGET = async (id) => {
        const endpoint = this.root + "uvagriotapp/droni/pianificazioni/" + id + "/images/";
        return await HttpService.get(endpoint);
    }

    // MANUAL ACTUATOR
    sendManualActuator = async (payload) => {
        const endpoint = this.root + "uvagriotapp/manual_actuator/";
        return await HttpService.post(endpoint, payload);
    }

    uploadsPOST = async (id, payload) => {
        const endpoint = this.root + "uvagriotapp/droni/pianificazioni/" + id + "/uploads/";
        return await HttpService.post(endpoint, payload)
    }

}

export default new UvagriotService();

import React, {
  useState,
  useEffect,
  useMemo,
} from "react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "bootstrap/dist/css/bootstrap.css";
import { Grid } from "@mui/material";
import PianificazioniTable from "./PanificazioniTable";
import MDBox from "components/MDBox";
import UvagriotService from "services/uvagriot-service";
import PianificazioneForm from "./PianificazioneForm";
import MDTypography from "components/MDTypography";


function DashboardVoli(props){

  const { nodoID } = props
  const [rowData, setRowData] = useState([]);
  const [nodo, setNodo]=useState([])
  const [droni, setDroni] = useState([]);
  const [activeDrone, setActiveDrone] = React.useState(null);


  const getDataMachines = async () => {

    console.log("nodoID")
    console.log(nodoID)

    const dataMachines = await UvagriotService.pianificazioniGET(nodoID);

    console.log("dataMachine ===============================");
    console.log(dataMachines);

    setRowData(dataMachines.data);
    setDroni (dataMachines.droni)
    setNodo(dataMachines.infoNodo)

    setActiveDrone(dataMachines.droneAttivoNodo);


  }
  useEffect(() => {
    getDataMachines();
  }, []);


  useEffect(() => {
    let interval;
    
    interval = setInterval(async () => {
      console.log("intervallo attivo");
      const data = await UvagriotService.statoDroniGET(
        nodoID
      );
      console.log("data INTERVAl");
      console.log(data);
      setDroni(data.droni);
      setActiveDrone(data.droneAttivoNodo);
      

    }, 10*1000);
    
    return () => clearInterval(interval);
  }, []);


  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  
  return (
    <MDBox pb={2} pt={3} px={2}>
      <Grid container spacing={0}>
        <div style={containerStyle}>
          <div className="outer-div">
            <Grid item xs={12} md={12} xl={12} >
              <div id="mineID">
                <PianificazioneForm
                  id={"pianificazioneFormID"}
                  info={nodo}
                  droni={{droni:droni, setDroni:setDroni}}
                  activeDrone = {{activeDrone: activeDrone, setActiveDrone: setActiveDrone}}
                  paramsHook = {{ rows: rowData, setRows: setRowData }}
                />
                <MDTypography variant="h5" fontWeight="medium">
                  Storico
                </MDTypography>
                
                <PianificazioniTable
                  id={'tableNodiID'}
                  rows={{ rows: rowData, setRows: setRowData }}
                  droni ={{droni:droni, setDroni:setDroni}}
                  nodo = {nodo}
                />
              </div>
            </Grid>
          </div>
        </div>
      </Grid>
    </MDBox>
  );
};

export default DashboardVoli;


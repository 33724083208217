// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import InsertNewAppezzamento from "./formInserimentoAppezzamenti";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function AllPartOfInsertAppezzamento(props) {
  const navigate = useNavigate();

  useEffect(() => {
    if (!(props.modality >= 0)) {
      navigate("/gestione_appezzamenti")
    }
  }, [])

  return (
    <MDBox pt={6} pb={3}>
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                {props.modality ? " Modifica Appezzamento" : "Nuovo Appezzamento"}
              </MDTypography>
            </MDBox>
            <InsertNewAppezzamento modality={props.modality} />
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default AllPartOfInsertAppezzamento;

/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {MDBSpinner} from "mdb-react-ui-kit";

function DefaultInfoCard({color, icon, title, description, value, type, loading = false, iconButton}) {
    return (
        <Card>
            <MDBox pb={2} px={2} textAlign="center" lineHeight={1} display="flex" justifyContent="center"
                   style={{'alignItems': 'center'}}>
                <Icon fontSize="large">{icon}</Icon> &nbsp;
                <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
                    {title}
                </MDTypography>
            </MDBox>
            <Divider/>
            <MDBox pb={2} px={2} textAlign="center" lineHeight={1} display="flex" justifyContent="center" style={{'height': '5vh'}}>
                {!type && (
                    <MDTypography variant="h4" fontWeight="medium">
                        {value}
                    </MDTypography>
                )}
                {type && !loading && (
                    <Icon onClick={iconButton} fontSize="large"
                          style={{'color': value === 1 ? "green" : "red"}}>{value === 1 ? "toggle_on" : "toggle_off"}</Icon>
                )}
                {type && loading && (
                    <MDBSpinner color='success'>
                        <span className='visually-hidden'>Loading...</span>
                    </MDBSpinner>
                )}
            </MDBox>
        </Card>
    );
}

// Setting default values for the props of DefaultInfoCard
DefaultInfoCard.defaultProps = {
    color: "info",
    value: "",
    description: "",
};

// Typechecking props for the DefaultInfoCard
DefaultInfoCard.propTypes = {
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
    icon: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default DefaultInfoCard;

import React, { Component } from "react";
import DashboardRicette from "./components/DashboardRicette";
import UvagriotTemplate from "uvagriotLayouts/utility/UvagriotTemplate";

export default class DashboardRicetteComponent extends Component {
    render() {
        return (
            <UvagriotTemplate
                title="Ricette"
                component={<DashboardRicette />}
            />

        );
    }
}

// #End region

import React from 'react';
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBBtn, MDBContainer, MDBRow, MDBCol
} from 'mdb-react-ui-kit';
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "../../components/MDTypography";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import MDButton from "../../components/MDButton";
import {NavLink} from "react-router-dom";

export default function DSS_Dashboard() {
    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox pt={6} pb={3} style={{minWidth: 280}}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    DSS
                                </MDTypography>
                            </MDBox>
                            <MDBox pb={2} pt={2} px={2}>
                                <MDBRow>
                                    <MDBCol size='md'>
                                        <MDBCard>
                                            <MDBCardBody>
                                                <MDBCardTitle>Tipologia Coltura</MDBCardTitle>
                                                <MDBCardText>
                                                    Crea, modifica o elimina le tipologie di colture.
                                                </MDBCardText>
                                                <NavLink
                                                    to={`/dss/tipologia_coltura`}
                                                >
                                                    <MDButton variant="gradient" color="info" m={5}>
                                                        Entra
                                                    </MDButton>
                                                </NavLink>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>
                                    <MDBCol size='md'>
                                        <MDBCard>
                                            <MDBCardBody>
                                                <MDBCardTitle>Fase Fenologica</MDBCardTitle>
                                                <MDBCardText>
                                                    Crea o elimina le fasi fenologiche.
                                                </MDBCardText>
                                                <NavLink
                                                    to={`/dss/fase_fenologica`}
                                                >
                                                    <MDButton variant="gradient" color="info" m={5}>
                                                        Entra
                                                    </MDButton>
                                                </NavLink>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>
                                    <MDBCol size='md'>
                                        <MDBCard>
                                            <MDBCardBody>
                                                <MDBCardTitle>Fase</MDBCardTitle>
                                                <MDBCardText>
                                                    Crea, elimina e modifica le fasi.
                                                </MDBCardText>
                                                <NavLink
                                                    to={`/dss/fase`}
                                                >
                                                    <MDButton variant="gradient" color="info" m={5}>
                                                        Entra
                                                    </MDButton>
                                                </NavLink>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>
                                </MDBRow>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>


    );
}
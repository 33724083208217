// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import AllPartOfInsertAppezzamento from "./components/allAppezzamenti";
import { useLocation } from "react-router-dom";

function InserisciNuovoAppezzamento() {
  const data = useLocation()
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={1}>
        <MDBox>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} lg={12}>
              <AllPartOfInsertAppezzamento modality={data?.state?.modality} />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default InserisciNuovoAppezzamento;

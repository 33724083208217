import React, { useEffect, useState } from "react";
import UvagriotService from "services/uvagriot-service";
/* import { Card } from "primereact/card"; */
import styled from "styled-components";

import { Knob } from "primereact/knob";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Thermometer from "react-thermometer-component";
import { Margin } from "@mui/icons-material";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { BsFillCircleFill } from "react-icons/bs";
import { ResponsiveContainer } from "recharts";
import DataTable from "react-data-table-component";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

import { Form, Field, FormElement } from "@progress/kendo-react-form";

import {
  FormInput,
  FormTextArea,
  FormNumericTextBox,
  FormComboBox,
  FormDropDownList,
} from "uvagriotLayouts/utility/form-components";

import {
  sendRecipeValidator,
  typeValidator,
  recipeValidator,
  numberValidator,
} from "uvagriotLayouts/utility/validators";
import MDButton from "components/MDButton";

import RecipesTable from "./ricetteTable";
import { CardActions, CardContent, CardHeader } from "@mui/material";
import { CardFooter } from "@progress/kendo-react-layout";

import { Scrollbar } from "smooth-scrollbar-react";

function AttuazioniTemp(props) {
  const { nodoID } = props;
  const [recipes, setRecipes] = React.useState([]);
  const [paramForm, setParamForm] = React.useState([]);
  const formRef = React.useRef(null);

  //-------------------------------------------------------------
  const [check, setCheck] = React.useState(false);
  const [statusParam, setStatusParam] = React.useState([]);
  const [lastRecipeID, setLastRecipeID] = React.useState(null);
  const [msg, setMsg] = React.useState("Controllo attuattori...");
  //-------------------------------------------------------------

  const getConfiguration = async () => {
    const data = await UvagriotService.getConfigurazione(nodoID);
    setParamForm(data.data);
    console.log("CONFIGURAZIONE: " + nodoID);
    console.log(data.data);
  };

  const getRecipes = async () => {
    const data = await UvagriotService.recipeGET(nodoID);
    setRecipes(data.data);
    setLastRecipeID(data.data[0].id);
    setCheck(true);
    console.log("Attuazioni");
    console.log(data.data);
    console.log(data.data[0].id);
  };

  useEffect(() => {
    console.log("2. SECOND");
    getRecipes();
    getConfiguration();
  }, []);

  /*TODO:
    1. chiamata API che restituisce tutti gli stati degli attuatori e stato se ha settato tutto
    2. interrompi chiamata se è tutto settato
    3. riattiva chiamata a nuovo invio della ricetta
  */

  useEffect(() => {
    console.log("1. FIRST");
    let interval;
    if (check && lastRecipeID) {
      interval = setInterval(async () => {
        console.log("intervallo attivo");
        const data = await UvagriotService.getStatoParametriRicetta(
          lastRecipeID
        );
        console.log("data");
        console.log(data);

        setStatusParam(data.data);
        setMsg(data.msg);
        if (data.statoParametri) {
          setCheck(false);
          setLastRecipeID(null);
        }
      }, 2000);
    }
    return () => clearInterval(interval);
  }, [check]);

  const submit = async (dataitem) => {
    console.log("dataItem");
    console.log(dataitem);

    let payload = {
      nome: dataitem.nome,
      descrizione: dataitem.descrizione,
      nodoID: nodoID,
      parametri: [],
    };

    delete dataitem.descrizione;
    delete dataitem.nome;

    payload.parametri = dataitem;
    console.log(payload);

    const response = await UvagriotService.recipePOST(JSON.stringify(payload));

    switch (response.status) {
      case 200:
        let _recipes = [...recipes];
        _recipes.unshift(response.data);
        console.log("response");
        console.log(response.data);
        setRecipes(_recipes);
        setLastRecipeID(response.data.id);
        setCheck(true);
        formRef.current.resetForm();
        break;
      case 409:
        alert(response.msg);
        break;
    }
  };

  return (
    <Grid container spacing={2} pt={2} alignItems="stretch">
      <Grid item md={8} xs={12}>
        {/* <div className={"k-messagebox k-messagebox-error"}>
          {"Attenzione non ci sono attuatori. Impossibile inviare una ricetta"}
        </div> */}
        <Card py={2} px={0} sx={{ background: "#f8f9fa" }}>
          {paramForm.length ? (
            <Form
              ref={formRef}
              onSubmit={submit}
              render={(formRenderProps) => (
                <FormElement px={2}>
                  <fieldset className={"k-form-fieldset px-3 pt-3"}>
                    {check && (
                      <div className={"k-messagebox k-messagebox-error"}>
                        {msg}
                      </div>
                    )}
                    <Scrollbar
                      className="scrollable"
                      alwaysShowTracks={true}
                      style={{
                        maxHeight: "400px",
                        padding: "0px 20px 20px 0px",
                      }}
                      plugins={{
                        overscroll: {
                          effect: "bounce",
                        },
                      }}
                    >
                      <MDBox>
                        <Field
                          id={"ricettaID"}
                          name={"nome"}
                          label={"Attuazione"}
                          component={FormInput}
                          validator={recipeValidator}
                        />
                        {paramForm.map((param) => (
                          <Field
                            id={param.id}
                            name={String(param.id)}
                            label={param.descrizione}
                            component={FormNumericTextBox}
                            min={1}
                            validator={numberValidator}
                          />
                        ))}
                      </MDBox>
                    </Scrollbar>
                  </fieldset>

                  <div className="k-form-buttons p-3">
                    <MDButton
                      variant="gradient"
                      color="info"
                      className="float-right"
                      type={"submit"}
                      disabled={!formRenderProps.allowSubmit || check}
                      fullWidth
                    >
                      Avvia
                    </MDButton>
                  </div>
                </FormElement>
              )}
            />
          ) : (
            <>
              <div className={"k-messagebox k-messagebox-warning m-3"}>
                {
                  "Attenzione non ci sono attuattori con cui è possibile avviare una fertirrigazione"
                }
              </div>
            </>
          )}
        </Card>
      </Grid>
      <Grid item md={4} xs={12}>
        <RecipesTable
          data={recipes}
          create={() => console.log("creazione")}
          update={() => console.log("creazione")}
          del={() => console.log("creazione")}
        />
      </Grid>
      {/* <Grid item xs={12}>
        <h5>Stato parametri</h5>
        {statusParam.length
          ? statusParam.map((param, index) => (
              <li key={index}>
                {param.parametro}: {param.stato}
              </li>
            ))
          : "Nessun parametro"}
        {msg}
      </Grid> */}
    </Grid>
  );
}

export default AttuazioniTemp;

// #Region Styled Component

// #End region

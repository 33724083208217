import React, { Component } from "react";
import DashboardColture from "./components/DashboardColture";
import TipologiaNodiTemplate from "uvagriotLayouts/TipologiaNodo/components/tipologiaTemplate";

export default class DashboardColtureComponent extends Component {
    render() {
        return (
            <TipologiaNodiTemplate
                title="Colture"
                component={<DashboardColture />}
            />

        );
    }
}

// #End region

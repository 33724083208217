import React, { useEffect, useState } from 'react';
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "../../components/MDTypography";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import uvagriotService from "../../services/uvagriot-service";
import DataTable from "react-data-table-component";
import MDButton from "../../components/MDButton";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import UvagriotService from "../../services/uvagriot-service";

export default function DSS_Attuazione_Single_Range() {

    let location = useLocation();
    const [listRange, setListRange] = useState([])
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    console.log(location.state);

    const getRange = async () => {
        let response = await uvagriotService.rangeAttuazioneLIST(location.state.attuazione)
        console.log("attuazione_range")
        console.log(response)
        setListRange(response);
    }

    const handleButtonClick = async (id) => {
        console.log(id)
        let response = await UvagriotService.rangeAttuazioneDELETE(id)
        console.log(response)
        getRange().then(r => setLoading(false))
    }

    useEffect(() => {
        getRange().then(r => setLoading(false))
    }, []);

    let columns = [
        {
            name: 'Valore Attuazione (s)',
            selector: row => row.valore_attuazione,
        },
        {
            name: 'Range Up',
            selector: row => row.range_up,
        },

        {
            name: 'Range Down',
            selector: row => row.range_down,
        },
        {
            name: '',
            button: true,
            cell: (row) =>
            (
                <NavLink to={`/dss/attuazione/single/range/single`} state={{ id: row.id, attuazione: location.state.attuazione, soglia: location.state.soglia, fase: location.state.fase }}>
                    <MDButton variant="gradient" color="info" m={5}>
                        Edit
                    </MDButton>
                </NavLink>
            ),
        },
        {
            name: '',
            button: true,
            cell: (row) => (
                <MDButton
                    onClick={() => { handleButtonClick(row.id) }}
                    variant="gradient" color="error" m={5}>
                    Delete
                </MDButton>
            ),
        },
    ];


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3} style={{ minWidth: 280 }}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Range Attuazione
                                </MDTypography>
                            </MDBox>
                            <MDBox pb={2} pt={2} px={2}>
                                <NavLink to={`/dss/attuazione/single/range/single`} state={{ attuazione: location.state.attuazione, soglia: location.state.soglia, fase: location.state.fase }}>
                                    <MDButton variant="gradient" color="info">
                                        Nuovo
                                    </MDButton>
                                    &nbsp;
                                    <NavLink to={`/dss/attuazione`} state={{ soglia: location.state.soglia, fase: location.state.fase }}>
                                        <MDButton variant="gradient" color="warning">
                                            Annulla
                                        </MDButton>
                                    </NavLink>
                                </NavLink>
                                <DataTable
                                    columns={columns}
                                    data={listRange}
                                    pagination
                                    subHeader
                                    // subHeaderComponent={subHeaderComponent}
                                    noDataComponent={<p className={'py-5'}><h4>Nessun Range</h4></p>}
                                />
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    );
}
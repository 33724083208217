import React, { Component } from "react";
import DashboardTipologia from "./components/DashboardTipologia";
import "./style/formStyle.css";
import TipologiaNodiTemplate from "./components/tipologiaTemplate";

export default class DashboardType extends Component {
    render() {
        return (
            <TipologiaNodiTemplate
                title="Tipologie Nodi"
                component={<DashboardTipologia />}
            />

        );
    }
}

// #End region

import styled from "styled-components";
import UvagriotService from "../../../services/uvagriot-service";
import React, {useState, useEffect, useMemo} from "react";
import {
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Brush,
    ResponsiveContainer,
    LineChart, Legend,
} from "recharts";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import {ThreeCircles} from "react-loader-spinner";
import {DateRangePicker} from "rsuite";
import "rsuite/dist/rsuite.css";

import {BiRefresh} from "react-icons/bi";

import DataTable from 'react-data-table-component';
import FilterComponent from "./FilterComponent";

const DashBoardGraf = ({id}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [historyData, setHistoryData] = useState(null);
    const [alarmData, setAlarmData] = useState(null)
    const [graphs, setGraphs] = useState([]);
    const [filterText, setFilterText] = useState("");
    const [filteredAlarm, setFilteredAlarm] = useState([])

    useEffect(() => {
        getMachineByIdTest();
    }, []);

    useEffect(() => {
        console.log(historyData);
        setGraphs(printOtherGraph());
    }, [historyData]);

    let getMachineByIdTest = async (range = null) => {
        let payload = JSON.stringify({
            'id': id,
            'range': range
        })

        let response = await UvagriotService.getDataMachine(payload);
        let data = response.data;
        setHistoryData(data);
        let list_alarm = []
        for (let k in data) {
            if (k !== "start" && k !== "end") {
                let row = data[k]
                for (let j in row) {
                    console.log(row[j].status)
                    if (row[j]?.status !== 'OK') {
                        list_alarm.push(row[j])
                    }
                }
            }
        }
        console.log(list_alarm)
        setAlarmData(list_alarm)
        setFilteredAlarm(list_alarm);
        setIsLoading(false);
    };

    const styles = {width: "auto", display: "block"};
    const getValueByDataRange = (range) => {
        if (range) {
            setIsLoading(true);
            var data_start =
                [
                    range[0].getMonth() + 1,
                    range[0].getDate(),
                    range[0].getFullYear(),
                ].join("/") +
                " " +
                [0, 0, 0].join(":");
            var data_end =
                [
                    range[1].getMonth() + 1,
                    range[1].getDate(),
                    range[1].getFullYear(),
                ].join("/") +
                " " +
                [23, 59, 59].join(":");
            range = [data_start, data_end]
            getMachineByIdTest(range);
        }
    }

    const handleSearchInputChange = (text) => {
        setFilterText(text);
        let filtered = text === "" ? alarmData : alarmData.filter(
            alarm => (alarm.status && alarm.status.toLowerCase().includes(text.toLowerCase())) ||
                (alarm.name && alarm.name.toLowerCase().includes(text.toLowerCase()))
        );
        setFilteredAlarm(filtered);
    }

    const subHeaderComponent = useMemo(() => {
        return (
            <FilterComponent
                onFilter={e => handleSearchInputChange(e.target.value)}
                filterText={filterText}
            />
        );
    }, [filterText]);

    const printOtherGraph = () => {
        let graphs_list = []
        if (historyData) {
            for (let k in historyData) {
                if (k !== 'start' && k !== 'end') {
                    let value_row = historyData[k]
                    let color = historyData[k][0]?.color
                    let description = historyData[k][0]?.descrizione
                    graphs_list.push({
                            "description": description,
                            "graphic":
                                <LineChart
                                    data={value_row}
                                    margin={{
                                        top: 5,
                                        right: 50,
                                        left: 20,
                                        bottom: 10,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3"/>
                                    <XAxis dataKey="timestamp"/>
                                    <YAxis/>
                                    <Tooltip/>
                                    <Legend layout="horizontal" verticalAlign="top" align="center"
                                            wrapperStyle={{fontSize: "16px"}}/>
                                    <Line type="monotone" dataKey='value'
                                          stroke={color}
                                          dot={false}
                                          name={k} strokeWidth={3}
                                          key={k}/>
                                    <Brush dataKey="timestamp" height={20} stroke="#8884d8"/>
                                    <Line type="monotone" dataKey="threeshold_l" stroke="#FF5858" dot={false}
                                          name="Threeshold L"
                                          strokeWidth={1}/>
                                    <Line type="monotone" dataKey="threeshold_h" stroke="#FF8C00" dot={false}
                                          name="Threeshold H"
                                          strokeWidth={1}/>
                                </LineChart>
                        }
                    );
                }
            }
        }
        let graphs = []
        let key = 0
        for (let i in graphs_list) {
            let key_name = "grid_number_" + key
            graphs.push(
                <>
                    <Spazer/>
                    <Grid item xs={12} key={key_name}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    <Row>
                                        <Col xl={11} md={10} xs={10}>
                                            {graphs_list[i]?.description}
                                        </Col>
                                    </Row>
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3}>
                                <Row className="divGrafOthersAll" fluid>
                                    <Col xl={12} className="divGrafOthers">
                                        <ResponsiveContainer>
                                            {graphs_list[i]?.graphic}
                                        </ResponsiveContainer>
                                    </Col>
                                </Row>
                            </MDBox>
                        </Card>
                    </Grid>
                </>
            );
            key++;
        }
        return (
            <>
                {graphs}
            </>
        );
    }

    const printAlarm = () => {
        if (alarmData.length > 0) {
            let columns = [
                {
                    name: 'Data',
                    selector: row => row.timestamp,
                },
                {
                    name: 'Nome',
                    selector: row => row.name,
                },
                {
                    name: 'Stato',
                    selector: row => row.status,
                },
            ];
            return (
                <DataTable
                    columns={columns}
                    data={filteredAlarm}
                    pagination
                    subHeader
                    subHeaderComponent={subHeaderComponent}
                    noDataComponent={<p className={'py-5'}><h4>Nessun Allarme</h4></p>}
                />
            );
        }
        return (<p className={'py-5'}><h4>Nessun Allarme</h4></p>);
    }

    return (
        <ContainerAll>
            {isLoading ? (
                <Container
                    style={{margin: "12px auto 12px auto"}}
                    fluid
                    class="d-flex justify-content-around"
                >
                    <Row>
                        <Col xl={12} md={12} xs={12}>
                            <div fluid className="d-flex justify-content-around">
                                <ThreeCircles
                                    height="100"
                                    width="100"
                                    color="#328BEC"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={isLoading}
                                    ariaLabel="three-circles-rotating"
                                    outerCircleColor=""
                                    innerCircleColor=""
                                    middleCircleColor=""
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            ) : (
                <MDBox pt={6} pb={3}>
                    <Grid container spacing={6}>
                        <Container className="allContainer" fluid>
                            <Spazer/>
                            <Grid item xs={12}>
                                <Card>
                                    <MDBox
                                        mx={2}
                                        mt={-3}
                                        py={3}
                                        px={2}
                                        variant="gradient"
                                        bgColor="info"
                                        borderRadius="lg"
                                        coloredShadow="info"
                                    >
                                        <MDTypography variant="h6" color="white">
                                            <Row>
                                                <Col xl={2} md={4} xs={4}>
                                                    <DateRangePicker
                                                        showOneCalendar
                                                        size="xs"
                                                        placeholder="Periodo di visualizzazione"
                                                        placement="auto"
                                                        style={styles}
                                                        onChange={getValueByDataRange}
                                                    />
                                                </Col>
                                                <Col xl={1} md={2} xs={2}>
                                                    <button
                                                        className="buttonMachineRefresh"
                                                        onClick={() => getMachineByIdTest()}
                                                        style={{
                                                            transition: "all 0.5s linear",
                                                            position: "absolute",
                                                            width: "32px",
                                                            height: "32px",
                                                            borderRadius: "50%",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            cursor: "pointer",
                                                            border: "none",
                                                            letterSpacing: "inherit",
                                                            fontSize: "inherit",
                                                            outline: "none",
                                                        }}
                                                    >
                                                        <BiRefresh/>
                                                    </button>
                                                </Col>
                                            </Row>
                                        </MDTypography>
                                    </MDBox>
                                </Card>
                            </Grid>
                            {printOtherGraph()}
                            <Spazer/>
                            <Grid item xs={12}>
                                <Card>
                                    <MDBox
                                        mx={2}
                                        mt={-3}
                                        py={3}
                                        px={2}
                                        variant="gradient"
                                        bgColor="info"
                                        borderRadius="lg"
                                        coloredShadow="info"
                                    >
                                        <MDTypography variant="h6" color="white">
                                            <Row>
                                                <Col xl={12} md={12} xs={12}>
                                                    Storico Allarmi
                                                </Col>
                                            </Row>
                                        </MDTypography>
                                    </MDBox>
                                    <MDBox pt={3}>
                                        <Row fluid={1}>
                                            <Col xl={12} className="text-center px-5">
                                                {printAlarm()}
                                            </Col>
                                        </Row>
                                    </MDBox>
                                </Card>
                            </Grid>
                        </Container>
                    </Grid>
                </MDBox>
            )}
        </ContainerAll>
    );
};

export default DashBoardGraf;

const ContainerAll = styled.div`
    .allContainer {
        margin-left: 38px;
        padding-right: 0;

        .divGrafMotorAll {
            height: 30vh;

            .scrollablelist {
                ::-webkit-scrollbar {
                    width: 10px;
                }

                ::-webkit-scrollbar-track {
                    background: #f1f1f1;
                }

                ::-webkit-scrollbar-thumb {
                    background: #888;
                }

                ::-webkit-scrollbar-thumb:hover {
                    background: #555;
                }
            }
        }

        .divGrafOthersAll {
            height: 20vh;
        }

        .recharts-legend-item {
            font-size: 1.25rem;
            margin-right: 25px !important;
        }

        .recharts-responsive-container {
            height: auto;
        }
    }
`;

const Spazer = styled.div`
    height: 5vh;
`;

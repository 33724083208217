import Dashboard from "./uvagriotLayouts/dashboard";
import Machines from "./uvagriotLayouts/Machines";
import GestioneNodi from "./uvagriotLayouts/gestionenodi";
import TypeMachine from "./uvagriotLayouts/TipologiaNodo";
import TipologiaNodiComponent from "./uvagriotLayouts/TipologiaNodo";
import DashboardParametriComponent from "uvagriotLayouts/parametri";
import Recipes from "uvagriotLayouts/Recipes";

import Billing from "./layouts/billing";
import Notifications from "./layouts/notifications";
import SignIn from "./layouts/authentication/sign-in";
import SignUp from "./layouts/authentication/sign-up";

import UserProfile from "./layouts/user-profile";
import UserManagement from "./layouts/user-management";

import Login from "./auth/login";
import Register from "./auth/register";
import ForgotPassword from "./auth/forgot-password";
import ResetPassword from "./auth/reset-password";

import {CreateTipologia} from "uvagriotLayouts/TipologiaNodo/components/dashboardinsertVar";
import {UpdateTipologia} from "uvagriotLayouts/TipologiaNodo/components/dashboardUpdateType";
import DashBoardMachine from "layouts/dashboardmachine";
import DashboardColtureComponent from "uvagriotLayouts/colture";
import DashboardModelliComponent from "uvagriotLayouts/modelli";
import InserisciNuovoCliente from "uvagriotLayouts/nuovoCliente";
import EditCliente from "uvagriotLayouts/editCliente";
import InserisciNuovoNodo from "uvagriotLayouts/nuovoNodo";
import EditMachine from "uvagriotLayouts/editMachine";
import DashboardStdRecipes from "uvagriotLayouts/TipologiaNodo/RicetteStandard/Ricette Standard";
import DashboardProfileComponents from "uvagriotLayouts/profiloUtente";
import DashboardRicetteComponent from "uvagriotLayouts/Recipes";
import {enumSection as section} from "uvagriotLayouts/utility/enumerators";
import DashboardNodiWrapper from "components/DashBoard";
import Appezzamenti from "uvagriotLayouts/Appezzamenti";
import Clienti from "uvagriotLayouts/Clienti";
import Droni from "uvagriotLayouts/Droni";
import Pianificazione from "uvagriotLayouts/PianificazioneVolo";
/* import GestioneAccounts from "uvagriotLayouts/gestioneAccounts"; */ //lASCIARE COMMENTATO

//import RequireAuth from "auth/RequireAuth";

// @mui icons
import Icon from "@mui/material/Icon";
import {
    AiOutlineHome,

} from "react-icons/ai";
import {BsRobot, BsTruckFrontFill} from "react-icons/bs";
import DSS_Dashboard from "./uvagriotLayouts/dss/dss_dashboard";
import DSS_Tipologia_Coltura from "./uvagriotLayouts/dss/tipologia_coltura";
import DSS_Tipologia_Coltura_Single from "./uvagriotLayouts/dss/tipologia_coltura_single";
import DSS_Fase_Fenologica from "./uvagriotLayouts/dss/fase_fenologica";
import DSS_Fase_Fenologica_Single from "./uvagriotLayouts/dss/fase_fenologica_single";
import DSS_Fase from "./uvagriotLayouts/dss/fase";
import DSS_Fase_Single from "./uvagriotLayouts/dss/fase_single";
import DSS_Fase_Single_Soglia from "./uvagriotLayouts/dss/fase_single_soglia";
import DSS_Fase_Single_Soglia_Single from "./uvagriotLayouts/dss/fase_single_soglia_single";
import DSS_Attuazione from "./uvagriotLayouts/dss/attuazione";
import DSS_Attuazione_Single from "./uvagriotLayouts/dss/attuazione_single";
import DSS_Attuazione_Single_Range from "./uvagriotLayouts/dss/attuazione_single_range";
import DSS_Attuazione_Single_Range_Single from "./uvagriotLayouts/dss/attuazione_single_range_single";
import DSS_Pianificazione from "./uvagriotLayouts/dss/pianificazione";
import DSS_Pianificazione_Single from "./uvagriotLayouts/dss/pianificazione_single";

const routes = [
    {
        type: "collapse",
        category: section.HOME,
        name: "Home",
        key: "dashboard",
        icon: <AiOutlineHome/>,
        route: "/dashboard",
        component: <Dashboard/>,
    },
    {
        type: "collapse",
        name: "clienti",
        category: section.CLIENTI,
        key: "clienti",
        icon: <Icon fontSize="small">table_view</Icon>,
        route: "/clienti",
        component: <Clienti/>
    },

    //GESTIONE NODI
    {
        type: "collapse",
        category: section.GESTIONE_NODI,
        name: "Gestione Appezzamenti",
        key: "gestione_appezzamenti",
        icon: <Icon fontSize="small">settings_suggest_icon</Icon>,
        route: "/gestione_appezzamenti",
        component: <GestioneNodi/>,
    },
    // NODI
    {
        //type: "collapse",
        category: section.NODI,
        name: "Nodi",
        key: "nodi",
        //icon: <Icon fontSize="small">table_view</Icon>,
        route: "/appezzamenti/:appezzamentoID/nodi",
        component: <Machines/>,
    },
    {
        type: "collapse",
        name: "Appezzamenti",
        category: section.NODI,
        key: "appezzamenti",
        icon: <Icon fontSize="small">table_view</Icon>,
        route: "/appezzamenti",
        component: <Appezzamenti/>
    },
    //* DRONI
    {
        type: "collapse",
        name: "Droni",
        category: section.DRONI,
        key: "droni",
        icon: <Icon fontSize="small">table_view</Icon>,
        route: "/droni",
        component: <Droni/>
    },
    // RICETTE
    /*   {
        type: "collapse",
        category: section.RICETTE,
        name: "Ricette",
        key: "ricette",
        icon: <Icon fontSize="small">notifications</Icon>,
        route: "/ricette",
        component: <DashboardRicetteComponent />,
      }, */
    //TIPOLOGIA CENTRALINA
    /*   {
        type: "collapse",
        category: section.TIPOLOGIA,
        name: "Parametri nodi",
        key: "parametri-nodo",
        icon: <BsTruckFrontFill />,
        route: "/tipologia-nodo",
        component: <TipologiaNodiComponent />,
      }, */
    {
        type: "collapse",
        category: section.PARAMETRI,
        name: "Parametri",
        key: "parametri",
        icon: <BsTruckFrontFill/>,
        route: "/parametri",
        component: <DashboardParametriComponent/>,
    },

    {
        name: "crea tipologia",
        category: section.TIPOLOGIA,
        key: "creaTipologia",
        route: "/tipologia-nodo/crea",
        component: <CreateTipologia/>
    },
    {
        name: "Modifica tipologia",
        category: section.TIPOLOGIA,
        key: "modificaTipologia",
        route: "/tipologia-nodo/modifica",
        component: <UpdateTipologia/>
    },
    {
        name: "Ricette standard",
        category: section.TIPOLOGIA,
        key: "ricetteStandard",
        route: "/tipologia-nodo/ricette-standard",
        component: <DashboardStdRecipes/>
    },
    {
        name: "Nodi",
        category: section.NODI,
        key: "nodi",
        route: "/nodi/:nodoID",
        component: <DashboardNodiWrapper/>
    },
    {
        name: "Pianificazioni Volo",
        category: section.NODI,
        key: "pinainifcazioniVolo",
        route: "/nodi/:nodoID/pianificazioni",
        component: <Pianificazione/>
    },
    {
        name: "crea appezzamento",
        category: section.GESTIONE_NODI,
        key: "creaApp",
        route: "/gestione_appezzamenti/creazione",
        component: <InserisciNuovoCliente/>
    },
    {
        name: "modifica appezzamento",
        category: section.GESTIONE_NODI,
        key: "modificaApp",
        route: "/gestione_appezzamenti/modifica",
        component: <InserisciNuovoCliente/>
    },
    {
        name: "crea nodo",
        category: section.GESTIONE_NODI,
        key: "creaNodo",
        route: "/gestione_nodi/inseriscinodo",
        component: <InserisciNuovoNodo/>
    },
    {
        name: "modifica nodo",
        category: section.GESTIONE_NODI,
        key: "modificaNodo",
        route: "/gestione_nodi/editmachine",
        component: <EditMachine/>
    },
    {
        name: "profile",
        category: section.PROFILO,
        key: "profile",
        route: "/profilo",
        component: <DashboardProfileComponents/>
    },
    {
        type: "collapse",
        name: "DSS",
        category: section.DSS,
        key: "dss",
        route: "/dss",
        icon: <BsRobot/>,
        component: <DSS_Dashboard/>
    },
    {
        name: "Tipologia Coltura",
        category: section.DSS,
        key: "tipologia_coltura",
        route: "/dss/tipologia_coltura",
        component: <DSS_Tipologia_Coltura/>
    },
    {
        name: "Tipologia Coltura Single",
        category: section.DSS,
        key: "tipologia_coltura_single",
        route: "/dss/tipologia_coltura/single",
        component: <DSS_Tipologia_Coltura_Single/>
    },
    {
        name: "Fase Fenologica",
        category: section.DSS,
        key: "fase_fenologica",
        route: "/dss/fase_fenologica",
        component: <DSS_Fase_Fenologica/>
    },
    {
        name: "Fase Fenologica Single",
        category: section.DSS,
        key: "fase_fenologica_single",
        route: "/dss/fase_fenologica/single",
        component: <DSS_Fase_Fenologica_Single/>
    },
    {
        name: "Fase",
        category: section.DSS,
        key: "fase",
        route: "/dss/fase",
        component: <DSS_Fase/>
    },
    {
        name: "Fase Single",
        category: section.DSS,
        key: "fase_single",
        route: "/dss/fase/single",
        component: <DSS_Fase_Single/>
    },
    {
        name: "Soglie Fase",
        category: section.DSS,
        key: "fase_single_soglia",
        route: "/dss/fase/single/soglia",
        component: <DSS_Fase_Single_Soglia/>
    },
    {
        name: "Soglia Fase Single",
        category: section.DSS,
        key: "fase_single_soglia_single",
        route: "/dss/fase/single/soglia/single",
        component: <DSS_Fase_Single_Soglia_Single/>
    },
    {
        name: "Attuazione",
        category: section.DSS,
        key: "attuazione",
        route: "/dss/attuazione",
        component: <DSS_Attuazione/>
    },
    {
        name: "Attuazione Single",
        category: section.DSS,
        key: "attuazione_single",
        route: "/dss/attuazione/single",
        component: <DSS_Attuazione_Single/>
    },
    {
        name: "Range Attuazione",
        category: section.DSS,
        key: "attuazione_single_range",
        route: "/dss/attuazione/single/range",
        component: <DSS_Attuazione_Single_Range/>
    },
    {
        name: "Range Attuazione Single",
        category: section.DSS,
        key: "attuazione_single_range_single",
        route: "/dss/attuazione/single/range/single",
        component: <DSS_Attuazione_Single_Range_Single/>
    },
    {
        name: "Pianificazione",
        category: section.NODI,
        key: "pianificazione",
        route: "/dss/pianificazione",
        component: <DSS_Pianificazione/>
    },
    {
        name: "Pianificazione Single",
        category: section.NODI,
        key: "pianificazione",
        route: "/dss/pianificazione/single",
        component: <DSS_Pianificazione_Single/>
    },
];

export default routes;

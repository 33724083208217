import "@patternfly/react-core/dist/styles/base.css";
import React from "react";
import {
  MultipleFileUpload,
  MultipleFileUploadMain,
  MultipleFileUploadStatus,
  MultipleFileUploadStatusItem,
  HelperText,
  HelperTextItem,
  DropEvent,
} from "@patternfly/react-core";
import UploadIcon from "@patternfly/react-icons/dist/esm/icons/upload-icon";
import { Button } from "@progress/kendo-react-buttons";
import uvagriotService from "services/uvagriot-service";

interface readFile {
  fileName: string;
  data?: string;
  loadResult?: "danger" | "success";
  loadError?: DOMException;
}
interface ModalHandler {
  toggleShow: () => void;
  voloID: number;
}

export const MultipleFileUploadBasic: React.FC<ModalHandler> = ({ toggleShow, voloID }) => {
  const [isHorizontal, setIsHorizontal] = React.useState(false);
  const [fileUploadShouldFail, setFileUploadShouldFail] = React.useState(false);
  const [currentFiles, setCurrentFiles] = React.useState<File[]>([]);
  const [readFileData, setReadFileData] = React.useState<readFile[]>([]);
  const [showStatus, setShowStatus] = React.useState(false);
  const [statusIcon, setStatusIcon] = React.useState("inProgress");

  if (!showStatus && currentFiles.length > 0) {
    setShowStatus(true);
  }

  React.useEffect(() => {
    if (readFileData.length < currentFiles.length) {
      setStatusIcon("inProgress");
    } else if (readFileData.every((file) => file.loadResult === "success")) {
      setStatusIcon("success");
    } else {
      setStatusIcon("danger");
    }
  }, [readFileData, currentFiles]);

  const submit = async () => {

    if (currentFiles.length === 0) return;

    const formData = new FormData();
    currentFiles.forEach((file) => {
      console.log('Appending file:', file.name); // Debug
      formData.append('files', file); // 'files' è il nome del campo per i file
    });

    let response = await uvagriotService.uploadsPOST(voloID, formData)

    setReadFileData([]);
    setCurrentFiles([]);
    toggleShow();
    setShowStatus(false);
      
  };

  const removeFiles = (namesOfFilesToRemove: string[]) => {
    const newCurrentFiles = currentFiles.filter(
      (currentFile) => !namesOfFilesToRemove.some((fileName) => fileName === currentFile.name)
    );

    setCurrentFiles(newCurrentFiles);

    const newReadFiles = readFileData.filter(
      (readFile) => !namesOfFilesToRemove.some((fileName) => fileName === readFile.fileName)
    );

    setReadFileData(newReadFiles);
  };

  const updateCurrentFiles = (files: File[]) => {
    if (fileUploadShouldFail) {
      const corruptedFiles = files.map((file) => ({
        ...file,
        lastModified: "foo" as unknown as number,
      }));
      setCurrentFiles((prevFiles) => [...prevFiles, ...(corruptedFiles as any)]);
    } else {
      setCurrentFiles((prevFiles) => [...prevFiles, ...files]);
    }
  };

  const handleFileDrop = (_event: DropEvent, droppedFiles: File[]) => {
    const currentFileNames = currentFiles.map((file) => file.name);
    const reUploads = droppedFiles.filter((droppedFile) =>
      currentFileNames.includes(droppedFile.name)
    );

    Promise.resolve()
      .then(() => removeFiles(reUploads.map((file) => file.name)))
      .then(() => updateCurrentFiles(droppedFiles));
  };

  const handleReadSuccess = (data: string, file: File) => {
    setReadFileData((prevReadFiles) => [
      ...prevReadFiles,
      { data, fileName: file.name, loadResult: "success" },
    ]);
  };

  const handleReadFail = (error: DOMException, file: File) => {
    setReadFileData((prevReadFiles) => [
      ...prevReadFiles,
      { loadError: error, fileName: file.name, loadResult: "danger" },
    ]);
  };

  const createHelperText = (file: File) => {
    const fileResult = readFileData.find(
      (readFile) => readFile.fileName === file.name
    );
    if (fileResult?.loadError) {
      return (
        <HelperText isLiveRegion>
          <HelperTextItem variant={"error"}>
            {fileResult.loadError.toString()}
          </HelperTextItem>
        </HelperText>
      );
    }
  };

  const successfullyReadFileCount = readFileData.filter(
    (fileData) => fileData.loadResult === "success"
  ).length;

  return (
    <>
      <MultipleFileUpload
        onFileDrop={handleFileDrop}
        dropzoneProps={{
          accept: {
            "image/jpeg": [".jpg", ".jpeg"],
            "application/msword": [".doc"],
            "application/pdf": [".pdf"],
            "image/png": [".png"],
            "image/tiff": [".tif", ".tiff"],
          },
        }}
        isHorizontal={isHorizontal}
      >
        <MultipleFileUploadMain
          titleIcon={<UploadIcon />}
          titleText="Drag and drop files here"
          titleTextSeparator="or"
          style={{
            maxHeight: "250px",
          }}
          infoText="Accepted file types: JPEG, Doc, PDF, PNG"
        />
        {showStatus && (
          <div style={{ maxHeight: "250px", overflowY: "auto" }}>
            <MultipleFileUploadStatus
              statusToggleText={`${successfullyReadFileCount} of ${currentFiles.length} files uploaded`}
              statusToggleIcon={statusIcon}
              aria-label="Current uploads"
            >
              {currentFiles.map((file) => (
                <MultipleFileUploadStatusItem
                  file={file}
                  key={file.name}
                  onClearClick={() => removeFiles([file.name])}
                  onReadSuccess={handleReadSuccess}
                  onReadFail={handleReadFail}
                  progressHelperText={createHelperText(file)}
                />
              ))}
            </MultipleFileUploadStatus>
          </div>
        )}
      </MultipleFileUpload>
      <div className="my-3">
        <Button 
          className="me-2"
          themeColor={"primary"}
          onClick={submit} 
        >
          Carica
        </Button>
        <Button
          onClick={toggleShow}
        >
          Chiudi
        </Button>
      </div>
    </>
  );
};

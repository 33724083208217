import React, {
  useCallback,
  useState,
  useEffect,
  Component,
  PureComponent,
  useMemo,
} from "react";
import { NavLink } from "react-router-dom";

import styled from "styled-components";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "bootstrap/dist/css/bootstrap.css";
import { Grid } from "@mui/material";
import AppezzamentiTable from "./AppezzamentiTable";
import MDBox from "components/MDBox";
import UvagriotService from "../../../services/uvagriot-service";
import data from "layouts/dashboard/components/Projects/data";

const DashBoardAppezzamenti = () => {
  const [rowData, setRowData] = useState([]);


  const getAppezzamenti = async () => {
    const dataMachines = await UvagriotService.appezzamentiGET();
    console.log("dataMachine ===============================");
    setRowData(dataMachines.data);
    console.log(dataMachines);
  }


  useEffect(() => {
    getAppezzamenti();

  }, []);


  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);


  return (
    <MDBox pb={2} pt={3} px={2}>
      <Grid container spacing={0}>
        <div style={containerStyle}>
          <div className="outer-div">
            <Grid item xs={12} md={12} xl={12} >
              <div id="mineID">
                <AppezzamentiTable
                  id={'tableAppezzamentiID'}
                  rows={{ rows: rowData, setRows: setRowData }}
                />
              </div>
            </Grid>
          </div>
        </div>
      </Grid>
    </MDBox>
  );
};

export default DashBoardAppezzamenti;

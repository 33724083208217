import { useState, useEffect } from "react";
//import '@progress/kendo-theme-default/dist/default-ocean-blue.scss';
import "@progress/kendo-theme-default/dist/all.css";

import React from "react";
//import Button from 'react-bootstrap/Button';

import MDBox from "components/MDBox";
import "../style/style.css";

//import "../style/style.css";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { enumTypeParam } from "uvagriotLayouts/utility/enumerators";

import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBCol,
} from "mdb-react-ui-kit";

import Grid from "@mui/material/Grid";

import { StatusCodes } from "http-status-codes";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import {
  FormInput,
  FormDropDownList,
  FormTextArea,
  FormSwitch,
} from "uvagriotLayouts/utility/form-components";
import {
  existParamValidator,
  existLabelValidator,
} from "uvagriotLayouts/utility/validators";
import uvagriotService from "../../../services/uvagriot-service";
import { Typography } from "@mui/material";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";


export default function ParamForm(props) {
  const { toggleShow, modalHook, modality, editValue, paramsHook } = props;

  const formRef = React.useRef(null);

  const tipologieParametri = Object.values(enumTypeParam);
  //const structureKey = Object.values(enumStructureKey);

  const initial = {
    nome: "",
    //label_frontend : "",
    descrizione: "",
    tipologia: tipologieParametri[0],
    unita_di_misura: "-",
  };

  const [initialState, setInitialState] = useState(initial);

  const closeModal = () => {
    closeForm(formRef.current.resetForm);
  };

  useEffect(() => {
    if (modality === "edit") {
      setInitialState({
        ...editValue,
        tipologia: enumTypeParam[editValue.tipologia],
      });
    } else {
      setInitialState(initial);
    }
  }, [editValue]);

  const closeForm = (reset) => {
    reset();
    toggleShow();
  };

  /* const notificationAction = (type) => {

        console.log(type)
        if (type === "create") {
            NotificationManager.success(
                "Ricetta creata con successo",
                "",
                4000
            );
        } else {
            NotificationManager.success(
                "Ricetta modificata con successo",
                "",
                4000
            );
        }
    } */

  const submit = async (dataItem) => {
    console.log("submit --------------------------");

    let tipologia = Object.keys(enumTypeParam).find(
      (key) => enumTypeParam[key] === dataItem.tipologia
    );

    dataItem.tipologia = parseInt(tipologia);

    if (modality === "create") {
      const response = await uvagriotService.parametriPOST(
        JSON.stringify({
          parametro: dataItem,
        })
      );

      if (response.status === 200) {
        let copy = [...paramsHook.params];
        console.log("RESPONSE DATA");
        console.log(response.data);

        copy.push(response.data);
        paramsHook.setParams(copy);
      } else {
        console.log("GIA ESISTENTE");
      }
    } else {
      const response = await uvagriotService.parametriPUT(
        JSON.stringify({
          parametro: dataItem,
        })
      );
      console.log("response data");
      console.log(response.data);

      if (response.status === 200) {
        let copy = [...paramsHook.params];

        copy = copy.map((param) =>
          param.id === response.data.id ? response.data : param
        );

        console.log("copy");
        console.log(copy);
        paramsHook.setParams(copy);
      } else {
        alert("qualcosa è andato storto"); //do something
      }
    }

    closeForm(formRef.current.resetForm);
  };

  useEffect(() => {
    formRef.current.resetForm();
  }, [initialState]);

  return (
    <>
      <MDBModal
        staticBackdrop
        tabIndex="-1"
        show={modalHook.staticModal}
        setShow={modalHook.setStaticModal}
        className="pt-5"
      >
        <MDBModalDialog size='fullscreen-sm-down'>
          <MDBModalContent>
            <MDBox
              mx={1}
              mt={-3}
              py={1}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDBModalHeader style={{ border: 0 }}>
                <MDBModalTitle style={{ color: "whitesmoke" }}>
                  {modality === "edit"
                    ? "Modifica Parametro"
                    : "Crea Parametro"}
                </MDBModalTitle>
                <MDBBtn
                  className="btn-close float right"
                  color="none"
                  aria-label="Close"
                  onClick={closeModal}
                />
              </MDBModalHeader>
            </MDBox>

            <MDBModalBody className="px-4 py-0">
              <MDBCol xl={12} lg={12} className="mb-4 pr-3">
                <Form
                  ref={formRef}
                  onSubmit={submit}
                  initialValues={initialState}
                  key={"form_recipe"}
                  render={(formRenderProps) => (
                    <FormElement>
                      <fieldset className={"k-form-fieldset"}>
                        <Grid container columnSpacing={2}>
                          <Grid item xs={12}>
                            {modality === "edit" ? (
                              <Field
                                id={"nameID"}
                                name={"nome"}
                                label={"Parametro"}
                                component={FormInput}
                                //onChange={changeStructureParam}
                                disabled={true}
                              />
                            ) : (
                              <Field
                                id={"nameID"}
                                name={"nome"}
                                label={"Parametro"}
                                component={FormInput}
                                //onChange={changeStructureParam}
                                validator={(choice) =>
                                  existParamValidator(
                                    choice,
                                    paramsHook.params.map((param) => param.nome)
                                  )
                                }
                              />
                            )}
                          </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                          {/* <Grid item xs={12} md={6}>
                              <Field
                                id={"labelID"}
                                name={"label_frontend"}
                                label={"Label"}
                                component={FormInput}
                                validator={(choice) =>
                                  existLabelValidator(
                                    choice,
                                    modality === "edit"
                                      ? editValue.label_frontend
                                      : "",
                                    paramsHook.params.map(
                                      (param) => param.label_frontend
                                    )
                                  )
                                }
                              />
                            </Grid> */}

                          <Grid item xs={12} md={4}>
                            <Field
                              id={"unitaID"}
                              name={"unita_di_misura"}
                              label={"Unità di misura"}
                              component={FormInput}
                              //validator={(choice) => existRecipeValidator(choice, recipesHook.recipes)}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Field
                              id={"tipologiaID"}
                              name={"tipologia"}
                              label={"Tipologia"}
                              component={FormDropDownList}
                              data={tipologieParametri}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Field
                              id={"descrizioneID"}
                              name={"descrizione"}
                              label={"Descrizione"}
                              component={FormTextArea}
                              //validator={(choice) => existRecipeValidator(choice, recipesHook.recipes)}
                            />
                          </Grid>
                        </Grid>
                      </fieldset>
                      <div className="k-form-buttons">
                        <Button
                          themeColor={"primary"}
                          type={"submit"}
                          disabled={!formRenderProps.allowSubmit}
                        >
                          {modality === "edit" ? "Aggiorna" : "Aggiungi"}
                        </Button>
                        <Button
                          onClick={() => closeForm(formRenderProps.onFormReset)}
                        >
                          Chiudi
                        </Button>
                      </div>
                    </FormElement>
                  )}
                />
              </MDBCol>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <NotificationContainer />
    </>
  );
}

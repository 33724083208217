import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useEffect } from "react";
import { Scrollbar } from "smooth-scrollbar-react";
import TablePagination from "@mui/material/TablePagination";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

import { styled } from "@mui/material/styles";

// Images
import MDButton from "components/MDButton";

import { BsPlusCircleFill } from "react-icons/bs";
import { BsCheck2Circle } from "react-icons/bs";
import { BsXCircle, BsPen } from "react-icons/bs";
import { BsX, BsPlusCircleDotted, BsPlus } from "react-icons/bs";
import { BsTrash3, BsFileText } from "react-icons/bs";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import Popover from "@mui/material/Popover";
import uvagriotService from "../../../services/uvagriot-service";
import { useState } from "react";

import { MDBCheckbox, MDBSelect } from "mdb-react-ui-kit";

import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "react-select";
import Chip from "@mui/material/Chip";
import { dataCsvIcon } from "@progress/kendo-svg-icons";

function Row(props) {
  const {
    row,

    defaultValue,
    parametri,
    updateAction,
    deleteAction,
    submit,
    cancel,
  } = props;

  const trueElem = <BsCheck2Circle color="green" size={25} />;
  const falseElem = <BsXCircle color="red" size={20} />;
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [check, setCheck] = useState(true);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [lastInsert, setLastInsert] = useState({});

  useEffect(() => {
    var lastInsert_ = {};
    if (row.modality === "edit") {
      lastInsert_ = {
        paramID: row.paramID,
        nome: row.nome,

        realtime: row.realtime,

        modality: "view",
      };
    } else {
      lastInsert_ = {
        paramID: defaultValue.value,
        nome: defaultValue.label,

        realtime: false,

        modality: "view",
      };
    }

    setLastInsert(lastInsert_);
  }, [row]);

  const changeHandlerParam = (choice) =>
    setLastInsert({ ...lastInsert, paramID: choice.value, nome: choice.label });

  const changeHandler = (e) => {
    setLastInsert({ ...lastInsert, [e.target.name]: e.target.checked });

    let realtime =
      e.target.name === "realtime" ? e.target.checked : lastInsert.realtime;

    setCheck(realtime);
  };

  const submitRow = (modality, data) => {
    submit(modality, data);
  };

  return (
    <React.Fragment>
      {row.modality === "edit" || row.modality === "create" ? (
        <>
          <TableRow>
            <TableCell align="center">
              <Select
                name="parametro"
                id={"selectID"}
                onChange={(choice) => changeHandlerParam(choice)}
                classNamePrefix="select"
                isSearchable={true}
                options={parametri}
                defaultValue={defaultValue}
                isDisabled={row.modality === "edit" ? true : false}
              />
            </TableCell>

            <TableCell align="center">
              <MDBCheckbox
                name="realtime"
                id={"realtime" + row.paramID}
                onChange={changeHandler}
                defaultChecked={row.realtime}
              />
            </TableCell>

            <TableCell align="center">
              <IconButton
                color="info"
                aria-label="add an alarm"
                onClick={() => submitRow(row.modality, lastInsert)}
              >
                <BsPlus size={30} className="p-0" />
              </IconButton>
              <IconButton
                color="error"
                className="p-0"
                aria-label="add an alarm"
                onClick={() =>
                  cancel(row.modality, row.paramID)
                } /* disabled={disable} */
              >
                <BsX size={30} />
              </IconButton>
            </TableCell>
          </TableRow>
        </>
      ) : (
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell component="th" scope="row" align="center">
            {row.nome}
          </TableCell>

          <TableCell align="center">
            {row.realtime ? trueElem : falseElem}
          </TableCell>

          <TableCell align="center">
            <ParamEditButton
              color="info"
              onClick={() => updateAction(row.paramID)}
            >
              <BsPen size={20} />
            </ParamEditButton>

            <ParamButton
              color="error"
              onClick={() => deleteAction(row.paramID)}
            >
              <BsTrash3 size={20} />
            </ParamButton>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

export default function ParamTable(props) {
  const { rows, setRows } = props;

  /* ROW:
        controllo: false
        in_ricetta: false
        modality: "create"
        nome: ""
        paramID: "createID"
        realtime: false
    */

  const [params, setParams] = useState([]); //parametri nel DB
  const [disable, setDisable] = useState(false); //disabilita il tasto aggiungi
  const [filterParams, setFilterParams] = useState([]);
  const [default_, setDefault_] = useState({});

  // TODO: I PARAMETRI NON SONO FILTRATI INIZIALMENTE PER QUELLI GIA PRESENTI PER L'EDIT
  useEffect(() => {
    const get_params_choices = async () => {
      const data = await uvagriotService.parametriGET();
      var t = [];

      data.data.map((p, index) => {
        t.push({ id: index, value: p.id, label: p.nome });
      });
      setParams(t);
      setFilterParams(t);
      setDefault_(t[0]);
    };

    get_params_choices();
  }, []);

  const addFormRow = () => {
    let row = [...rows];
    row.unshift({
      paramID: "createID",
      nome: "",

      realtime: false,
      modality: "create",
    });

    setRows(row);
    setDefault_(filterParams[0]);
    setDisable(true);
  };

  const editFormRow = (paramID) => {
    let row = [...rows];
    row = row.map((r) =>
      r.paramID === paramID ? { ...r, modality: "edit" } : r
    );
    let a = params.find((elem) => elem.value === paramID);
    /* let filter = [...filterParams]
        filter.push(a);
        setFilterParams(filter); */
    setDefault_(a);

    setRows(row);
    setDisable(true);
  };

  const submit = (modality, data) => {
    switch (modality) {
      case "create":
        let row = rows.filter((r) => r.modality !== "create");
        row.unshift(data);
        setFilterParams(
          filterParams.filter((param) => param.value !== data.paramID)
        );
        setRows(row);
        setDisable(false);
        break;
      default:
        let rowEdit = rows.map((r) => (r.paramID === data.paramID ? data : r));
        setRows(rowEdit);
        setDisable(false);
        break;
    }
  };

  const remove = (paramID) => {
    console.log(paramID);
    let a = params.find((elem) => elem.value === paramID);
    let filter = [...filterParams];
    filter.push(a);
    filter.sort((a, b) => a.id - b.id);

    setFilterParams(filter);

    setRows(rows.filter((elem) => elem.paramID !== paramID));
  };

  const cancel = (modality, paramID) => {
    switch (modality) {
      case "create":
        setRows(rows.filter((r) => r.modality !== "create"));
        break;
      default:
        let rowEdit = rows.map((r) =>
          r.paramID === paramID ? { ...r, modality: "view" } : r
        );
        setRows(rowEdit);
        break;
    }
    setDisable(false);
  };

  return (
    <TableContainer component={Paper} style={{ minHeight: "500px" }}>
      <Table>
        <TableHead sx={{ display: "table-header-group" }}>
          <TableRow>
            <TableCell align="center">Parametro</TableCell>
            {/* <TableCell style={{maxWidth: '40px'}}>Descrizione</TableCell> */}

            <TableCell align="center">Realtime</TableCell>

            <TableCell component="td" align="center">
              <Button
                variant="text"
                color="primary"
                style={{ padding: 0 }}
                onClick={addFormRow}
                id={"addTypeID"}
                disabled={disable}
              >
                <BsPlus size={30} align={"right"} />
                AGGIUNGI
              </Button>
            </TableCell>
          </TableRow>
          <TableRow>
            {/* <TableCell colSpan={5} style={{hidden:true}}></TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row
              key={row.paramID}
              row={row}
              updateAction={editFormRow}
              deleteAction={remove}
              submit={submit}
              cancel={cancel}
              parametri={filterParams}
              defaultValue={default_}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const ParamButton = styled(IconButton)({
  "&:hover": {
    color: "red",
    boxShadow: "none",
  },
});
const ParamEditButton = styled(IconButton)({
  "&:hover": {
    color: "blue",
    boxShadow: "none",
  },
});

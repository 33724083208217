import React, {useEffect, useState} from 'react';
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "../../components/MDTypography";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import uvagriotService from "../../services/uvagriot-service";
import DataTable from "react-data-table-component";
import MDButton from "../../components/MDButton";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import UvagriotService from "../../services/uvagriot-service";

export default function DSS_Fase_Single_Soglia() {

    let location = useLocation();
    const [listSoglie, setListSoglie] = useState([])
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    console.log(location.state);

    const getSoglie = async () => {
        let response = await uvagriotService.soglieFaseLIST(location.state.fase)
        console.log("fase_soglia")
        console.log(response)
        setListSoglie(response);
    }

    const handleButtonClick = async (id) => {
        console.log(id)
        let response = await UvagriotService.faseSogliaDELETE(id)
        console.log(response)
        getSoglie().then(r => setLoading(false))
    }

    useEffect(() => {
        getSoglie().then(r => setLoading(false))
    }, []);

    let columns = [
        {
            name: 'Parametro',
            selector: row => row.parametro.nome,
        },
        {
            name: 'Valore',
            selector: row => row.valore,
        },
        {
            name: '',
            button: true,
            width: "10rem",
            cell: (row) =>
                (
                    <NavLink to={`/dss/attuazione`} state={{soglia: row.id, fase: location.state.fase}}>
                        <MDButton variant="gradient" color="info" m={5}>
                            Attuazione
                        </MDButton>
                    </NavLink>
                ),
        },
        {
            name: '',
            button: true,
            cell: (row) =>
                (
                    <NavLink to={`/dss/fase/single/soglia/single`} state={{id: row.id, fase: location.state.fase}}>
                        <MDButton variant="gradient" color="info" m={5}>
                            Edit
                        </MDButton>
                    </NavLink>
                ),
        },
        {
            name: '',
            button: true,
            cell: (row) => (
                <MDButton
                    onClick={() => {
                        handleButtonClick(row.id)
                    }}
                    variant="gradient" color="error" m={5}>
                    Delete
                </MDButton>
            ),
        },
    ];


    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox pt={6} pb={3} style={{minWidth: 280}}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Soglie Fase
                                </MDTypography>
                            </MDBox>
                            <MDBox pb={2} pt={2} px={2}>
                                <NavLink to={`/dss/fase/single/soglia/single`} state={{fase: location.state.fase}}>
                                    <MDButton variant="gradient" color="info">
                                        Nuovo
                                    </MDButton>
                                </NavLink>
                                &nbsp;
                                <NavLink to={`/dss/fase`}>
                                    <MDButton variant="gradient" color="warning">
                                        Annulla
                                    </MDButton>
                                </NavLink>
                                <DataTable
                                    columns={columns}
                                    data={listSoglie}
                                    pagination
                                    subHeader
                                    // subHeaderComponent={subHeaderComponent}
                                    noDataComponent={<p className={'py-5'}><h4>Nessuna Soglia</h4></p>}
                                />
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    );
}
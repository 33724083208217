import { createContext, useContext, useReducer, useMemo, useState, useEffect } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import AuthService from "services/auth-service";
import { useSearchParams } from "react-router-dom"
import { enumSection as section } from "uvagriotLayouts/utility/enumerators";

// Material Dashboard 2 React main context
const MaterialUI = createContext();

// authentication context
export const AuthContext = createContext({
    login: () => {
    },
    logout: () => {
    },
    permissionRoutes: () => {
    },
});

const AuthContextProvider = ({ children }) => {

    const navigate = useNavigate();
    const [queryParameters] = useSearchParams();
    const [permission, setPermission] = useState({});
    const [isLogged, setIsLogged] = useState(false);


    const login = async (access, refresh) => {

        console.log("access: ", access)
        console.log("refresh: ", refresh)
        if (access && refresh) {
            localStorage.setItem("access_token", access ? access : "");
            localStorage.setItem("refresh_token", refresh ? refresh : "")
            let token_decode = jwtDecode(access);
            var user = await AuthService.getUser(token_decode.user_id);
            localStorage.setItem('user', JSON.stringify(user));
            setIsLogged(true);

        }

        const url = queryParameters.get("redirectTo") ? queryParameters.get("redirectTo") : "/dashboard";
        navigate(url);
    };


    const logout = () => {
        console.log("LOGOUT")
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        setIsLogged(false);

        navigate("/auth/login");
    };

    const permissionRoutes = (category) => {

        //const permission_ = JSON.parse(localStorage.getItem('permission'));
        const user_is_superuser = JSON.parse(localStorage.getItem('user'))?.is_superuser;
        //console.log("is SUPERUSER:")
        //console.log(user_is_superuser)
        switch (category) {

            case section.GESTIONE_NODI:
                return user_is_superuser;

            case section.PARAMETRI:
                return user_is_superuser;

            case section.CLIENTI:
                return user_is_superuser;

            case section.DRONI:
                return user_is_superuser;

            case section.DSS:
                return user_is_superuser;

            default:
                return true;
        }

    }

    return (
        <AuthContext.Provider value={{ login, logout, permissionRoutes }}>
            {children}
        </AuthContext.Provider>
    );
};

// Setting custom name for the context which is visible on react dev tools
MaterialUI.displayName = "MaterialUIContext";

// Material Dashboard 2 React reducer
function reducer(state, action) {
    switch (action.type) {
        case "MINI_SIDENAV": {
            return { ...state, miniSidenav: action.value };
        }
        case "TRANSPARENT_SIDENAV": {
            return { ...state, transparentSidenav: action.value };
        }
        case "WHITE_SIDENAV": {
            return { ...state, whiteSidenav: action.value };
        }
        case "SIDENAV_COLOR": {
            return { ...state, sidenavColor: action.value };
        }
        case "TRANSPARENT_NAVBAR": {
            return { ...state, transparentNavbar: action.value };
        }
        case "FIXED_NAVBAR": {
            return { ...state, fixedNavbar: action.value };
        }
        case "OPEN_CONFIGURATOR": {
            return { ...state, openConfigurator: action.value };
        }
        case "DIRECTION": {
            return { ...state, direction: action.value };
        }
        case "LAYOUT": {
            return { ...state, layout: action.value };
        }
        case "DARKMODE": {
            return { ...state, darkMode: action.value };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

// Material Dashboard 2 React context provider
function MaterialUIControllerProvider({ children }) {
    const initialState = {
        miniSidenav: false,
        transparentSidenav: false,
        whiteSidenav: true,
        sidenavColor: "success",
        transparentNavbar: true,
        fixedNavbar: true,
        openConfigurator: false,
        direction: "ltr",
        layout: "dashboard",
        darkMode: false,
    };

    const [controller, dispatch] = useReducer(reducer, initialState);

    const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

    return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>;
}

// Material Dashboard 2 React custom hook for using context
function useMaterialUIController() {
    const context = useContext(MaterialUI);

    if (!context) {
        throw new Error(
            "useMaterialUIController should be used inside the MaterialUIControllerProvider."
        );
    }

    return context;
}

// Typechecking props for the MaterialUIControllerProvider
MaterialUIControllerProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setWhiteSidenav = (dispatch, value) => dispatch({ type: "WHITE_SIDENAV", value });
const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDirection = (dispatch, value) => dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setDarkMode = (dispatch, value) => dispatch({ type: "DARKMODE", value });

export {
    AuthContextProvider,
    MaterialUIControllerProvider,
    useMaterialUIController,
    setMiniSidenav,
    setTransparentSidenav,
    setWhiteSidenav,
    setSidenavColor,
    setTransparentNavbar,
    setFixedNavbar,
    setOpenConfigurator,
    setDirection,
    setLayout,
    setDarkMode,
};

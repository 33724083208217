import React from "react";
import styled from "styled-components";

const Input = styled.input.attrs(props => ({
  type: "text",
  size: props.small ? 5 : undefined
}))`
  height: 32px;
  width: 200px;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <Input
      id="search"
      type="text"
      placeholder="Filtra allarmi"
      value={filterText}
      onChange={onFilter}
    />
);

export default FilterComponent;

import React, {
  useCallback,
  useState,
  useEffect,
  Component,
  PureComponent,
  useMemo,
} from "react";
import { NavLink } from "react-router-dom";

import styled from "styled-components";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "bootstrap/dist/css/bootstrap.css";
import { Grid } from "@mui/material";
import ClientiTable from "./ClientiTable";
import MDBox from "components/MDBox";
import UvagriotService from "../../../services/uvagriot-service";
import data from "layouts/dashboard/components/Projects/data";

const DashBoardClienti = () => {
  const [rowData, setRowData] = useState([]);

  const getClienti = async () => {
    const dataMachines = await UvagriotService.usersGET();
    console.log("dataMachine ===============================");
    setRowData(dataMachines.data.filter((client) => !client.is_superuser));
    console.log(dataMachines);
  };

  useEffect(() => {
    getClienti();
  }, []);

  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);

  return (
    <MDBox pb={2} pt={3} px={2}>
      <Grid container spacing={0}>
        <div style={containerStyle}>
          <div className="outer-div">
            <Grid item xs={12} md={12} xl={12}>
              <div id="mineID">
                <ClientiTable
                  id={"tableClientiID"}
                  rows={{ rows: rowData, setRows: setRowData }}
                />
              </div>
            </Grid>
          </div>
        </div>
      </Grid>
    </MDBox>
  );
};

export default DashBoardClienti;

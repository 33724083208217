import {useLocation, useNavigate} from "react-router-dom";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import {NotificationContainer, NotificationManager} from "react-notifications";
import React, {useEffect, useState} from "react";
import UvagriotService from "../../services/uvagriot-service";
import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {Button} from "@progress/kendo-react-buttons";
import Form from "@rjsf/core";
import {customizeValidator} from "@rjsf/validator-ajv8";
import localizer from "ajv-i18n";
import jwtDecode from "jwt-decode";


function EditMachine() {
    const {state} = useLocation();
    console.log(state);

    const [listTipologie, setListTipologie] = useState([])
    const [formData, setFormData] = useState(null)

    useEffect(() => {
        getTipologie();
        getMachine();
    }, []);

    const getMachine = async () => {
        const requestOptions = JSON.stringify({id_nodo: state})
        let response = await UvagriotService.getnodo(requestOptions);
        // setTipologiaList(response?.tipologia?.id)
        setFormData(response?.nodo)
        console.log('--------------------------------------')
        console.log(response);
    }

    const getTipologie = async () => {
        let response = await UvagriotService.tipologiaColturaLIST()
        let tmp_list = []
        for (let k in response) {
            if (response[k]?.is_active) {
                tmp_list.push({const: response[k]?.id, title: response[k]?.nome})
            }
        }
        setListTipologie(tmp_list)
    }

    const schema: RJSFSchema = {
        title: 'Modifica Nodo',
        type: 'object',
        required: ['nome', 'nome_univoco'],
        properties: {
            nome: {type: 'string', title: 'Nome'},
            nome_univoco: {type: 'string', title: 'Nome Univoco', readOnly: true},
            tipologia_coltura: {
                type: 'number',
                title: 'Tipologia Coltura',
                oneOf: listTipologie
            },
            descrizione: {type: 'string', title: 'Descrizione'},
        },
    };

    const validator = customizeValidator({}, localizer.it);

    const uiSchema: UiSchema = {
        "descrizione": {
            "ui:widget": "textarea"
        },
        "ui:submitButtonOptions": {
            "props": {
                "disabled": false,
                "className": "btn btn-info"
            },
            "submitText": "Applica"
        }
    }

    const onSubmit = async () => {
        let singleDataUserAg = formData;
        var refresh_token = localStorage.getItem("access_token");
        let refresh_tokenParts = jwtDecode(refresh_token);
        var user_id = refresh_tokenParts.user_id;
        singleDataUserAg["user_id"] = user_id;
        singleDataUserAg["nodo_id"] = state;
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({userData: singleDataUserAg}),
        };
        const response = await UvagriotService.editMachine(requestOptions);
        console.log(response);
        createNotification(response['response']);
    }

    const navigate = useNavigate();

    const createNotification = async (type) => {
        if (type === "success") {
            console.log(1);
            NotificationManager.success(
                "Nodo Modificata",
                "Modifica Effettuata",
                5000
            )
            navigate("/gestione_nodi");
        } else if (type === "error") {
            /* NotificationManager.error("Error message", "Click me!", 5000, () => {
              alert("callback");
            }); */
            NotificationManager.error(
                "Problema durante la modifica",
                "Errore durante inserimento",
                5000
            );
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox py={1}>
                <MDBox>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Form
                                schema={schema}
                                uiSchema={uiSchema}
                                formData={formData}
                                onChange={(e) => setFormData(e.formData)}
                                onSubmit={onSubmit}
                                validator={validator}>
                                <div>
                                    <Button
                                        themeColor={"primary"}
                                        type={"submit"}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </Form>
                        </Grid>
                    </Grid>
                </MDBox>
            </MDBox>
            <NotificationContainer/>
        </DashboardLayout>
    );
}

export default EditMachine;
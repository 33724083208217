import React, { Component } from "react";
import DashboardModelli from "./components/DashboardModelli";
import TipologiaNodiTemplate from "uvagriotLayouts/TipologiaNodo/components/tipologiaTemplate";

export default class DashboardModelliComponent extends Component {
    render() {
        return (
            <TipologiaNodiTemplate
                title="Tipologia modelli"
                component={<DashboardModelli />}
            />

        );
    }
}

// #End region

import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import Button from "@mui/material/Button";
import { BsX, BsPlusCircleDotted, BsPlus } from "react-icons/bs";
import ImageModal from "./ImageModal";
import UploadImage from "./uploadModals";
import Chip from '@mui/material/Chip';


function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};


function Row(props) {
  const { row, toggleShow,toggleShowUp } = props;


  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell component="th" scope="row" align="center">
          {row.drone}
        </TableCell>
        <TableCell align="center">{row.tipologia_coltura.map(coltura => <Chip variant="outlined" color="success"  label={coltura} className="mx-1"/>)
          }</TableCell>
        <TableCell align="center">{row.timestamp}</TableCell>
        <TableCell align="center">
        <Button
                  variant="text"
                  color="primary"
                  style={{ paddingRight: 17 }}
                  onClick={()=>toggleShow(row.id)}
                  id={"indiciID"}
                  disabled={!row.images_exists}
                >
                  indici
                </Button>
        {!row.automatico && 
          <Button
            variant="text"
            color="info"
            style={{ paddingRight: 17 }}
            onClick={()=>toggleShowUp(row.id)}
            id={"upButtonID"}
            disabled={row.images_exists}
          >   
            upload
          </Button>
        }
        


        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function PianificazioniTable(props) {
  const { rows } = props;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [current, setCurrent]= useState(null);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [staticModal, setStaticModal] = useState(false);
  const toggleShow = (id) => {
    setStaticModal(!staticModal);
    setCurrent(id);

  }

  const [staticModalUp, setStaticModalUp] = useState(false);
  const toggleShowUp = (id) => {
    setStaticModalUp(!staticModalUp);
    setCurrent(id);

  }


  return (
    <>
    
      <TableContainer component={Paper} style={{ minHeight: "500px" }}>
        <Table aria-label="collapsible table">
          <TableHead sx={{ display: "table-header-group" }}>
            <TableRow>
              <TableCell align="center">Drone</TableCell>
              <TableCell align="center">Coltura</TableCell>
              <TableCell align="center">Data</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row key={index} row={row} toggleShow={toggleShow} toggleShowUp={toggleShowUp}/>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          colSpan={3}
          count={rows.rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage="righe per pagina"
          SelectProps={{
            select: {
              marginbottom: "10px",
            },
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </TableContainer>
      <ImageModal
        id={"createFormID"}   
        toggleShow={toggleShow}
        modalHook={{ staticModal: staticModal, setStaticModal: setStaticModal }}
        pianificazioneID={current}
      />
      <UploadImage
        id={"uploadID"}   
        toggleShow={toggleShowUp}
        modalHook={{ staticModal: staticModalUp, setStaticModal: setStaticModalUp }}
        pianificazioneID={current}
      />

    </>
  );
}

import React, {useEffect, useState} from 'react';
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "../../components/MDTypography";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import uvagriotService from "../../services/uvagriot-service";
import DataTable from "react-data-table-component";
import MDButton from "../../components/MDButton";
import {NavLink, useLocation} from "react-router-dom";
import UvagriotService from "../../services/uvagriot-service";

export default function DSS_Attuazione() {


    let location = useLocation();
    const [listAttuazioni, setListAttuazioni] = useState([])
    const [loading, setLoading] = useState(true)
    console.log(location.state)

    const getAttuazioni = async () => {
        const response = await uvagriotService.attuazioneLIST(location.state.soglia)
        console.log("attuazione")
        console.log(response)
        setListAttuazioni(response);
    }

    useEffect(() => {
        getAttuazioni().then(r => setLoading(false))
    }, []);

    const handleButtonClick = async (id) => {
        console.log(id)
        let response = await UvagriotService.attuazioneDELETE(id)
        console.log(response)
        getAttuazioni().then(r => setLoading(false))
    }

    let columns = [
        {
            name: 'Nome',
            selector: row => row.nome,
        },
        {
            name: 'Acronimo',
            selector: row => row.acronimo,
        },
        {
            name: 'Soglia',
            selector: row => row.soglia.parametro.descrizione + " - Valore: " + row.soglia.valore,
        },
        {
            name: 'Attuatore',
            selector: row => row.parametro.descrizione,
        },
        {
            name: '',
            button: true,
            cell: (row) =>
                (
                    <NavLink to={`/dss/attuazione/single/range`}
                             state={{attuazione: row.id, soglia: location.state.soglia, fase: location.state.fase}}>
                        <MDButton variant="gradient" color="primary" m={5}>
                            Range
                        </MDButton>
                    </NavLink>
                ),
        },
        {
            name: '',
            button: true,
            cell: (row) =>
                (
                    <NavLink to={`/dss/attuazione/single`} state={{id: row.id, fase: location.state.fase, soglia: location.state.soglia}}>
                        <MDButton variant="gradient" color="info" m={5}>
                            Edit
                        </MDButton>
                    </NavLink>
                ),
        },
        {
            name: '',
            button: true,
            cell: (row) => (
                <MDButton
                    onClick={() => {
                        handleButtonClick(row.id)
                    }}
                    variant="gradient" color="error" m={5}>
                    Delete
                </MDButton>
            ),
        },
    ];


    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox pt={6} pb={3} style={{minWidth: 280}}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Attuazione
                                </MDTypography>
                            </MDBox>
                            <MDBox pb={2} pt={2} px={2}>
                                {listAttuazioni.length === 0 ? (
                                        <>
                                            <NavLink to={`/dss/attuazione/single`} state={{soglia: location.state.soglia, fase: location.state.fase}}>
                                                <MDButton variant="gradient" color="info">
                                                    Nuovo
                                                </MDButton>
                                            </NavLink>
                                            &nbsp;
                                        </>
                                    ) :
                                    (<></>)}
                                <NavLink to={`/dss/fase/single/soglia`} state={{fase: location.state.fase}}>
                                    <MDButton variant="gradient" color="warning">
                                        Annulla
                                    </MDButton>
                                </NavLink>
                                <DataTable
                                    columns={columns}
                                    data={listAttuazioni}
                                    pagination
                                    subHeader
                                    // subHeaderComponent={subHeaderComponent}
                                    noDataComponent={<p className={'py-5'}><h4>Nessuna Attuazione</h4></p>}
                                />
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    );
}
import { useState, useEffect, useMemo, useContext } from "react";

// react-router components
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";
import MDButton from "components/MDButton";

// RTL plugins
// import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import uvagriotLogo from "assets/images/uvagriot_logo.png";

import { setupAxiosInterceptors } from "./services/interceptor";
import ProtectedRoute from "examples/ProtectedRoute";
import ForgotPassword from "auth/forgot-password";
import ResetPassword from "auth/reset-password";
import Login from "auth/login";
import Register from "auth/register";
import { AuthContext } from "context";
import UserProfile from "layouts/user-profile";
import UserManagement from "layouts/user-management";
import MetaTags from "react-meta-tags";
import DashBoardMachine from "./layouts/dashboardmachine/index";
import InserisciNuovoAppezzamento from "./uvagriotLayouts/nuovoCliente/index";
import InserisciNuovoNodo from "./uvagriotLayouts/nuovoNodo/index";
import { CreateTipologia } from "uvagriotLayouts/TipologiaNodo/components/dashboardinsertVar";
import { UpdateTipologia } from "uvagriotLayouts/TipologiaNodo/components/dashboardUpdateType";
import EditCliente from "./uvagriotLayouts/editCliente";
import EditMachine from "./uvagriotLayouts/editMachine";
// import { UpdateTipologia } from "uvagriotLayouts/TipologiaNodo/components/dashboardUpdateType";
import DashboardStdRecipes from "uvagriotLayouts/TipologiaNodo/RicetteStandard/Ricette Standard";
import DashboardProfileComponents from "uvagriotLayouts/profiloUtente";

export default function App() {
  const authContext = useContext(AuthContext);

  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;

  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const [permissionRoutes, setPermissionRoutes] = useState([]);

  const [isDemo, setIsDemo] = useState(false);

  useEffect(() => {
    setIsDemo(process.env.REACT_APP_IS_DEMO === "true");

  }, []);


  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  //Probabilmente facendo dipendere tutti i route da app questo permette di verificare ogni volta il login
  // ------------------------------------------------------------------------------
  // if the token expired or other errors it logs out and goes to the login page
  const navigate = useNavigate();


  /* setupAxiosInterceptors(() => {
    authContext.isAuth();
  }); */
  // ------------------------------------------------------------------------------

  // Setting the dir attribute for the body element
  /*   useEffect(() => {
      document.body.setAttribute("dir", direction);
    }, [direction]); */

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);




  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {

      if (route.route && authContext.permissionRoutes(route.category)) {
        return (
          <Route
            exact
            path={route.route}
            element={
              <ProtectedRoute to={route.route}>
                {route.component}
              </ProtectedRoute>
            }
            key={route.key}
          />
        );
      }
      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return (
    <>
      <ThemeProvider theme={darkMode ? themeDark : theme} >
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={
                (transparentSidenav && !darkMode) || whiteSidenav
                  ? uvagriotLogo
                  : uvagriotLogo
              }
              brandName=""
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />

          </>
        )}
        <Routes>
          <Route path="/auth/login" element={<Login />} />
          <Route path="/auth/forgot-password" element={<ForgotPassword />} />
          <Route path="/auth/reset-password" element={<ResetPassword />} />

          {getRoutes(routes)}


          <Route path="*" element={<Navigate to="/dashboard" />} />

        </Routes>
      </ThemeProvider >
    </>
  );
}

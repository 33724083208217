import { useState, useEffect } from "react";
//import '@progress/kendo-theme-default/dist/default-ocean-blue.scss';
import "@progress/kendo-theme-default/dist/all.css";

import React from "react";
//import Button from 'react-bootstrap/Button';

import MDBox from "components/MDBox";
import "uvagriotLayouts/utility/styles/uvagriotStyle.css";

//import "../style/style.css";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { enumTypeParam } from "uvagriotLayouts/utility/enumerators";

import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBCol,
} from "mdb-react-ui-kit";

import Grid from "@mui/material/Grid";

import { StatusCodes } from "http-status-codes";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import {
  FormInput,
  FormDropDownList,
  FormTextArea,
  FormSwitch,
  FormNumericTextBox,
} from "uvagriotLayouts/utility/form-components";
import {
  existParamValidator,
  existLabelValidator,
  numberValidator,
} from "uvagriotLayouts/utility/validators";
import uvagriotService from "../../../services/uvagriot-service";
import { Typography } from "@mui/material";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

export default function ParamForm(props) {
  const { toggleShow, modalHook, modality, editValue, paramsHook, check } =
    props;

  const formRef = React.useRef(null);

  const [initialState, setInitialState] = useState(editValue);

  const [minvalue, setMinValue] = useState(1);

  const closeModal = () => {
    closeForm(formRef.current.resetForm);
  };

  useEffect(() => {
    setInitialState(editValue);
    setMinValue(editValue.threshold_L);
  }, [editValue]);

  const closeForm = (reset) => {
    reset();
    toggleShow();
  };

  const submit = async (dataItem) => {
    console.log("submit --------------------------");
    console.log(dataItem);

    const response = await uvagriotService.parametriNodoPUT(
      JSON.stringify(dataItem)
    );
    console.log("response data");
    console.log(response.data);

    if (response.status === 200) {
      let copy = [...paramsHook.rows];
      console.log("First copy");
      console.log(copy);

      copy = copy.map((param) => ({
        ...param,
        parametriNodo: param.parametriNodo.map((p) =>
          p.id === response.data.id ? response.data : p
        ),
      }));

      console.log("copy");
      console.log(copy);
      paramsHook.setRows(copy);
      check.setCheck(true);
    } else {
      alert("qualcosa è andato storto"); //do something
    }

    closeForm(formRef.current.resetForm);
  };

  useEffect(() => {
    formRef.current.resetForm();
  }, [initialState]);

  return (
    <>
      <MDBModal
        staticBackdrop
        tabIndex="-1"
        show={modalHook.staticModal}
        setShow={modalHook.setStaticModal}
        className="pt-5"
      >
        <MDBModalDialog>
          <MDBModalContent>
            <MDBox
              mx={1}
              mt={-3}
              py={1}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDBModalHeader style={{ border: 0 }}>
                <MDBModalTitle style={{ color: "whitesmoke" }}>
                  {modality === "edit"
                    ? "Modifica " + initialState.parametro
                    : "Crea Parametro"}
                </MDBModalTitle>
                <MDBBtn
                  className="btn-close float right"
                  color="none"
                  aria-label="Close"
                  onClick={closeModal}
                />
              </MDBModalHeader>
            </MDBox>

            <MDBModalBody className="px-4 py-0">
              <MDBCol xl={12} lg={12} className="mb-4 pr-3">
                <Form
                  ref={formRef}
                  onSubmit={submit}
                  initialValues={initialState}
                  key={"form_recipe"}
                  render={(formRenderProps) => (
                    <FormElement>
                      <fieldset className={"k-form-fieldset"}>
                        <Grid container>
                          <Grid item xs={12} md={12}>
                            <Field
                              id={"thrHID"}
                              name={"threshold_H"}
                              label={"threshold High"}
                              component={FormNumericTextBox}
                              min={
                                formRenderProps.valueGetter("threshold_L") + 1
                              }
                              validator={numberValidator}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <Field
                              id={"thrLID"}
                              name={"threshold_L"}
                              label={"threshold Low"}
                              component={FormNumericTextBox}
                              //onChange={setV}
                              min={0}
                              max={
                                formRenderProps.valueGetter("threshold_H") - 1
                              }
                              //validator={numberValidator}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <Field
                              id={"realtieID"}
                              name={"realtime"}
                              label={"Realtime"}
                              className={"uvagriotSwitch"}
                              component={FormSwitch}
                              //validator={(choice) => existRecipeValidator(choice, recipesHook.recipes)}
                            />
                          </Grid>
                        </Grid>
                      </fieldset>
                      <div className="k-form-buttons">
                        <Button
                          themeColor={"primary"}
                          type={"submit"}
                          disabled={!formRenderProps.allowSubmit}
                        >
                          {modality === "edit" ? "Aggiorna" : "Aggiungi"}
                        </Button>
                        <Button
                          onClick={() => closeForm(formRenderProps.onFormReset)}
                        >
                          Chiudi
                        </Button>
                      </div>
                    </FormElement>
                  )}
                />
              </MDBCol>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <NotificationContainer />
    </>
  );
}

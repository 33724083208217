import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useEffect } from "react";
import { Scrollbar } from "smooth-scrollbar-react";
import TablePagination from "@mui/material/TablePagination";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { NavLink } from "react-router-dom";
import { Component } from "react";

import { styled } from "@mui/material/styles";

import { BarChart, Bar, XAxis, ResponsiveContainer } from "recharts";
import { Tooltip as TP } from "recharts";

// Images
import MDButton from "components/MDButton";

import { BsPlusCircleFill } from "react-icons/bs";
import { BsCheck2Circle } from "react-icons/bs";
import { BsXCircle, BsPen } from "react-icons/bs";
import { BsX, BsPlusCircleDotted, BsPlus } from "react-icons/bs";
import { BsTrash3, BsPenFill, BsFileText } from "react-icons/bs";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import Popover from "@mui/material/Popover";
import uvagriotService from "../../../services/uvagriot-service";
import { useState } from "react";

import { MDBCheckbox, MDBSelect } from "mdb-react-ui-kit";

import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "react-select";
import Chip from "@mui/material/Chip";
import { dataCsvIcon } from "@progress/kendo-svg-icons";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import ClientiForm from "./ClientiForm";
//import "uvagriotLayouts/utility/styles/uvagriotStyle.css";

import {
  enumStructureKey,
  regexStructureKey,
} from "uvagriotLayouts/utility/enumerators";

const structureKey = Object.values(enumStructureKey);

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${label}`}</p>
      </div>
    );
  }
  return null;
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function DeleteButton(props) {
  const { deleteAction, parametro } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [params, setParams] = useState([]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (parametro.is_structure) {
      let parametri = [];

      let param = parametro.nome.replace(regexStructureKey, "");

      structureKey.map((key) => {
        parametri.push(param + key);
      });

      setParams(parametri);
    }
  }, [parametro]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const label = "elimina";

  return (
    <>
      <Tooltip title={label} TransitionComponent={Zoom} placement="top">
        <span>
          <IconButton color="error" onClick={handleClick}>
            <BsTrash3 size={20} className="p-0" />
          </IconButton>
        </span>
      </Tooltip>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Typography sx={{ p: 2 }} variant="h6">
          Sei Sicuro di voler eliminare questo account?
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "5px 16px 5px 16px",
          }}
        >
          <MDButton
            variant="text"
            color="info"
            onClick={handleClose}
            size="small"
          >
            indietro
          </MDButton>
          <MDButton
            color={"error"}
            size="small"
            onClick={() => deleteAction(parametro.id)}
          >
            Elimina
          </MDButton>
        </div>
      </Popover>
    </>
  );
}

function Row(props) {
  const { row, _delete } = props;

  const trueElem = <BsCheck2Circle color="green" size={25} />;
  const falseElem = <BsXCircle color="red" size={20} />;

  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell align="center">{row.email}</TableCell>
        <TableCell component="th" scope="row" align="center">
          {row.nome}
        </TableCell>
        <TableCell align="center">{row.cognome}</TableCell>
        <TableCell align="center">{row.codice_fiscale}</TableCell>
        <TableCell align="center">{row.start_date}</TableCell>

        <TableCell align="right">
          {/* <ParamEditButton color="info" onClick={() => updateAction(row)}>
            <BsPen size={20} />
          </ParamEditButton> */}
          <DeleteButton key={row.pk} deleteAction={_delete} parametro={row} />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function ClientiTable(props) {
  const { rows } = props;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [staticModal, setStaticModal] = useState(false);

  const toggleShow = () => setStaticModal(!staticModal);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteAction = async (accountId) => {
    console.log("DELETE");
    const response = await uvagriotService.usersDELETE(
      JSON.stringify({ pk: accountId })
    );
    if (response.status === 200) {
      console.log(response.msg);
      rows.setRows(rows.rows.filter((row) => row.id !== accountId));
    }

    //setEditValue(data);
  };

  useEffect(() => {
    console.log("rows");

    console.log(rows.rows);
  }, []);

  return (
    <>
      <TableContainer component={Paper} style={{ minHeight: "500px" }}>
        <Table aria-label="collapsible table">
          <TableHead sx={{ display: "table-header-group" }}>
            <TableRow>
              {/* <TableCell style={{maxWidth: '40px'}}>Descrizione</TableCell> */}
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Nome</TableCell>
              <TableCell align="center">Cognome</TableCell>
              <TableCell align="center">Codice Fiscale</TableCell>
              <TableCell align="center">Registrazione</TableCell>
              {/*  <TableCell align="center">Utilizzo</TableCell> */}
              <TableCell component="td" align="right">
                <Button
                  variant="text"
                  color="primary"
                  style={{ paddingRight: 17 }}
                  onClick={toggleShow}
                  id={"addClientID"}
                >
                  <BsPlus size={30} align={"right"} />
                  AGGIUNGI
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row key={index} row={row} _delete={deleteAction} />
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          colSpan={3}
          count={rows.rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage="righe per pagina"
          SelectProps={{
            select: {
              marginbottom: "10px",
            },
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </TableContainer>
      <ClientiForm
        id={"createFormID"}
        paramsHook={rows}
        toggleShow={toggleShow}
        modalHook={{
          staticModal: staticModal,
          setStaticModal: setStaticModal,
        }}
        //editValue={editValue}
        //modality={"edit"}
      />
    </>
  );
}

const ParamButton = styled(IconButton)({
  "&:hover": {
    color: "red",
    boxShadow: "none",
  },
});
const ParamEditButton = styled(IconButton)({
  "&:hover": {
    color: "blue",
    boxShadow: "none",
  },
});

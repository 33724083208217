import React, { useCallback, useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

/* import { v } from "../style/Variables"; */
/* import { Button, ButtonGroup, Dropdown } from "react-bootstrap"; */

import MDBox from "components/MDBox";

import "../style/formStyle.css";

import Grid from "@mui/material/Grid";
import { useMaterialUIController } from "context";
import TipologiaNodiTemplate from "./tipologiaTemplate";

// Data
import MDButton from "components/MDButton";
import uvagriotService from "../../../services/uvagriot-service";

import { Form, Field, FormElement } from "@progress/kendo-react-form";

import {
  FormInput,
  FormTextArea,
  FormComboBox,
} from "../../utility/form-components";
import { emptyValidator, checkvalidator } from "../../utility/validators";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import ParamTable from "./DataTable";

function DashboardUpdateType() {
  const location = useLocation();
  const formRef = React.useRef(null);
  const [data, setData] = useState([]);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [initialValue, setInitialValue] = useState({
    tipologia: location.state.tipologia.nometipologia,
    descrizione: location.state.tipologia.descrizione,
    modello: location.state.tipologia.modello,
    coltura: location.state.tipologia.coltura,
  });

  const notificationAction = () => {
    NotificationManager.success("Tipologia modificata con successo", "", 4000);
  };

  useEffect(() => {
    console.log("PARAMETRI DI MODIFICA ----------------------------");
    let params = [];
    location.state.tipologia.parametriTipologia.map((elem) =>
      params.push({
        paramID: elem.parametro.id,
        nome: elem.parametro.nome,
        realtime: elem.realtime,
        modality: "view",
      })
    );
    console.log(params);
    setData(params);
  }, []);

  const navigate = useNavigate();

  const goBack = () => {
    navigate("/tipologia-nodo");
  };

  const submit = async (dataitem) => {
    console.log("UPDATE");
    console.log(dataitem);
    console.log(location.state.tipologia);
    const response = await uvagriotService.tipologiaNodoPUT(
      JSON.stringify({
        pk: location.state.tipologia.id,
        tipologia: dataitem.tipologia,
        descrizione: dataitem.descrizione,
        modello: dataitem.modello.id,
        coltura: dataitem.coltura.id,
        parametri: data,
      })
    );

    switch (response.status) {
      case 200:
        //notificationAction();
        goBack();

        break;
      case 409:
        break;
    }
    //goBack();
  };

  return (
    <>
      <MDBox pt={3}>
        <MDBox pt={1} pb={2}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={5} xl={4} mb={3} px={2}>
              <MDBox
                component="ul"
                display="flex"
                flexDirection="column"
                p={0}
                m={0}
                bgColor={darkMode ? "transparent" : "grey-100"}
                style={{ borderRadius: "0.5rem" }}
              >
                <MDBox
                  id={"box_tipologia"}
                  component="li"
                  display="flex"
                  justifyContent="space-between"
                  borderRadius="lg"
                  my={2}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12} xl={12}>
                      <Form
                        ref={formRef}
                        onSubmit={submit}
                        validator={() =>
                          checkvalidator(
                            data.filter((e) => e.modality === "view").length
                          )
                        }
                        initialValues={initialValue}
                        ignoreModified={true}
                        render={(formRenderProps) => (
                          <FormElement px={2}>
                            <fieldset className={"k-form-fieldset px-3"}>
                              {formRenderProps.visited &&
                                formRenderProps.errors &&
                                formRenderProps.errors.VALIDATION_SUMMARY && (
                                  <div
                                    className={
                                      "k-messagebox k-messagebox-error"
                                    }
                                  >
                                    {formRenderProps.errors.VALIDATION_SUMMARY}
                                  </div>
                                )}
                              <Field
                                id={"id_tip"}
                                name={"tipologia"}
                                label={"tipologia"}
                                component={FormInput}
                                validator={emptyValidator}
                              />
                              <Field
                                id={"id_descrizione"}
                                name={"descrizione"}
                                label={"Descrizione"}
                                component={FormTextArea}
                                rows={15}
                              />
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                  <Field
                                    id={"modelloID"}
                                    name={"modello"}
                                    label={"Modello"}
                                    textField={"modello"}
                                    component={FormComboBox}
                                    data={location.state.modelli}
                                  />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Field
                                    id={"colturaID"}
                                    name={"coltura"}
                                    label={"Coltura"}
                                    textField={"nome"}
                                    component={FormComboBox}
                                    data={location.state.colture}
                                  />
                                </Grid>
                              </Grid>
                            </fieldset>

                            <div className="k-form-buttons px-3">
                              <MDButton
                                variant="gradient"
                                color="info"
                                className="float-right"
                                type={"submit"}
                                disabled={!formRenderProps.allowSubmit}
                                fullWidth
                              >
                                Modifica
                              </MDButton>
                            </div>
                          </FormElement>
                        )}
                      />

                      {/* <MDBox component="form" role="form" px={2}>
                                            <MDInput type="text" label="Tipologia Nodo" name='nome_nodo' fullWidth
                                                value={nodo.nome_nodo}
                                                onChange={changeHandler}
                                            />
                                        </MDBox> */}
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={7} xl={8} px={2}>
              <MDBox className={"mx-0 mb-2 mt-0"}>
                <ParamTable rows={data} setRows={setData} />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </>
  );
}

// #End region

export class UpdateTipologia extends React.Component {
  render() {
    return (
      <TipologiaNodiTemplate
        title="Modifica tipologia nodo"
        component={<DashboardUpdateType />}
      />
    );
  }
}

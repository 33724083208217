import React, { Component } from "react";
import { useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import DashboardNodi from "./DBComponent/DashBoard";
import UvagriotTemplate from "uvagriotLayouts/utility/UvagriotTemplate";

export default function DashboardNodiWrapper() {
    const params = useParams();
    const state = useLocation();
    return (
        <>
            {state?.state ?
                < UvagriotTemplate
                    title={"Nodo " + state.state.nodo}
                    component={< DashboardNodi nodoID={params.nodoID} />}
                /> : < UvagriotTemplate
                    title={"Nodo "}
                    component={< DashboardNodi nodoID={params.nodoID} />}
                />
            }
        </>
    )

};

// #End region

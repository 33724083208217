import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import React, {useEffect, useState} from "react";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {RJSFSchema} from "@rjsf/utils";
import {NotificationContainer, NotificationManager} from "react-notifications";
import Form from "@rjsf/core";
import {customizeValidator} from "@rjsf/validator-ajv8";
import localizer from "ajv-i18n";
import MDButton from "../../components/MDButton";
import UvagriotService from "../../services/uvagriot-service";
import {UiSchema} from "@rjsf/utils";


export default function DSS_Tipologia_Coltura_Single() {

    let location = useLocation();
    const [formData, setFormData] = useState(null);
    const navigate = useNavigate()
    console.log(location.state.id);

    useEffect(() => {
        if (location.state.id > 0) {
            getTipologia();
        }
    }, []);

    let getTipologia = async () => {
        let response = await UvagriotService.tipologiaColturaGET(location.state.id);
        let tmp_form_data = {
            "nome": response?.nome,
            "descrizione": response?.descrizione,
            "temperatura_base": response?.temperatura_base
        }
        setFormData(tmp_form_data)
        console.log(response);
    }

    const schema: RJSFSchema = {
        title: location.state.id > 0 ? "Modifica Tipologia" : "Nuova Tipologia",
        type: 'object',
        required: ['nome', 'temperatura_base'],
        properties: {
            nome: {type: 'string', title: 'Nome'},
            temperatura_base: {type: 'number', title: 'Temperatura Base'},
            descrizione: {type: 'string', title: 'Descrizione'},
        },
    };

    const uiSchema: UiSchema = {
        "descrizione": {
            "ui:widget": "textarea"
        },
        "ui:submitButtonOptions": {
            "props": {
                "disabled": false,
                "className": "btn btn-info"
            },
            "submitText": "Applica"
        }
    }

    const onSubmit = async () => {
        console.log(formData);
        let response = null
        if (location.state.id > 0) {
            response = await UvagriotService.tipologiaColturaPUT(location.state.id, formData)
        } else {
            response = await UvagriotService.tipologiaColturaPOST(formData);
        }
        console.log(response)
        await createNotification(response)
    }

    const createNotification = async (response) => {
        if (response?.id > 0) {
            navigate("/dss/tipologia_coltura");
        } else {
            let error = ''
            let i = 0
            for (let k in response) {
                if (i > 0) error += "\n"
                error += response[k]
                i++
            }
            NotificationManager.error(
                error,
                "Errore",
                5000
            );
        }
    };

    const validator = customizeValidator({}, localizer.it);

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox py={1}>
                <MDBox>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Form
                                schema={schema}
                                uiSchema={uiSchema}
                                formData={formData}
                                onChange={(e) => setFormData(e.formData)}
                                validator={validator}
                                onSubmit={onSubmit}
                            >
                                <div style={{"padding-top": 15}}>
                                    <MDButton
                                        variant="gradient"
                                        color="info"
                                        m={5}
                                        type={"submit"}
                                    >
                                        Invia
                                    </MDButton>
                                    &nbsp;
                                    <NavLink to={`/dss/tipologia_coltura`}>
                                        <MDButton variant="gradient" color="warning">
                                            Annulla
                                        </MDButton>
                                    </NavLink>
                                </div>
                            </Form>
                        </Grid>
                    </Grid>
                </MDBox>
            </MDBox>
            <NotificationContainer/>
        </DashboardLayout>
    );
}
import React, {useEffect, useState} from 'react';
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "../../components/MDTypography";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import uvagriotService from "../../services/uvagriot-service";
import DataTable from "react-data-table-component";
import MDButton from "../../components/MDButton";
import {MDBSwitch} from "mdb-react-ui-kit";
import {NavLink, useNavigate} from "react-router-dom";
import UvagriotService from "../../services/uvagriot-service";
import {NotificationManager} from "react-notifications";

export default function DSS_Fase_Fenologica() {

    const [listFasi, setListFasi] = useState([])
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()

    const getFasi = async () => {
        const response = await uvagriotService.faseFenologicaLIST()
        console.log("fase_fenologica")
        console.log(response)
        setListFasi(response);
    }

    useEffect(() => {
        getFasi().then(r => setLoading(false))
    }, []);

    const handleButtonClick = async (id) => {
        console.log(id)
        let response = await UvagriotService.faseFenologicaDELETE(id)
        console.log(response)
        getFasi().then(r => setLoading(false))
    }

    const createNotification = async (response) => {
        if (response !== {}) {
            let error = ''
            let i = 0
            for (let k in response) {
                if (i > 0) error += "\n"
                error += response[k]
                i++
            }
            NotificationManager.error(
                error,
                "Errore",
                5000
            );
        }
    };

    let columns = [
        {
            name: 'Nome',
            selector: row => row.nome,
        },
        {
            name: 'Descrizione',
            selector: row => row.descrizione,
        },
        {
            name: '',
            button: true,
            cell: (row) =>
                (
                    <NavLink to={`/dss/fase_fenologica/single`} state={{id: row.id}}>
                        <MDButton variant="gradient" color="info" m={5}>
                            Edit
                        </MDButton>
                    </NavLink>
                ),
        },
        {
            name: '',
            button: true,
            cell: (row) => (
                <MDButton
                    disabled={!row.is_fase_linked}
                    onClick={() => {handleButtonClick(row.id)}}
                    variant="gradient" color="error" m={5}>
                    Delete
                </MDButton>
            ),
        },
    ];


    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox pt={6} pb={3} style={{minWidth: 280}}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Fase Fenologica
                                </MDTypography>
                            </MDBox>
                            <MDBox pb={2} pt={2} px={2}>
                                <NavLink to={`/dss/fase_fenologica/single`} state={{id: 0}}>
                                    <MDButton variant="gradient" color="info">
                                        Nuovo
                                    </MDButton>
                                </NavLink>
                                <DataTable
                                    columns={columns}
                                    data={listFasi}
                                    pagination
                                    subHeader
                                    // subHeaderComponent={subHeaderComponent}
                                    noDataComponent={<p className={'py-5'}><h4>Nessuna Fase Fenologica</h4></p>}
                                />
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    );
}
import Grid from "@mui/material/Grid";
import MDBox from "../../MDBox";
import React, {useEffect, useState} from "react";
import UvagriotService from "../../../services/uvagriot-service";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import uvagriotSchema from "../../../assets/images/uvagriot_schema.jpg";
import DefaultInfoCard from "../../../examples/Cards/InfoCards/DefaultInfoCard";
import styled from "styled-components";

const Attuazione = ({id}) => {

    const [act, setAct] = useState([]);
    const [loading, setLoading] = useState(true)
    const [paramLoading, setParamLoading] = useState([])

    const getRealtimeParams = async () => {

        const data = await UvagriotService.getRealtimeParams(id);
        console.log("data")
        console.log(data)
        setAct(data.attuatori);
    }

    useEffect(() => {
        getRealtimeParams();
    }, []);

    useEffect(() => {
            if (act.length) {
                const machine_socket = UvagriotService.getDataMqttSocket(id);
                machine_socket.onmessage = function (e) {
                    try {
                        console.log(e)
                        let response = JSON.parse(e.data)
                        let response_value = response?.value?.value
                        console.log(response_value)
                        if (response_value) {
                            realTimeActuatorsList(response_value);
                        } else {
                            console.log("response: ")
                            console.log(response)
                        }
                    } catch
                        (e) {
                        console.log(e)
                    }
                }
                if (loading) {
                    UvagriotService.getLastRealtimeParams(id).then(
                        setLoading(false)
                    )
                }
                //set interval for ping and keep alive websocket
                const interval = setInterval(async () => {
                    console.log("send ping");
                    machine_socket.send(JSON.stringify({ping: 1}));
                }, 60000);
                return () => {
                    clearInterval(interval);
                    machine_socket.close();
                }
            }
        }, [act]
    );

    const realTimeActuatorsList = (param) => {
        let backup_list = act
        for (let k in param) {
            backup_list = backup_list.map((elem) => (
                elem.nome === param[k]?.Id
                    ? {...elem, Value: param[k]?.Value, loading: param[k]?.loading} :
                    elem
            ))
        }
        setAct(backup_list);
    }

    const tryOpenActuator = async (param) => {
        setParamLoading(param)
        param.loading = true
        param.nodo = id
        let list_param = [param]
        console.log(param)
        realTimeActuatorsList(list_param)
        let data = await UvagriotService.sendManualActuator(param);
        console.log(data)
    }

    return (
        <MDBox pt={6} pb={6}>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    {act.length > 0 ?
                        (
                            <MDBox pt={3}>
                                <ContainerRealTime>
                                    <Container
                                        style={{margin: "12px auto 12px auto"}}
                                        fluid
                                        className="d-flex justify-content-around"
                                    >
                                        <Row>
                                            <Col xl={6} md={6} xs={12} style={{marginBottom: 12}}>
                                                <img
                                                    src={uvagriotSchema}
                                                    className='img-fluid hover-shadow'
                                                    alt='uvagriot_schema'
                                                />
                                            </Col>
                                            <Col xl={6} md={6} xs={12} style={{marginBottom: 12}}>
                                                <Row>
                                                    {act.map(attuatore => (
                                                        <Col xl={4} md={4} xs={12} style={{marginBottom: 12}}
                                                             key={attuatore.nome}>
                                                            <div fluid={1}
                                                                 style={{textAlign: "center"}} key={attuatore.nome}>
                                                                <div>
                                                                    <DefaultInfoCard
                                                                        icon={attuatore.icona}
                                                                        style={{background: "linear-gradient(195deg, #3CD93F, #33ba3b)"}}
                                                                        type={true}
                                                                        title={attuatore.descrizione}
                                                                        value={attuatore.Value}
                                                                        loading={attuatore.loading}
                                                                        iconButton={() => tryOpenActuator(attuatore)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Container>
                                </ContainerRealTime>
                            </MDBox>
                        )
                        :
                        (<></>)}
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default Attuazione;

// #Region Styled Component

const ContainerRealTime = styled.div`
    .thermometer ul.thermometer__statistics li {
        width: auto !important;
    }
`;

const Spazer = styled.div`
    height: 5vh;
`;

// #End region
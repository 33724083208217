import * as React from "react";
import UvagriotService from "../../../services/uvagriot-service";

import { Form, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import "react-notifications/lib/notifications.css";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import { useLocation, useNavigate } from "react-router-dom";
import "uvagriotLayouts/utility/styles/uvagriotStyle.css"

import { Field } from "@progress/kendo-react-form";
import { FormInput, FormDateInput, FormRadioGroup, FormNumericTextBox, FormDropDownList, FormComboBox, FormTextArea } from "uvagriotLayouts/utility/form-components";
import {
    userNameValidator,
    emailValidator,
    NumberValidator,
    nameValidator,
    appezzamentoValidator,
    requiredValidator,
    codiceFiscaleValidator,
    /* passwordValidator, */
} from "uvagriotLayouts/utility/validators";

import uvagriotService from "services/uvagriot-service";



/* import { PaymentDetails } from "./payment-details"; */
//const stepPages = [<AccountDetails />, <PersonalDetails />];

function App() {
    const navigate = useNavigate();
    const data = useLocation();
    const formRef = React.useRef(null);
    const [step, setStep] = React.useState(0);
    const [formState, setFormState] = React.useState({});
    const [clients, SetClients] = React.useState([]);
    const getUsers = async () => {
        const response = await uvagriotService.usersGET()
        console.log("users")
        console.log(response.data)
        let d = response.data.map(user => ({ 'id': user.id, 'email': user.email }))
        console.log(d)
        SetClients(response.data.map(user => ({ 'id': user.id, 'email': user.email })));
    }


    React.useEffect(() => {
        getUsers()
        if (data.state?.appezzamento) {
            console.log("update")
            setFormState({
                'nome': data.state?.appezzamento?.appezzamento?.nome,
                'descrizione': data.state?.appezzamento?.appezzamento?.descrizione,
                'longitudine': data.state?.appezzamento?.appezzamento?.longitudine,
                'latitudine': data.state?.appezzamento?.appezzamento?.latitudine,
                'indirizzo': data.state?.appezzamento?.appezzamento?.indirizzo,
                'utente': { 'id': data.state?.appezzamento?.clienteID, 'email': data.state?.appezzamento?.cliente }
            })
            formRef.current.resetForm()
        }
    }, [])

    React.useEffect(() => {
        formRef.current.resetForm()
    }, [formState])




    /* const [isLoading, setIsLoading] = React.useState(false);
    const [steps, setSteps] = React.useState([
        {
            label: "Info Utente",
            isValid: undefined,
        },
        // {
        //     label: "Info Azienda",
        //     isValid: undefined,
        // },
    ]); */
    /* const lastStepIndex = steps.length - 1; */
    /* const isLastStep = lastStepIndex === step; */
    /* const isPreviousStepsValid =
        steps
            .slice(0, step)
            .findIndex((currentStep) => currentStep.isValid === false) === -1; */
    /* const onStepSubmit = React.useCallback(
        (event) => {
            const { isValid, values } = event;
            const currentSteps = steps.map((currentStep, index) => ({
                ...currentStep,
                isValid: index === step ? isValid : currentStep.isValid,
            }));
            setSteps(currentSteps);
            setStep(() => Math.min(step + 1, lastStepIndex));
            setFormState(values);
            if (isLastStep && isPreviousStepsValid && isValid) {
                setIsLoading(true);
                
                sendToBAckend(values);
            }
        },
        [steps, isLastStep, isPreviousStepsValid, step, lastStepIndex]
    ); */

    const createNotification = (type) => {
        if (type === "info") {
            NotificationManager.info("Info message");
        } else if (type === "success") {
            NotificationManager.success(
                "Utente e Azienda inseriti",
                "Inserimento Effettuato",
                5000
            )
            navigate("/gestione_appezzamenti");
        } else if (type === "warning") {
            NotificationManager.warning(
                "Warning message",
                "Close after 3000ms",
                3000
            );
        } else if (type === "error") {
            /* NotificationManager.error("Error message", "Click me!", 5000, () => {
              alert("callback");
            }); */
            NotificationManager.error(
                "Problema durante l'inserimento",
                "Errore durante inserimento",
                5000
            );
        } else if (type === "errorCustom") {
            /* NotificationManager.error("Error message", "Click me!", 5000, () => {
              alert("callback");
            }); */
            NotificationManager.error(
                "Problema durante l'inserimento",
                "Azienda e Partita iva già presente.",
                5000
            );
        }
    };

    const sendToBAckend = async (dataUser) => {

        console.log("data --------")
        console.log(dataUser);

        let response;
        if (data.state.modality) {
            console.log("update")
            dataUser['appezzamentoID'] = data.state?.appezzamento?.appezzamento?.id
            response = await UvagriotService.appezzamentiPUT(JSON.stringify(dataUser));
        } else {
            console.log("create")
            response = await UvagriotService.appezzamentiPOST(JSON.stringify(dataUser));
        }

        createNotification(response.responce);
    };

    /* const onPrevClick = React.useCallback(
        (event) => {
            event.preventDefault();
            setStep(() => Math.max(step - 1, 0));
        },
        [step, setStep]
    ); */
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
            }}
        >
            {/*<Stepper value={step} items={steps}/>*/}
            <Form
                ref={formRef}
                initialValues={formState}
                onSubmit={sendToBAckend}
                render={(formRenderProps) => (
                    <div
                        style={{
                            /* alignSelf: "center", */
                            marginLeft: "20px",
                            marginRight: "20px",
                            marginBottom: "20px",
                        }}
                    >
                        <FormElement
                            style={{
                                width: "100%",
                            }}
                        >
                            <div>
                                <Field
                                    key={"nomeAppID"}
                                    id={"appezzamento?ID"}
                                    name={"nome"}
                                    label={"Nome Appezzamento"}
                                    component={FormInput}
                                    validator={(choice) => appezzamentoValidator(choice, data.state.allApp)}
                                />
                                <Field
                                    key={"descrizioneID"}
                                    id={"descrizioneID"}
                                    name={"descrizione"}
                                    label={"Descrizione"}
                                    component={FormTextArea}
                                    rows={5}
                                //validator={userNameValidator}
                                />
                                <Field
                                    key={"indirizzoID"}
                                    id={"indirizzoID"}
                                    name={"indirizzo"}
                                    label={"indirzzo"}
                                    component={FormInput}
                                //validator={userNameValidator}
                                />

                                <Field
                                    key={"longitudineID"}
                                    id={"longitudineID"}
                                    name={"longitudine"}
                                    label={"Longitudine"}
                                    component={FormNumericTextBox}
                                // validator={codiceFiscaleValidator}
                                />
                                <Field
                                    key={"latitudineID"}
                                    id={"latitudineID"}
                                    name={"latitudine"}
                                    label={"Latitudine"}

                                    component={FormNumericTextBox}
                                // validator={codiceFiscaleValidator}
                                />

                                {!data.state?.modality
                                    &&
                                    <Field
                                        key={"clienteID"}
                                        id={"clienteID"}
                                        name={"utente"}
                                        label={"Cliente"}
                                        //hint={"Inserisci la tua e-mail"}
                                        //type={"email"}
                                        textField={"email"}
                                        component={FormComboBox}
                                        data={clients}
                                        //defaultValue={clients[0]}
                                        clearButton={false}
                                        validator={nameValidator}
                                    />
                                }

                            </div>
                            <span
                                style={{
                                    marginTop: "40px",
                                }}
                                className={"k-form-separator"}
                            />
                            <div
                                style={{
                                    justifyContent: "space-between",
                                    alignContent: "center",
                                }}
                                className={
                                    "k-form-buttons k-button k-button-md k-rounded-md k-button-solid k-button-solid-bases-end"
                                }
                            >
                                <div>
                                    <Button
                                        themeColor={"primary"}
                                        disabled={!formRenderProps.allowSubmit}
                                        onClick={formRenderProps.onSubmit}
                                    >
                                        {!data.state?.modality ? "Inserisci" : "aggiorna"}
                                    </Button>
                                </div>
                            </div>
                        </FormElement>
                    </div>
                )}
            />
            <NotificationContainer />
        </div>
    );
}

export default App;

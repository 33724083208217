// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// Material Dashboard 2 React context
import { useMaterialUIController } from "context";

function InfoPianificazione({ nodo, form, errors, noGutter }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor={darkMode ? "transparent" : "grey-100"}
      borderRadius="lg"
      p={3}
      mb={noGutter ? 0 : 1}
      mt={2}
    >
      <MDBox width="100%" display="flex" flexDirection="column">
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          mb={2}
        >
          <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
            {nodo?.nome}
          </MDTypography>

        </MDBox>
        {errors &&
        <MDBox mb={1} lineHeight={0}>
        <MDTypography variant="caption" color="text">
            <MDTypography variant="caption" fontWeight="medium">
            <div className={"k-messagebox k-messagebox-error"}>
            {
              errors
            }
          </div>
            </MDTypography>
          </MDTypography>
        </MDBox>}
        {/* <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="text">
            Tipologia Coltura:&nbsp;&nbsp;&nbsp;
            <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
              {nodo?.tipologia_coltura}
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="text">
            Percorso:&nbsp;&nbsp;&nbsp;
            <MDTypography variant="caption" fontWeight="medium">
              {nodo?.percorso}
            </MDTypography>
          </MDTypography>
        </MDBox> */}
        {form}
      </MDBox>
    </MDBox>
  );
}


export default InfoPianificazione;

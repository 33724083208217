import React, {useEffect, useState} from "react";
import UvagriotService from "services/uvagriot-service";
/* import { Card } from "primereact/card"; */
import styled from "styled-components";

import {Knob} from "primereact/knob";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Thermometer from "react-thermometer-component";
import {Margin} from "@mui/icons-material";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {BsFillCircleFill} from "react-icons/bs";
import {ResponsiveContainer} from "recharts";
import DataTable from "react-data-table-component";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

import uvagriotSchema from "assets/images/uvagriot_schema.jpg";


const RealTime = ({id}) => {

        const [productionTime, setProductionTime] = React.useState("");
        const [realtimeParams, setRealtimeParams] = React.useState([]);
        const [act, setAct] = useState([]);
        const [sensor, setSensor] = useState([]);
        const [loading, setLoading] = useState(true)

        //const [socketData, setSocketData] = React.useState({});

        //const [realTimeAlarm, setRealTimeAlarm] = React.useState(null)

        /* const handleExpandClick = () => {
          setExpanded(!expanded);
        }; */

        const getRealtimeParams = async () => {

            const data = await UvagriotService.getRealtimeParams(id);
            console.log("data")
            console.log(data)
            setAct(data.attuatori);
            setSensor(data.sensori);
        }

        useEffect(() => {
            getRealtimeParams();
        }, [])


        useEffect(() => {
                if (act.length || sensor.length) {

                    const machine_socket = UvagriotService.getDataMqttSocket(id);

                    machine_socket.onmessage = function (e) {
                        try {
                            console.log(e)
                            let response = JSON.parse(e.data)
                            let response_value = response?.value?.value
                            console.log(response_value)
                            if (response_value) {
                                realTimeActuatorsList(response_value);
                                realTimeParamsList(response_value);
                            } else {
                                console.log("response: ")
                                console.log(response)

                            }
                        } catch
                            (e) {
                            console.log(e)
                        }
                    }

                    if(loading){
                        UvagriotService.getLastRealtimeParams(id).then(
                            setLoading(false)
                        )
                    }

                    /* machine_socket.onclose = function(e){
                        console.log("connection close")
                    } */

                    //set interval for ping and keep alive websocket
                    const interval = setInterval(async () => {
                        console.log("send ping");
                        machine_socket.send(JSON.stringify({ping: 1}));
                    }, 60000);


                    return () => {
                        clearInterval(interval);
                        machine_socket.close();
                    }

                }

            }, [act, sensor]
        )
        ;


        const realTimeActuatorsList = (param) => {

            let backup_list = act
            for (let k in param) {
                backup_list = backup_list.map((elem) => (
                    elem.nome === param[k]?.Id
                        ? {...elem, Value: param[k]?.Value} :
                        elem
                ))
            }
            setAct(backup_list);
        }

        const realTimeParamsList = (param) => {
            let backup_list = sensor
            for (let k in param) {
                backup_list = backup_list.map((elem) => (
                    elem.nome === param[k]?.Id
                        ? {...elem, Value: param[k]?.Value} :
                        elem
                ))
            }
            setSensor(backup_list);
        }

        /* const realTimeParamsList = () => {
            let list = []
            if (realtimeParams.length > 0) {
                for (let k in realtimeParams) {
                    if (realtimeParams[k].tipologia === 0) {
                        let single_param = <Col xl={4} md={4} xs={12} style={{ marginBottom: 12 }}
                            key={realtimeParams[k].nome}>
                            <div fluid={1}
                                style={{ textAlign: "center" }} key={realtimeParams[k].nome}>
                                <div>
                                    <DefaultInfoCard
                                        icon={realtimeParams[k].icona}
                                        style={{ background: "linear-gradient(195deg, #3CD93F, #33ba3b)" }}
                                        type={false}
                                        title={realtimeParams[k].descrizione}
                                        value={String(realtimeParams[k].Value) + " " + realtimeParams[k].unita_di_misura}
                                    />
                                </div>
                            </div>
                        </Col>
                        list.push(single_param)
                    }
                }
                return (<>{list}</>);
            }
            return (<></>);
        }
        */
        return (
            <MDBox pt={6} pb={6}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <MDBox pt={3}>
                            <ContainerRealTime>
                                <Container
                                    style={{margin: "12px auto 12px auto"}}
                                    fluid
                                    className="d-flex justify-content-around"
                                >
                                    <Row>
                                        {sensor.map(sensore => (
                                            <Col xl={4} md={4} xs={12} style={{marginBottom: 12}}
                                                 key={sensore.nome}>
                                                <div fluid={1}
                                                     style={{textAlign: "center"}} key={sensore.nome}>
                                                    <div>
                                                        <DefaultInfoCard
                                                            icon={sensore.icona}
                                                            style={{background: "linear-gradient(195deg, #3CD93F, #33ba3b)"}}
                                                            type={false}
                                                            title={sensore.descrizione}
                                                            value={String(sensore.Value) + " " + sensore.unita_di_misura}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                </Container>
                            </ContainerRealTime>
                        </MDBox>
                        {act.length > 0 ?
                            (
                                <MDBox pt={3}>
                                    <ContainerRealTime>
                                        <Container
                                            style={{margin: "12px auto 12px auto"}}
                                            fluid
                                            className="d-flex justify-content-around"
                                        >
                                            <Row>
                                                <Col xl={6} md={6} xs={12} style={{marginBottom: 12}}>
                                                    <img
                                                        src={uvagriotSchema}
                                                        className='img-fluid hover-shadow'
                                                        alt='uvagriot_schema'
                                                    />
                                                </Col>
                                                <Col xl={6} md={6} xs={12} style={{marginBottom: 12}}>
                                                    <Row>
                                                        {act.map(attuatore => (
                                                            <Col xl={4} md={4} xs={12} style={{marginBottom: 12}}
                                                                 key={attuatore.nome}>
                                                                <div fluid={1}
                                                                     style={{textAlign: "center"}} key={attuatore.nome}>
                                                                    <div>
                                                                        <DefaultInfoCard
                                                                            icon={attuatore.icona}
                                                                            style={{background: "linear-gradient(195deg, #3CD93F, #33ba3b)"}}
                                                                            type={true}
                                                                            title={attuatore.descrizione}
                                                                            value={attuatore.Value}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        ))}
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </ContainerRealTime>
                                </MDBox>
                            )
                            :
                            (<></>)}
                    </Grid>
                </Grid>
            </MDBox>
        );
    }
;

export default RealTime;

// #Region Styled Component

const ContainerRealTime = styled.div`
    .thermometer ul.thermometer__statistics li {
        width: auto !important;
    }
`;

const Spazer = styled.div`
    height: 5vh;
`;

// #End region

export const regexStructureKey = /(Session|PowerOn|AllTime)/g

export const enumStructureKey = {
    SESSION: 'Session',
    POWERON: 'PowerOn',
    ALLTIME: 'AllTime'
}
// Ha un ordine preciso di seguito l'ordine: stringa->intero->float->bool
export const enumTypeParam = {
    0: "SENSORE",
    1: "ATTUATORE",
}

export const enumSection = {
    HOME: 'home',
    GESTIONE_NODI: 'gestione_nodi',
    NODI: 'nodi',
    DRONI: 'droni',
    CLIENTI: "clienti",
    RICETTE: 'ricette',
    TIPOLOGIA: 'tipologia_nodi',
    PARAMETRI: 'parametri',
    PROFILO: 'profilo',
    MODELLI: 'modelli',
    COLTURE: 'colture',
    DSS: 'dss'
}
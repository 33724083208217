import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {useEffect} from "react";
import {Scrollbar} from "smooth-scrollbar-react";
import TablePagination from "@mui/material/TablePagination";
import {useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import {NavLink} from "react-router-dom";
import {Component} from "react";

import {styled} from "@mui/material/styles";

import {BarChart, Bar, XAxis, ResponsiveContainer} from "recharts";
import {Tooltip as TP} from "recharts";

// Images
import MDButton from "components/MDButton";

import {BsPlusCircleFill} from "react-icons/bs";
import {BsCheck2Circle} from "react-icons/bs";
import {BsXCircle, BsPen} from "react-icons/bs";
import {BsX, BsPlusCircleDotted, BsPlus} from "react-icons/bs";
import {BsTrash3, BsPenFill, BsFileText} from "react-icons/bs";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import Popover from "@mui/material/Popover";
import uvagriotService from "../../../services/uvagriot-service";
import {useState} from "react";

import {MDBCheckbox, MDBSelect} from "mdb-react-ui-kit";

import {useTheme} from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "react-select";
import Chip from "@mui/material/Chip";
import {dataCsvIcon} from "@progress/kendo-svg-icons";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import ParamForm from "./ParamForm";

//import "uvagriotLayouts/utility/styles/uvagriotStyle.css";

import {
    enumStructureKey,
    regexStructureKey,
} from "uvagriotLayouts/utility/enumerators";

const structureKey = Object.values(enumStructureKey);

const CustomTooltip = ({active, payload, label}) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="label">{`${label}`}</p>
            </div>
        );
    }
    return null;
};

function TablePaginationActions(props) {
    const theme = useTheme();
    const {count, page, rowsPerPage, onPageChange} = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{flexShrink: 0, ml: 2.5}}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === "rtl" ? <LastPageIcon/> : <FirstPageIcon/>}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight/>
                ) : (
                    <KeyboardArrowLeft/>
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft/>
                ) : (
                    <KeyboardArrowRight/>
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === "rtl" ? <FirstPageIcon/> : <LastPageIcon/>}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

class BtnCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.webSocketSniffer(props.value);
        const buttonGlowStyleYellow = {
            font: "10 Raleway, sans-serif",
            padding: "5px",
            border: "solid 2px",
            cursor: "pointer",
            position: "relative",
            transition: "0.25s ease-out",
            boxShadow: "0 0 15px #dee610",
        };
        this.state = buttonGlowStyleYellow;
    }

    webSocketSniffer(id) {
        let url = `${process.env.REACT_APP_SOCKET_ENDPOINT}ws/mqtt/${id}/`;
        console.log(id);
        const machineSocket = new WebSocket(url);
        machineSocket.onopen = (e) => {
            console.log("connected");
            this.setState({boxShadow: "0 0 15px #2ae319", borderColor: "#2ae319"});
        };
        machineSocket.onclose = (e) => {
            console.log("disconnected");
            this.setState({boxShadow: "0 0 15px #f20f22", borderColor: "#f20f22"});

            setTimeout(() => {
                this.machineSocket = new WebSocket(url);
                console.log(url);
            }, 1000);
        };
        machineSocket.onerror = (err) => {
            console.log("Socket encountered error: ", err.message, "Closing socket");
            this.setState({boxShadow: "0 0 15px #f20f22", borderColor: "#f20f22"});
            machineSocket.close();
        };
        machineSocket.onmessage = function (e) {
            let data = JSON.parse(e.data);
            /* if (data.hasOwnProperty("value")) {
              console.log("Data ActiveRecipe:", data.value.value.Data.ActiveRecipe);
            } else {
              console.log("Data:", data);
            } */
        };
    }

    render() {
        return (
            <NavLink
                to={`/nodi/${this.props.value}`}
                state={{
                    id: this.props.value,
                    permission: this.props.dashboard,
                    nodo: this.props.name,
                }}
            >
                <MDButton className="buttonGlow" style={this.state} m={5}>
                    Dashboard
                </MDButton>
            </NavLink>
        );
    }
}

function DeleteButton(props) {
    const {deleteAction, parametro} = props;

    const [anchorEl, setAnchorEl] = React.useState(null);

    const [params, setParams] = useState([]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (parametro.is_structure) {
            let parametri = [];

            let param = parametro.nome.replace(regexStructureKey, "");

            structureKey.map((key) => {
                parametri.push(param + key);
            });

            setParams(parametri);
        }
    }, [parametro]);

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const disabled_label = "Ci sono nodi associati";
    const label = "elimina";

    return (
        <>
            <Tooltip title={label} TransitionComponent={Zoom} placement="top">
        <span>
          <IconButton color="error" onClick={handleClick}>
            <BsTrash3 size={20} className="p-0"/>
          </IconButton>
        </span>
            </Tooltip>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <Typography sx={{p: 2}} variant="h6">
                    Sei Sicuro di voler eliminare questa tipologia?
                </Typography>
                <Typography sx={{p: 2}} variant="body2">
                    {params.length ? (
                        <>
                            Questa azione comporta l'eliminazione di:
                            {params.map((p, index) => (
                                <li key={index}>{p}</li>
                            ))}
                        </>
                    ) : (
                        ""
                    )}
                </Typography>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        padding: "5px 16px 5px 16px",
                    }}
                >
                    <MDButton
                        variant="text"
                        color="info"
                        onClick={handleClose}
                        size="small"
                    >
                        indietro
                    </MDButton>
                    <MDButton
                        color={"error"}
                        size="small"
                        onClick={() => deleteAction(parametro)}
                    >
                        Elimina
                    </MDButton>
                </div>
            </Popover>
        </>
    );
}

function Row(props) {
    const {row, updateAction} = props;

    const trueElem = <BsCheck2Circle color="green" size={25}/>;
    const falseElem = <BsXCircle color="red" size={20}/>;

    const [open, setOpen] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <React.Fragment>
            <TableRow sx={{"& > *": {borderBottom: "unset"}}}>
                <TableCell align="center">
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                    {row.nome}
                </TableCell>
                <TableCell align="center">{row.indirizzo}</TableCell>
                <TableCell align="center">{row.longitudine}</TableCell>
                <TableCell align="center">{row.latitudine}</TableCell>
                <TableCell align="center">
                    <NavLink
                        to={`/dss/pianificazione`}
                        state={{
                            appezzamento: row.id,
                        }}
                    >
                        <MDButton variant="gradient" color="info" m={5}>
                            Pianificazione
                        </MDButton>
                    </NavLink>
                </TableCell>
                <TableCell align="center">
                    <NavLink
                        to={`/appezzamenti/${row.id}/nodi`}
                        /* state={{
                          id: this.props.value,
                          permission: this.props.dashboard,
                          nodo: this.props.name,
                        }} */
                    >
                        <MDButton variant="gradient" color="info" m={5}>
                            Nodi
                        </MDButton>
                    </NavLink>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{paddingBottom: 0, paddingTop: 0}}
                    colSpan={6}
                    sx={{backgroundColor: "#f8f9fa"}}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{margin: 1}}>
                            <Typography variant="h6" gutterBottom component="div">
                                Descrizione
                            </Typography>
                            <Typography
                                variant="body2"
                                gutterBottom
                                component="div"
                                align="justify"
                            >
                                <Scrollbar
                                    className="scrollable"
                                    alwaysShowTracks={true}
                                    style={{maxHeight: "300px", paddingRight: "25px"}}
                                    plugins={{
                                        overscroll: {
                                            effect: "bounce",
                                        },
                                    }}
                                >
                                    <div className="pl-5">{row.descrizione}</div>
                                </Scrollbar>
                            </Typography>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function AppezzamentiTable(props) {
    const {rows} = props;

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [staticModalEdit, setStaticModalEdit] = useState(false);
    const toggleShowEdit = () => setStaticModalEdit(!staticModalEdit);
    const [editValue, setEditValue] = useState({});

    const updateValue = (data) => {
        setEditValue(data);
        toggleShowEdit(false);
    };

    useEffect(() => {
        console.log("rows");

        console.log(rows.rows);
    }, []);

    return (
        <>
            <TableContainer component={Paper} style={{minHeight: "500px"}}>
                <Table aria-label="collapsible table">
                    <TableHead sx={{display: "table-header-group"}}>
                        <TableRow>
                            {/* <TableCell style={{maxWidth: '40px'}}>Descrizione</TableCell> */}
                            <TableCell align="center">Info</TableCell>
                            <TableCell align="center">Nome</TableCell>
                            <TableCell align="center">Indirizzo</TableCell>
                            <TableCell align="center">Latitudine</TableCell>
                            <TableCell align="center">Longitudine</TableCell>
                            {/*  <TableCell align="center">Utilizzo</TableCell> */}
                            <TableCell component="td" align="right">
                                {/* <Button variant="text" color='primary' style={{paddingRight:17}} onClick={toggleShow} id={"addParamID"} disabled={disable}><BsPlus size={30} align={'right'}/>AGGIUNGI</Button> */}
                            </TableCell>
                            <TableCell component="td" align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => (
                                <Row key={index} row={row} updateAction={updateValue}/>
                            ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    colSpan={3}
                    count={rows.rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage="righe per pagina"
                    SelectProps={{
                        select: {
                            marginbottom: "10px",
                        },
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                />
            </TableContainer>
            {/* <ParamForm
        id={"editFormID"}
        paramsHook={rows}
        toggleShow={toggleShowEdit}
        modalHook={{
          staticModal: staticModalEdit,
          setStaticModal: setStaticModalEdit,
        }}
        editValue={editValue}
        check={check}
        modality={"edit"}
      /> */}
        </>
    );
}

const ParamButton = styled(IconButton)({
    "&:hover": {
        color: "red",
        boxShadow: "none",
    },
});
const ParamEditButton = styled(IconButton)({
    "&:hover": {
        color: "blue",
        boxShadow: "none",
    },
});

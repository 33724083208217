import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import React, {useEffect, useState} from "react";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {RJSFSchema} from "@rjsf/utils";
import {NotificationContainer, NotificationManager} from "react-notifications";
import Form from "@rjsf/core";
import {customizeValidator} from "@rjsf/validator-ajv8";
import localizer from "ajv-i18n";
import MDButton from "../../components/MDButton";
import UvagriotService from "../../services/uvagriot-service";
import {UiSchema} from "@rjsf/utils";


export default function DSS_Fase_Single() {

    let location = useLocation();
    const [formData, setFormData] = useState(null);
    const [listTipologie, setListTipologie] = useState([])
    const [listFasiFenologiche, setFasiFenologiche] = useState([])
    const navigate = useNavigate()
    console.log(location.state.id);

    useEffect(() => {
        let response;
        if (location.state.id > 0) {
            getFase();
        }
        listFaseFenologicaFetch()
        listTipologieFetch()
    }, []);

    const listFaseFenologicaFetch = async () => {
        let response = await UvagriotService.faseFenologicaLIST()
        let tmp_list = []
        for (let k in response) {
            tmp_list.push({const: response[k]?.id, title: response[k]?.nome})
        }
        setFasiFenologiche(tmp_list)
    }

    const listTipologieFetch = async () => {
        let response = await UvagriotService.tipologiaColturaLIST()
        let tmp_list = []
        for (let k in response) {
            if (response[k]?.is_active) {
                tmp_list.push({const: response[k]?.id, title: response[k]?.nome})
            }
        }
        setListTipologie(tmp_list)
    }

    let getFase = async () => {
        let response = await UvagriotService.faseGET(location.state.id);
        console.log(response)
        let tmp_form_data = {
            "stato": response?.stato,
            "finestra_osservazione": response?.finestra_osservazione,
            "gdd": response?.gdd,
            "fase_fenologica": response?.fase_fenologica?.id,
            "tipologia_coltura": response?.tipologia_coltura?.id,
        }
        setFormData(tmp_form_data)
    }


    const schema: RJSFSchema = {
        title: location.state.id > 0 ? "Modifica Fase" : "Nuova Fase",
        type: 'object',
        required: ['stato', 'finestra_osservazione', 'gdd', 'fase_fenologica', 'tipologia_coltura'],
        properties: {
            stato: {type: 'string', title: 'Stato'},
            finestra_osservazione: {type: 'number', title: 'Finestra Osservazione'},
            gdd: {type: 'number', title: 'GDD'},
            fase_fenologica: {
                type: 'number',
                title: 'Fase Fenologica',
                oneOf: listFasiFenologiche
            },
            tipologia_coltura: {
                type: 'number',
                title: 'Tipologia Coltura',
                oneOf: listTipologie
            },
        },
    };

    const uiSchema: UiSchema = {
        "ui:submitButtonOptions": {
            "props": {
                "disabled": false,
                "className": "btn btn-info"
            },
            "submitText": "Applica"
        }
    }

    const onSubmit = async () => {
        formData.fase_fenologica_pk = formData?.fase_fenologica
        formData.tipologia_coltura_pk = formData?.tipologia_coltura
        console.log(formData);
        let response = null
        if (location.state.id > 0) {
            response = await UvagriotService.fasePUT(location.state.id, formData)
        } else {
            response = await UvagriotService.fasePOST(formData);
        }
        console.log(response)
        await createNotification(response)
    }

    const createNotification = async (response) => {
        if (response?.id > 0) {
            navigate("/dss/fase");
        } else {
            let error = ''
            let i = 0
            for (let k in response) {
                if (i > 0) error += "\n"
                error += response[k]
                i++
            }
            NotificationManager.error(
                error,
                "Errore",
                5000
            );
        }
    };

    const validator = customizeValidator({}, localizer.it);

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox py={1}>
                <MDBox>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Form
                                schema={schema}
                                uiSchema={uiSchema}
                                formData={formData}
                                onChange={(e) => setFormData(e.formData)}
                                validator={validator}
                                onSubmit={onSubmit}
                            >
                                <div style={{"padding-top": 15}}>
                                    <MDButton
                                        variant="gradient"
                                        color="info"
                                        m={5}
                                        type={"submit"}
                                    >
                                        Invia
                                    </MDButton>
                                    &nbsp;
                                    <NavLink to={`/dss/fase`}>
                                        <MDButton variant="gradient" color="warning">
                                            Annulla
                                        </MDButton>
                                    </NavLink>
                                </div>
                            </Form>
                        </Grid>
                    </Grid>
                </MDBox>
            </MDBox>
            <NotificationContainer/>
        </DashboardLayout>
    );
}
import React, { useCallback, useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
/* import { v } from "../style/Variables"; */
/* import { Button, ButtonGroup, Dropdown } from "react-bootstrap"; */

import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBCol,
  MDBRow,
} from "mdb-react-ui-kit";

import MDBox from "components/MDBox";

import "../style/formStyle.css";

import Grid from "@mui/material/Grid";

import { useMaterialUIController } from "context";

import uvagriotService from "../../../services/uvagriot-service";

import Card from "@mui/material/Card";

import TextField from "@mui/material/TextField";

import InputAdornment from "@mui/material/InputAdornment";
import { BsSearch } from "react-icons/bs";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import TypeTable from "./TableTipologia";

export default function DashboardTipologia() {
  //const { columns, rows, datas } = TipologiaTableData();
  const [rows, setRows] = useState([]);
  const [controller] = useMaterialUIController();
  const [search, setSearch] = useState("");
  const [filteredRow, setFilteredRow] = useState([]);
  const [modelli, setModelli] = useState([]);
  const [colture, setColture] = useState([]);

  const getConfigurazioneTipologia = async () => {
    const data = await uvagriotService.ModelliAndColturaGET();
    console.log("mod e e col");
    console.log(data.data);
    setModelli(data.data.modelli);
    setColture(data.data.colture);
  };

  //const [tabsOrientation, setTabsOrientation] = useState("horizontal");

  useEffect(() => {
    const getTypeMachine = async () => {
      /* Esempio di output
            0 : descrizione : "tipologia baresina per le nodi"
                nome : "baresina"
                parametri:
                    0:{nome: 'CutterSpeed', in_dasboard_realtime: false, in_ricetta: true, parametro_stato: false}
                    1:{nome: 'PressSpeed', in_dasboard_realtime: false, in_ricetta: true, parametro_stato: false}
                    2:{nome: 'AirStatus', in_dasboard_realtime: false, in_ricetta: true, parametro_stato: false}
                    3:{nome: 'CutterStatus', in_dasboard_realtime: false, in_ricetta: false, parametro_stato: true}
                    4:{nome: 'PressStatus', in_dasboard_realtime: false, in_ricetta: false, parametro_stato: true}
                pk: 1
            */
      const data = await uvagriotService.tipologiaNodoGET();
      console.log("####################");
      console.log(data.data);
      setRows(data.data);
      setFilteredRow(data.data);
    };

    getTypeMachine();
    getConfigurazioneTipologia();
  }, []);

  const navigate = useNavigate();

  const inserisciTipologia = () => {
    navigate("/tipologia-nodo/crea", {
      state: {
        tipologie: rows.map((type) => type.nome),
        modelli: modelli,
        colture: colture,
      },
    });
  };

  const updateTipologia = (tipologia) => {
    navigate("/tipologia-nodo/modifica", {
      state: {
        tipologia: tipologia,
        modelli: modelli,
        colture: colture,
      },
    });
  };
  const getStdRecipes = (tipologia) => {
    navigate("/tipologia-nodo/ricette-standard", {
      state: { typeName: tipologia.nome, tipologiaID: tipologia.pk },
    });
  };

  const deleteTipologia = async (pk) => {
    console.log("delete: " + pk);
    //TODO: il pk o va reso in json o cambiata la chiamata in urls.py e ovviamente in uvagriot service
    const response = await uvagriotService.tipologiaNodoDELETE(
      JSON.stringify({ pk: pk })
    );
    setRows(rows.filter((item) => item.id !== pk));
    setFilteredRow(rows.filter((item) => item.id !== pk));
  };

  const filterRows = (e) => {
    let search_ = e.target.value.toLowerCase();
    const filteredRows_ = rows.filter((elem) =>
      elem.nometipologia.toLowerCase().includes(search_)
    );
    setFilteredRow(filteredRows_);
    setSearch(e.target.value);
  };
  return (
    <>
      <MDBox pb={2} pt={1} px={2}>
        <Grid container spacing={0}>
          <Grid item xs={12} md={6} lg={4} my={3}>
            <TextField
              id="input-with-icon-textfield"
              label="Tipologia nodo"
              fullWidth
              value={search}
              onChange={filterRows}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BsSearch />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} md={12} xl={12}>
            <div id="id_standard">
              <TypeTable
                data={filteredRow}
                create={inserisciTipologia}
                update={updateTipologia}
                del={deleteTipologia}
                recipes={getStdRecipes}
              />
            </div>
          </Grid>
        </Grid>
      </MDBox>
      {<NotificationContainer />}
    </>
  );
}

// #End region

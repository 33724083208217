import React, {
  useCallback,
  useState,
  useEffect,
  Component,
  PureComponent,
  useMemo,
} from "react";
import { NavLink } from "react-router-dom";

import styled from "styled-components";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "bootstrap/dist/css/bootstrap.css";
import { Grid } from "@mui/material";
import NodiTable from "./machinesTable";
import MDBox from "components/MDBox";
import UvagriotService from "../../../services/uvagriot-service";
import data from "layouts/dashboard/components/Projects/data";

const DataTableFilterDemo = (props) => {
  const { appezzamentoID } = props
  const [rowData, setRowData] = useState([]);
  //const [rowClientData, setRowClientData] = useState([]);
  //const [columnDefs, setColumnDefs] = useState([]);

  const [check, setCheck] = React.useState(false);

  const getDataMachines = async () => {
    console.log("appezzamentoID")
    console.log(appezzamentoID)
    const dataMachines = await UvagriotService.nodiGET(appezzamentoID);

    setCheck(dataMachines.updating);

    console.log("dataMachine ===============================");
    setRowData(dataMachines.data);
    console.log(dataMachines);
  }
  useEffect(() => {
    getDataMachines();

  }, []);

  useEffect(() => {
    console.log("2. second");
    let interval;
    if (check) {
      interval = setInterval(async () => {
        console.log("intervallo attivo");
        console.log("updating");
        getDataMachines();
      }, 2000);
    }
    return () => clearInterval(interval);

  }, [check]);

  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  /*   const gridStyle = useMemo(
      () => ({ height: "70vh", width: "100%", boxSizing: "border-box" }),
      []
    ); */

  /* let getColumnMachines = async () => {

    let dataColumn = await UvagriotService.getDataAttributes();
    const perm = JSON.parse(localStorage.getItem('permission'));

    if (!perm.machine_data_permission) {
      dataColumn.push(
        {
          field: "tipologia_nodo",
          headerName: "Tipologia nodo",
          flex: 1,
          resizable: false,
          suppressSizeToFit: true,
          minWidth: 150,
          cellStyle: () => ({
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
          }),
          cellRenderer: TypeOfMachine,
          cellRendererParams: {
            getValue: function (field) {
              alert(`${field} was clicked`);
            },
          },
          cellMouseOver: (e) => console.log("Cell Hover"),
        },
        {
          field: "data",
          headerName: "Usage",
          flex: 2,
          resizable: false,
          suppressSizeToFit: true,
          minWidth: 150,
          cellStyle: () => ({
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }),
          cellRenderer: BarChartUsage,
          cellRendererParams: {
            getValue: function (field) {
              alert(`${field} was clicked`);
            },
          },
        },
        {
          field: "id",
          headerName: "",
          flex: 1,
          resizable: false,
          suppressSizeToFit: true,
          minWidth: 150,
          cellStyle: () => ({
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }),
          cellRenderer: BtnCellRenderer,
          cellRendererParams: {
            clicked: function (field) {
              alert(`${field} was clicked`);
            },
          },
        }
      );
    } else {
      dataColumn.push({
        field: "data",
        headerName: "Usage",
        flex: 2,
        resizable: false,
        cellStyle: () => ({
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }),
        cellRenderer: BarChartUsage,
        cellRendererParams: {
          getValue: function (field) {
            alert(`${field} was clicked`);
          },
        },
      });
    }

    setColumnDefs(dataColumn);
    console.log(dataColumn)
  };
 */
  /*   const onGridReady = useCallback(async () => {
  
      const dataMachines = await UvagriotService.getMachineDatas();
  
      let data = [...dataMachines];
  
      for (let elem of data) {
        for (let value of elem.active) {
          value.yVal = 5;
        }
      }
      console.log('data')
      console.log(data)
      setRowData(data);
  
      console.log(dataMachines);
  
    }, []);
  
    const defaultColDef = {
      resizable: false,
    }; */

  return (
    <MDBox pb={2} pt={3} px={2}>
      <Grid container spacing={0}>
        <div style={containerStyle}>
          <div className="outer-div">
            <Grid item xs={12} md={12} xl={12} >
              <div id="mineID">
                <NodiTable
                  id={'tableNodiID'}
                  rows={{ rows: rowData, setRows: setRowData }}
                  check={{ check: check, setCheck: setCheck }}
                />
              </div>
            </Grid>



            {/* <Grid item xs={12} md={12} xl={12} pt={5}>
              <MDBox
                my={3}
                py={3}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                className={'fullwidth'}
              >
                <MDTypography variant="h6" color="white" mx={2}>
                  Nodi Clienti
                </MDTypography>
              </MDBox>
              <div id="clientID">
                <ParamTable
                  id={'tableClientMachineID'}
                  rows={rowClientData}
                />
              </div>
            </Grid> */}

            {/* <div style={gridStyle} className="ag-theme-alpine">
            <AgGridReact
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              animateRows={true}
              onGridReady={onGridReady}
            ></AgGridReact>
          </div> */}




          </div>
        </div>
      </Grid>
    </MDBox>
  );
};

export default DataTableFilterDemo;

/* class BtnCellRenderer extends Component {
  constructor(props) {
    super(props);
    this.webSocketSniffer(props.value);

    const buttonGlowStyleYellow = {
      font: "10 Raleway, sans-serif",
      padding: "3px",
      borderRadius: "2em",
      cursor: "pointer",
      position: "relative",
      transition: "0.25s ease-out",
      boxShadow: "0 0 15px #dee610",
    };
    this.state = buttonGlowStyleYellow;
  }
  webSocketSniffer(id) {
    let url = `${process.env.REACT_APP_SOCKET_ENDPOINT}ws/mqtt/${id}/`;
    console.log(id);
    const machineSocket = new WebSocket(url);
    machineSocket.onopen = (e) => {
      console.log("connected");
      this.setState({ boxShadow: "0 0 15px #2ae319" });
    };
    machineSocket.onclose = (e) => {
      console.log("disconnected");
      this.setState({ boxShadow: "0 0 15px #f20f22" });

      setTimeout(() => {
        this.machineSocket = new WebSocket(url);
        console.log(url);
      }, 1000);
    };
    machineSocket.onerror = (err) => {
      console.log("Socket encountered error: ", err.message, "Closing socket");
      this.setState({ boxShadow: "0 0 15px #f20f22" });
      machineSocket.close();
    };
    machineSocket.onmessage = function (e) {
      let data = JSON.parse(e.data);
      if(data.hasOwnProperty("value")) {
        console.log("Data ActiveRecipe:", data.value.value.Data.ActiveRecipe);
      } else {
        console.log("Data:", data);
      } 
    };
  }

  render() {
    return (
      <NavLink
        to={`/nodi/${this.props.value}`}
        state={{ id: this.props.value }}
      >
        <button className="buttonGlow" style={this.state}>
          Dashboard
        </button>
      </NavLink>
    );
  }
} */

/* const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${label}`}</p>
      </div>
    );
  }
  return null;
}; */

/* class BarChartUsage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { data: [] };
    // console.log(props.data.active);
    this.dataUsage = props.data.active;
  }

  render() {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart width={150} height={40} data={this.dataUsage}>
          <Tooltip content={<CustomTooltip />} />
          <XAxis dataKey="xVal" hide />
          <Bar dataKey="yVal" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
 */
/* class TypeOfMachine extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { data: [] };
    // console.log(props.data.active);
    this.dataUsage = props.data.tipologia_nodo;
    if (props.data.tipologia_nodo === "baresina") {
      this.dataUsage = baresimaImage;
    } else {
      this.dataUsage = unknownImage;
    }
  }

  changeBackground(e) {
    // e.target.style.background = "red"; 
  }

  render() {
    return (
      <div onMouseOver={this.changeBackground}>
        <ReactRoundedImage
          image={this.dataUsage}
          roundedSize="0"
          imageWidth="50"
          imageHeight="50"
        />
      </div>
    );
  }
}
 */
// #Region Styled Component
const Container = styled.div`
  height: 70vh;
  width: 100%;
  color: ${(props) => props.theme.text};
  background: ${(props) => props.theme.bg};

  .ag-theme-alpine {
    color: ${(props) => props.theme.text};
    background: ${(props) => props.theme.bg};
    height: 100%;
    width: 100%;
    border-style: none;
    .ag-root-wrapper.ag-layout-normal {
      color: ${(props) => props.theme.text};
      background: ${(props) => props.theme.bg2};
      width: 100%;
      border-style: none;
    }
    .buttonGlow {
      color: ${(props) => props.theme.text};
      border: 2px solid ${(props) => props.theme.text};
      line-height: normal;
    }
  }
`;
// #End region

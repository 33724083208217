import React, { Component } from "react";
import DashboardUser from "./components/DashboardUser";
import TipologiaNodiTemplate from "uvagriotLayouts/TipologiaNodo/components/tipologiaTemplate";

export default class DashboardProfileComponents extends Component {
    render() {
        return (
            <TipologiaNodiTemplate
                title="Profilo"
                component={<DashboardUser />}
            />

        );
    }
}

// #End region

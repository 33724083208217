import { Component, useEffect } from "react";
import { useParams } from "react-router-dom";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import UvagriotTemplate from "uvagriotLayouts/utility/UvagriotTemplate";
import DashboardVoli from "./components/dashboard";

export default function Pianificazione() {

  const params = useParams();

  useEffect(() => {
    console.log("NODO");
    console.log(params)
  })
  return (
    <UvagriotTemplate
      title="Pianificazione voli"
      component={<DashboardVoli nodoID={params?.nodoID} />}
    />

  );

}
